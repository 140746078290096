export const isoValueOfCountry = (country) => {
  if (country === "Canada") return 124;
  if (country === "Ireland") return 372;
  if (country === "United States") return 840;
};

export const countryByIsoValue = (isoValue) => {
  switch (parseInt(isoValue)) {
    case 124:
      return "Canada";
    case 372:
      return "Ireland";
    case 840:
      return "United States";
    default:
      return null;
  }
};
