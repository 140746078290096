import React, {useEffect, useState} from 'react';
import './Invite.css';

import infoIcon from "../../assets/info.png";
import shareIcon from '../../assets/invite/share.png';
import goBackArrow from '../../assets/arrows/blue-left-arrow.png';

import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {setProviderInviteSent} from "../../redux/action-creators";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import InviteOptions from "./InviteOptions";

const InviteBusiness = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    // const currentCustomer = useSelector(({customerReducer: {currentCustomer}}) => currentCustomer);
    const inviteCode = localStorage.getItem('inviteCode');

    const providerInviteSent = useSelector(({messageReducer: {providerInviteSent}}) => providerInviteSent);

    const [shareActive, setShareActive] = useState(false);



    useEffect(() => {
        if (providerInviteSent) {
            setTimeout(() => {
                dispatch(setProviderInviteSent(false));
            }, 2000);
        }
    }, [providerInviteSent, dispatch]);

    return (
        <div className={'invite-wrapper'}>
            <HeaderMobile/>

            <div className={'invite-inner'}>
                <div className={providerInviteSent ? 'sales-inner__message active' : 'sales-inner__message'}>
                    <img src={infoIcon} alt="info about transaction"/>

                    <div className={'sales-inner__message-text'}>
                        Provider was invited
                    </div>
                </div>

                <div className={'invite-title'}>
                    <img
                        src={goBackArrow}
                        alt="go back arrow"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    <div>Invite a business</div>
                    <div/>
                </div>

                <div className={'invite-subtitle'}>
                    <div>Want a quick way to add to</div>
                    <div>your rewards balance?</div>
                </div>

                <div className={'invite-text'}>
                    <div>Share your invite code with a business! Once</div>
                    <div>the business completes their registration you</div>
                    <div>will receive an invite code to send to 10</div>
                    <div>friends. For every friend that registers you will</div>
                    <div>receive $30 into your rewards balance and so</div>
                    <div>will your friend.</div>
                </div>

                <div className={'invite-message'}>Share your invite code:</div>

                <div className={'invite-code'}>
                    <div />
                    <div>{inviteCode}</div>
                    <img
                        src={shareIcon}
                        alt="share"
                        onClick={() => {
                            setShareActive(true);
                        }}
                    />
                </div>
            </div>

            {shareActive && <InviteOptions setShareActive={setShareActive} />}
        </div>
    );
};

export default InviteBusiness;
