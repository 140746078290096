import React from 'react';
import './VendorPopUp.css';

const VendorPopUp = ({active, setActive, children}) => {
    return (
        <div
            className={active ? 'popup active' : 'popup'}
            onClick={() => {
                setActive(false);
            }}
        >
            <div
                className={active ? 'popup-content active' : 'popup-content'}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                { children }
            </div>
        </div>
    );
};

export default VendorPopUp;
