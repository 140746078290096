import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useObserver} from "../../hooks/useObserver";
import {jobActions} from "../../action";
import Activity from "../Activities/Activity";
import Loader from "../Loader/Loader";
import {useSelector} from "react-redux";

const FRewards = () => {
	// const customerTokenLocal = localStorage.getItem('customerToken');
	const customerTokenLocal = useSelector(({customerReducer: {customerToken}}) => customerToken);

	// const [isReviewedActivitiesActive, setIsReviewedActivitiesActive] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [noResults, setNoResults] = useState(false);

	const [reviewedActivities, setReviewedActivities] = useState([]);
	const [reviewedActivitiesPage, setReviewedActivitiesPage] = useState(1);
	const [reviewedActivitiesTotalPages, setReviewedActivitiesTotalPages] = useState(0);

	const reviewedActivitiesRef = useRef();

	useObserver(reviewedActivitiesRef, reviewedActivitiesPage < reviewedActivitiesTotalPages, isLoading,  () => {
		setReviewedActivitiesPage(reviewedActivitiesPage + 1);
	});

	const getReviewedActivities = useCallback(() => {
		setIsLoading(true);

		jobActions.getJobs(reviewedActivitiesPage, customerTokenLocal)
			.then(({data}) => {
				if (reviewedActivitiesPage > data.jobs.last_page) {
					setIsLoading(false);
					if (!reviewedActivities.length) setNoResults(true);
					return;
				}

				setReviewedActivitiesTotalPages(data.jobs.last_page);


				const reviewedActivitiesList = data.jobs.data.filter(activity => {
					return activity.status === 'reward';
				});

				if (!reviewedActivitiesList.length) {
					setReviewedActivitiesPage(reviewedActivitiesPage + 1);
					return;
				}

				setReviewedActivities([...reviewedActivities, ...reviewedActivitiesList]);
				setIsLoading(false);
			});
	}, [customerTokenLocal, reviewedActivitiesPage]);

	useEffect(() => {
		getReviewedActivities();
	}, [getReviewedActivities]);

	return (
		<>
			{reviewedActivities.map(activity =><Activity activity={activity} key={activity.id}/>)}
			{isLoading && <Loader />}
			{noResults && <div className={'activity-filtered'}>Nothing has been found</div>}
			{!isLoading && <div ref={reviewedActivitiesRef}/>}
		</>
	);
};

export default FRewards;
