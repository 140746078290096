import axios from "axios";
import { BASE_URL } from "../constants";

// ===== Customers =====
const verifySmsCustomer = async (data) => {
  return await axios.post(
    `${BASE_URL}/api/auth/register/customer/verify-phone`,
    data
  );
};

const sendVerifySmsCustomer = async (data) => {
  return await axios.post(
    `${BASE_URL}/api/auth/register/customer/send-phone-code`,
    data
  );
};

const verifyEmailCustomer = async (data) => {
  return await axios.post(
    `${BASE_URL}/api/auth/register/customer/verify-email`,
    data
  );
};

const sendVerifyEmailCustomer = async (data) => {
  return await axios.post(
    `${BASE_URL}/api/auth/register/customer/verify-email`,
    data
  );
};

const sendPhoneNumberForCustomer = async (data) => {
  return await axios.post(
    `${BASE_URL}/api/auth/register/customer/send-varification-code`,
    data
  );
};

// ===== Vendor =====
const verifySmsVendor = async (data) => {
  return await axios.post(
    `${BASE_URL}/api/auth/register/vendor/verify-phone`,
    data
  );

  // {
  //     "ok": true,
  //     "data": {
  //     "message": "success send. verify phone"
  // }
  // }
};

const sendVerifySmsVendor = async (data) => {
  return await axios.post(
    `${BASE_URL}/api/auth/register/vendor/send-phone-code`,
    data
  );
};

const verifyUpdatedPhone = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.post(`${BASE_URL}/api/user/verify-phone`, data, config);
};

export {
  verifySmsCustomer,
  sendVerifySmsCustomer,
  verifySmsVendor,
  sendVerifySmsVendor,
  verifyEmailCustomer,
  sendVerifyEmailCustomer,
  verifyUpdatedPhone,
  sendPhoneNumberForCustomer,
};
