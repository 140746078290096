import {
    SET_CUSTOMER_TOKEN,
    SET_CURRENT_CUSTOMER,
    SET_FRIEND_INVITES_LEFT
} from "../action-types";

const setCustomerToken = response => ({type: SET_CUSTOMER_TOKEN, payload: response});
const setCurrentCustomer = response => ({type: SET_CURRENT_CUSTOMER, payload: response});
const setFriendInvitesLeft = response => ({type: SET_FRIEND_INVITES_LEFT, payload: response});

export {
    setCustomerToken,
    setCurrentCustomer,
    setFriendInvitesLeft
};
