import React, {useState} from 'react';
import './VendorAlert.css';

import infoIcon from "../../../assets/info/info-icon.png";

const VendorAlert = ({title}) => {
    const [alertActive, setAlertActive] = useState(false);

    return (
        <div className={'form__title'}>
            <div className={'form__title-text v-info'}>{title}</div>
            <div className={'v-info'}>
                <img
                    className={''}
                    src={infoIcon}
                    alt="info icon"
                    onClick={() => setAlertActive(!alertActive)}
                />

                <div className={'vendor-alert'}>
                    <div className={'vendor-alert__message'}>
                        To continue as a vendor please use the <span>mobile app.</span>
                    </div>

                    <button className={'vendor-alert__button'}>Download the app</button>
                </div>
            </div>


            {/*{alertActive &&*/}

            {/*}*/}
        </div>
    );
};

export default VendorAlert;
