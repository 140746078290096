import React, {useEffect, useState} from 'react';
import s from './Activity.module.css';

import arrowIcon from '../../assets/arrows/back-black-arrow.svg';

import {useHistory, useParams} from "react-router-dom";
import {vendorActions} from '../../action';
import ProviderWithMap from "../ProviderWithMap/ProviderWithMap";
import Loader from "../../components/Loader/Loader";
import WebReviews from "../WebReviews/WebReviews";

const WebActivityDetails = () => {
	const customerTokenLocal = localStorage.getItem('customerToken');
	const {id, jobID} = useParams();

	const history = useHistory();

	const [isLoading, setIsLoading] = useState(true);

	const [provider, setProvider] = useState({});

	useEffect(() => {
		vendorActions.getVendorById(id, customerTokenLocal)
			.then(({data}) => {
				setProvider(data);
				setIsLoading(false);
			});
	}, [id, customerTokenLocal]);

	if (isLoading) return <div className="web-container"><Loader /></div>

	return (
		<>
			<div className="web-container">
				<div className={s.web_ad__title}>
					<img
						src={arrowIcon}
						alt="go to activities"
						onClick={() => history.goBack()}
					/>
					{provider.name}
				</div>
			</div>


			<div>
				<div className="web-container">
					<ProviderWithMap provider={provider} isActivity={true} noShare={true} />
				</div>
				<WebReviews reviews={provider.reviews} id={jobID} />
			</div>
		</>
	);
};

export default WebActivityDetails;
