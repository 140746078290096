const CanadaRegions = [
    {region: 'Ontario', code: 35},
    {region: 'Quebec', code: 24},
    {region: 'Nova Scotia', code: 12},
    {region: 'New Brunswick', code: 13},
    {region: 'Manitoba', code: 46},
    {region: 'British Columbia', code: 59},
    {region: 'Prince Edward Island', code: 11},
    {region: 'Saskatchewan', code: 47},
    {region: 'Alberta', code: 48},
    {region: 'Newfoundland and Labrador', code: 10},
    {region: 'Northwest Territories', code: 61},
    {region: 'Yukon', code: 60},
    {region: 'Nunavut', code: 62},
];

const IrelandRegions = [
    {region: 'Carlow', code: 1},
    {region: 'Cavan', code: 2},
    {region: 'Clare', code: 3},
    {region: 'Cork', code: 4},
    {region: 'Donegal', code: 5},
    {region: 'Dublin', code: 6},
    {region: 'Galway', code: 7},
    {region: 'Kerry', code: 8},
    {region: 'Kildare', code: 9},
    {region: 'Kilkenny', code: 10},
    {region: 'Laois', code: 11},
    {region: 'Leitrim', code: 12},
    {region: 'Limerick', code: 13},
    {region: 'Longford', code: 14},
    {region: 'Louth', code: 15},
    {region: 'Mayo', code: 16},
    {region: 'Meath', code: 17},
    {region: 'Monaghan', code: 18},
    {region: 'Offaly', code: 19},
    {region: 'Roscommon', code: 20},
    {region: 'Sligo', code: 21},
    {region: 'Tipperary', code: 22},
    {region: 'Waterford', code: 23},
    {region: 'Westmeath', code: 24},
    {region: 'Wexford', code: 25},
    {region: 'Wicklow', code: 26},
];

const UnitedStatesRegions = [
    {region: 'Alabama', code: 1},
    {region: 'Alaska', code: 2},
    {region: 'Arizona', code: 4},
    {region: 'Arkansas', code: 5},
    {region: 'California', code: 6},
    {region: 'Colorado', code: 8},
    {region: 'Connecticut', code: 9},
    {region: 'Delaware', code: 10},
    {region: 'District of Columbia', code: 11},
    {region: 'Florida', code: 12},
    {region: 'Georgia', code: 13},
    {region: 'Hawaii', code: 15},
    {region: 'Idaho', code: 16},
    {region: 'Illinois', code: 17},
    {region: 'Indiana', code: 18},
    {region: 'Iowa', code: 19},
    {region: 'Kansas', code: 20},
    {region: 'Kentucky', code: 21},
    {region: 'Louisiana', code: 22},
    {region: 'Maine', code: 23},
    {region: 'Maryland', code: 24},
    {region: 'Massachusetts', code: 25},
    {region: 'Michigan', code: 26},
    {region: 'Minnesota', code: 27},
    {region: 'Mississippi', code: 28},
    {region: 'Missouri', code: 28},
    {region: 'Montana', code: 30},
    {region: 'Nebraska', code: 31},
    {region: 'Nevada', code: 32},
    {region: 'New Hampshire', code: 33},
    {region: 'New Jersey', code: 34},
    {region: 'New Mexico', code: 35},
    {region: 'New York', code: 36},
    {region: 'North Carolina', code: 37},
    {region: 'North Dakota', code: 38},
    {region: 'Ohio', code: 39},
    {region: 'Oklahoma', code: 40},
    {region: 'Oregon', code: 41},
    {region: 'Pennsylvania', code: 42},
    {region: 'Rhode Island', code: 44},
    {region: 'South Carolina', code: 45},
    {region: 'South Dakota', code: 46},
    {region: 'Tennessee', code: 47},
    {region: 'Texas', code: 48},
    {region: 'Utah', code: 49},
    {region: 'Vermont', code: 50},
    {region: 'Virginia', code: 51},
    {region: 'Washington', code: 53},
    {region: 'West Virginia', code: 54},
    {region: 'Wisconsin', code: 55},
    {region: 'Wyoming', code: 56}
];

export {
    CanadaRegions,
    IrelandRegions,
    UnitedStatesRegions
};
