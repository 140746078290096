import React, {useEffect, useState} from 'react';
import s from './PlacesNearby.module.css';

import {providerActions} from "../../action";

import {getDistance} from "geolib";
import {getBrowserLocation} from "../../helpers/currentGeolocation";

import Loader from "../../components/Loader/Loader";
import PlacesMap from "../PlacesMap/PlacesMap";
import ProvidersNearby from "../ProviderNearby/ProvidersNearby";

const PlacesNearby = () => {
	const customerTokenLocal = localStorage.getItem('customerToken');
	const [page, setPage] = useState(1);

	const [userLocation, setUserLocation] = useState(null);
	const [placesNearby, setPlacesNearby] = useState([]);
	const [isAllPlaces, setIsAllPlaces] = useState(false);

	useEffect(() => {
		if (!userLocation) {
			getBrowserLocation()
				.then((currentLocation) => {
					setUserLocation(currentLocation);
				})
				.catch((defaultLocation) => {
					setUserLocation(defaultLocation);
				});
		}

		if (userLocation) {
			providerActions.getAllProviders(customerTokenLocal, page)
				.then(({data}) => {
					const providersByRadius = data.data.filter(provider => {
						let isInRadius = false;

						if (provider.locations.length !== 0) {
							provider.locations.forEach(location => {
								const distance = Math.floor(getDistance(userLocation, {
									lat: parseFloat(location.lat),
									lng: parseFloat(location.lon)
								}) * 0.000621371192);

								if (4500 > distance) isInRadius = true;
							});
						}

						return isInRadius;
					});

					setPlacesNearby([...placesNearby, ...providersByRadius]);
					if (page < data.total) setPage(page + 1);
					if (page === data.total) setIsAllPlaces(true);
				});
		}
	}, [customerTokenLocal, page, userLocation, placesNearby]);

	return (
		<div className={s.places_nearby__wrapper}>
			{!isAllPlaces && <Loader />}
			{isAllPlaces &&
				<>
					<PlacesMap places={placesNearby} />
					<ProvidersNearby providers={placesNearby} />
				</>
			}
		</div>
	);
};

export default PlacesNearby;
