import React, {useEffect, useState} from 'react';
import './Providers.css';

import backArrow from '../../assets/arrows/blue-left-arrow.png';
import forwardArrow from '../../assets/arrows/blue-right-arrow.svg';

import heartIcon from '../../assets/providers-icons/empty-heart.svg';
import heartFavoriteIcon from '../../assets/providers-icons/heart-favorite.svg';

import providerEmail from '../../assets/providers-icons/provider-email.svg';
import providerGlobe from '../../assets/providers-icons/provider-globe.svg';
import providerPhone from '../../assets/providers-icons/provider-phone.svg';
import providerShare from '../../assets/providers-icons/provider-share.svg';

import {useHistory, useParams} from "react-router-dom";

import {vendorActions} from "../../action";
import {providerActions} from '../../action';

import {useDispatch, useSelector} from "react-redux";
import {useJsApiLoader} from "@react-google-maps/api";
import {setProviderReviews} from "../../redux/action-creators";
import {getBrowserLocation} from "../../helpers/currentGeolocation";

import StarRatings from "react-star-ratings";

import LocationMarker from "../LocationMarker/LocationMarker";
import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import ProviderReview from "./ProviderReview";
import Loader from "../Loader/Loader";
import Map from "../Map/Map";

const libraries = ['places'];

const ProviderDetails = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const {id} = useParams();

    const API_KEY = process.env.REACT_APP_API_KEY;

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: API_KEY,
        libraries
    });

    // const [vendorId, setVendorId] = useState('');

    // const customerTokenLocal = localStorage.getItem('customerToken');
    const customerTokenLocal = useSelector(({customerReducer: {customerToken}}) => customerToken);

    const [isLoading, setIsLoading] = useState(false);
    // const [activity, setActivity] = useState({});
    const [vendor, setVendor] = useState({});

    const [isFavorite, setIsFavorite] = useState(false);

    const [center, setCenter] = useState({});

    const [locations, setLocations] = useState([]);

    useEffect(() => {
        setIsLoading(true);

        vendorActions.getVendorById(id, customerTokenLocal)
            .then(response => {

                dispatch(setProviderReviews(response.data.reviews));

                setVendor(response.data);
                setLocations(response.data.locations);
                setIsFavorite(response.data.isFavorite);
                setIsLoading(false);

                if (response.data.locations) {
                     setCenter({
                        lat: parseFloat(response.data?.locations[0]?.lat),
                        lng: parseFloat(response.data?.locations[0]?.lon)
                    });
                } else {
                    getBrowserLocation()
                        .then((currentLocation) => {
                            setCenter(currentLocation);
                        })
                        .catch((defaultLocation) => {
                            setCenter(defaultLocation);
                        });
                }
            });

        // jobActions.getJobById(id, customerTokenLocal)
        //     .then(({data}) => {
        //         console.log('VENDOR : ', data);
        //
        //         setActivity(data.data.job);
        //         setVendorId(data.data.vendor.id);
        //
        //         setIsFavorite(data.data.isFavorite);
        //
        //         setIsLoading(false);
        //     });
    }, [customerTokenLocal, id]);

    const addOrRemoveProviderFromFavorites = () => {
        providerActions.addProviderToFavorite(customerTokenLocal, id)
            .then(response => {
                if (response.ok) setIsFavorite(!isFavorite);
            });
    }

    const showProviderLocations = () => {
        if (locations.length === 0) return;

        return locations.map(location => <LocationMarker key={location.id} position={{
            lat: parseFloat(location.lat),
            lng: parseFloat(location.lon)
        }} />);
    }

    const showReviews = () => {
        if (!vendor?.reviews?.length) return <div className={'no-reviews'}>No reviews yet</div>;

        return (
            <>
                <ProviderReview
                    title={vendor?.reviews[0]?.consumer?.first_name}
                    rating={vendor?.reviews[0]?.rating}
                    key={vendor?.reviews[0]?.id} />

                {vendor?.reviews?.length > 1 &&
                    <ProviderReview
                        title={vendor.reviews[1].consumer.first_name}
                        rating={vendor.reviews[1].rating}
                        key={vendor.reviews[1].id} />
                }
            </>
        );
    }

    if (isLoading) return <div className={'center-loader'}><Loader /></div>;

    const showLocations = () => {
        if (!locations.length) return (
            <div className={'no-address-loc'}>No address</div>
        );

        return (
            <div className={'locations__provider'}>
                {locations.map(location => <div key={location.id}>{location.add_1}</div>)}
            </div>
        );
    }

    return (
        <div className={'providers__wrapper'}>
            <HeaderMobile />

            <div className={'providers__inner'}>
                <div className={'providers__title-wrapper'}>
                    <img
                        src={backArrow}
                        alt="go back arrow"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    <div className={'providers__title sub'}>{vendor.name}</div>
                    <div />
                </div>

                <div className={'provider-activity'}>
                    <div className={'provider-activity__title'}>{vendor.name}</div>

                    <div className={'provider-activity__rating'}>
                        <div>
                            <StarRatings
                                rating={vendor.ratingInStars ? vendor.ratingInStars : 0}
                                starRatedColor='rgba(255, 188, 58, 1)'
                                numberOfStars={5}
                                starSpacing={'4px'}
                                isSelectable={false}
                                name='rating'
                                svgIconViewBox={'0 0 15 14'}
                                svgIconPath={'M6.54894 0.927048C6.8483 0.00573778 8.1517 0.0057404 8.45106 0.927051L9.40837 3.87336C9.54224 4.28538 9.9262 4.56434 10.3594 4.56434H13.4574C14.4261 4.56434 14.8289 5.80395 14.0451 6.37336L11.5389 8.19427C11.1884 8.44892 11.0417 8.90028 11.1756 9.31231L12.1329 12.2586C12.4323 13.1799 11.3778 13.946 10.5941 13.3766L8.08778 11.5557C7.7373 11.3011 7.2627 11.3011 6.91221 11.5557L4.40594 13.3766C3.62222 13.946 2.56774 13.1799 2.8671 12.2586L3.82441 9.3123C3.95828 8.90028 3.81162 8.44892 3.46114 8.19427L0.954859 6.37335C0.171145 5.80395 0.573923 4.56434 1.54265 4.56434H4.64057C5.0738 4.56434 5.45776 4.28538 5.59163 3.87336L6.54894 0.927048Z'}
                            />
                        </div>

                        {vendor.reviewsCount} reviews
                    </div>

                    <div className={'provider-activity__icons'}>
                        <div>
                            <a href={'tel:' + vendor?.contact_work_tel}>
                                <img src={providerPhone} alt="call provider"/>
                            </a>

                            <a href={'mailto:' + vendor?.contact_email}>
                                <img src={providerEmail} alt="email provider"/>
                            </a>

                            <a href={vendor?.url} target={'_blank'}>
                                <img src={providerGlobe} alt="globe"/>
                            </a>

                            <img
                                src={isFavorite ? heartFavoriteIcon : heartIcon}
                                alt="favorite provider"
                                onClick={addOrRemoveProviderFromFavorites}
                            />
                        </div>

                        <img
                            src={providerShare}
                            alt="share provider"
                            onClick={() => {
                                history.push(`/customers/${id}`)
                            }}
                        />
                    </div>
                </div>

                <div
                    className={'provider-subtitle'}
                    onClick={() => {
                        history.push('/reviews');
                    }}
                >
                    See reviews
                    <img src={forwardArrow} alt="show reviews arrow"/>
                </div>

                <div className={'provider-reviews'}>
                    {showReviews()}
                </div>

                <div
                    className={'provider-subtitle'}
                    onClick={() => {
                        history.push('/reviews');
                    }}
                >
                    Locations
                </div>
                {showLocations()}

                <div className={'provider-subtitle'}>
                    <a href={`http://maps.google.com/?ie=UTF8&hq=&ll=${center.lat},${center.lng}&z=13`} target={'_blank'}>
                        Get directions
                    </a>
                </div>

                {isLoaded &&
                    <Map center={center}>
                        {showProviderLocations()}
                    </Map>
                }
            </div>
        </div>
    );
}

export default ProviderDetails;
