import React, {useEffect, useState} from 'react';
import './VendorIntro.css';

import crossIcon from "../../../assets/close.png";

import OneSignal from "react-onesignal";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";

import {vendorActions} from "../../../action";
import {setCurrentVendor, setVendorToken} from "../../../redux/action-creators";

import AuthMobileHeader from "../../headers/AuthMobileHeader/AuthMobileHeader";
import Modal from "../../Modal/Modal";
import VendorPopUp from "./VendorPopUp";

const VendorIntro = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const vendorDeviceId = localStorage.getItem('vendorDeviceId');
    // const vendorDeviceId = 'a8b76b89-a1bc-4361-818a-50cab764f46d'
    // console.log('Vendor device id: ', !!vendorDeviceId, ' value: ', vendorDeviceId);

    const [loginError, setLoginError] = useState(null);
    const [modalActive, setModalActive] = useState(false);
    const [vendorLoginError, setVendorLoginError] = useState(false);
    const [popupActive, setPopupActive] = useState(false);

    const vendorLogIn = () => {

        vendorActions.vendorLogin({device_id: vendorDeviceId})
            .then(({data}) => {

                if (data.ok) {
                    dispatch(setVendorToken(data.data.token));
                    dispatch(setCurrentVendor(data.data.user));

                    localStorage.setItem('vendorToken', data.data.token);
                    localStorage.setItem('currentVendor', JSON.stringify(data.data.user));

                    return;
                }

                setVendorLoginError(data);
            })
            .catch(e => {
                setLoginError('Wrong email or password');
            });
    }

    useEffect(() => {
        async function checkId() {
            if (!vendorDeviceId) {
                const id = OneSignal.getUserId();
                localStorage.setItem('vendorDeviceId', await id);
                localStorage.setItem('onesignalId', await id);

                setPopupActive(true);
            }
        }

        checkId();
    }, [vendorDeviceId]);

    return (
        <div>
            <AuthMobileHeader />

            <div className={'vendor-intro'}>
                <button
                    className={'vendor-intro__button active'}
                    onClick={() => {
                        vendorLogIn();
                    }}
                >Login</button>

                <button
                    className={'vendor-intro__button active green'}
                    onClick={() => {
                        history.push('/vendor/device-id');
                    }}
                >Show my device ID</button>
                <div
                    className={'vendor-intro__start'}
                    onClick={() => {
                        history.push('/vendor/signup');
                    }}
                >Become a vendor</div>
            </div>

            {loginError &&
                <Modal active={modalActive} setActive={setModalActive}>
                    <div className={'vendor-errors'}>
                        <img
                            src={crossIcon}
                            alt="cross"
                            onClick={() => {
                                setModalActive(false);
                            }}
                        />

                        <div>
                            <span>Error</span>

                            <div>{loginError}</div>
                        </div>
                    </div>
                </Modal>
            }

            {vendorLoginError &&
                <Modal active={vendorLoginError} setActive={setVendorLoginError}>
                    <div className={'vendor-errors'}>
                        <img
                            src={crossIcon}
                            alt="cross"
                            onClick={() => {
                                setVendorLoginError(false);
                            }}
                        />

                        <div>
                            <span>Error</span>

                            <div>{vendorLoginError}</div>
                        </div>
                    </div>
                </Modal>
            }

            <VendorPopUp active={popupActive} setActive={setPopupActive}>
                <div className={'pop-up__message'}>
                    Please allow this site to send you notifications to use this product
                </div>
            </VendorPopUp>
        </div>
    );
};

export default VendorIntro;
