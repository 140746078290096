import React from 'react';

import smileIcon from '../../assets/activity-icons/smile.svg';

import {useHistory} from "react-router-dom";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";

const Reviewed = () => {
    const history = useHistory();

    return (
        <div className={'activities-wrapper'}>
            <HeaderMobile />

            <div className={'activities-inner'}>
                <div className={'activities-inner__nav sub'}>
                    <div />
                    <div className={'activities__title sub'}>Leave a review</div>
                    <div />
                </div>

                <img className={'review__img'} src={smileIcon} alt="smile"/>

                <div className={'review__thx'}>Thank you!</div>

                <div className={'review__text'}>
                    Your review has been received and the rewards associated with this job will be issued shortly.
                </div>

                <button
                    className={'review__button bold'}
                    onClick={() => {
                        history.push('/activities');
                    }}
                >Back to activities</button>
            </div>
        </div>
    );
};

export default Reviewed;
