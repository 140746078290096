import React, {useEffect, useState} from 'react';
import './Providers.css';

import {useParams} from "react-router-dom";
import {categoriesActions} from '../../action';

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import Provider from "./Provider";
import Loader from "../Loader/Loader";
import {useSelector} from "react-redux";

const Providers = () => {
    const {id} = useParams();

    // const customerTokenLocal = localStorage.getItem('customerToken');
    const customerTokenLocal = useSelector(({customerReducer: {customerToken}}) => customerToken);

    const [isLoading, setIsLoading] = useState(false);
    const [providersList, setProviders] = useState([]);
    const [removed, setRemoved] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        categoriesActions.getCategoryVendor(id, customerTokenLocal)
            .then(response => {
                setProviders(response.data);
                setIsLoading(false);
            });
    }, [customerTokenLocal, id]);

    const loadProviders = () => {
        if (isLoading) return <Loader />;

        if (!providersList.length) return <div className={'providers-not-found'}>Providers not found 😔</div>;
    }

    return (
        <div className={'providers__wrapper'}>
            <HeaderMobile />

            <div className={'providers__inner'}>
                <div className={'providers__title'}>Providers</div>

                {loadProviders()}

                {providersList.map(provider => {
                    return (
                        <Provider
                            key={provider.id}
                            id={provider.id}
                            rating={provider.ratingInStars}
                            categories={[]}
                            name={provider.name}
                            reviews={provider.reviews}
                            address={provider?.locations ? provider?.locations[0]?.add_1 : ''}
                            isFavorite={provider.isFavorite}
                            removed={removed}
                            setRemoved={setRemoved}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Providers;
