import React, {useEffect, useState} from 'react';
import './SalesIntro.css';

import qrCodaIcon from '../../../assets/rewards/qr-code.svg';
import returnIcon from '../../../assets/rewards/return.svg';
import logo from '../../../assets/logos/MOR-logo.svg';
import infoIcon from '../../../assets/info.png';

import {uuidActions} from "../../../action";
import {setMessageActive, setPurchaseReturned} from "../../../redux/action-creators";

import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

import HeaderMobile from "../../headers/HeaderMobile/HeaderMobile";

const SalesIntro = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const vendor = useSelector(({vendorReducer : {currentVendor}}) => currentVendor);
    const messageActive = useSelector(({messageReducer: {messageActive}}) => messageActive);
    const purchaseReturned = useSelector(({messageReducer: {purchaseReturned}}) => purchaseReturned);

    // const vendorTokenLocal = localStorage.getItem('vendorToken');
    const oneSignalUuid = localStorage.getItem('onesignalId');

    const [vendorName, setVendorName] = useState('');

    useEffect(() => {
        if (vendor) {
            setVendorName(vendor.name);
        }
    }, [vendor]);

    useEffect(() => {
        if (messageActive) {
            setTimeout(() => {
                dispatch(setMessageActive(false));
            }, 3000);
        }

        if (purchaseReturned) {
            setTimeout(() => {
                dispatch(setPurchaseReturned(false));
            }, 3000);
        }
    }, [messageActive, purchaseReturned, dispatch]);

    useEffect(() => {
        if (oneSignalUuid) {
            uuidActions.setUuid(oneSignalUuid, {uuid: oneSignalUuid});
        }
    }, [oneSignalUuid]);

    const messageByActions = () => {
        if (messageActive) {
            return (
                <>
                    <div>Success!</div>
                    <div>transaction completed. Thank you</div>
                    <div>for using MOREWARDS</div>
                </>
            );
        }

        if (purchaseReturned) {
            return (
                <>
                    <div>Success!</div>
                    <div>Purchase successfully returned</div>
                </>
            );
        }
    }

    return (
        <div className={'sales-wrapper'}>
            <HeaderMobile vendor={true} />

            <div className={'sales-inner'}>
                <div className={messageActive || purchaseReturned
                    ? 'sales-inner__message active'
                    : 'sales-inner__message'
                }>
                    <img src={infoIcon} alt="info about transaction"/>

                    <div className={'sales-inner__message-text'}>
                        {messageByActions()}
                    </div>
                </div>

                <img className={'rewards-inner__logo'} src={logo} alt="morewards logo"/>

                <div className={'sales__title'}>Hello, {vendorName}!</div>

                <div className={'sales__buttons'}>
                    <div
                        className={'sales__button'}
                        onClick={() => {
                            history.push('/find-customer')
                        }}
                    >
                        <img src={qrCodaIcon} alt="qr code"/>
                        <div>Scan QR code</div>
                    </div>

                    <div
                        className={'sales__button'}
                        onClick={() => {
                            history.push('/scan-return');
                        }}
                    >
                        <img src={returnIcon} alt="return"/>
                        <div>Scan for return</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SalesIntro;
