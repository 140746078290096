import React, {useEffect, useState} from 'react';
import './Activities.css';

import goBackArrow from "../../assets/arrows/blue-left-arrow.png";

import {useHistory, useParams} from "react-router-dom";
import {jobActions} from '../../action';

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import Loader from "../Loader/Loader";
import Details from "./Details";
import {useSelector} from "react-redux";

const ActivityDetails = ({vendor}) => {
    const history = useHistory();
    const {id} = useParams();

    // const customerToken = localStorage.getItem('customerToken');
    const customerToken= useSelector(({customerReducer: {customerToken}}) => customerToken);

    const vendorToken = localStorage.getItem('vendorToken');

    const [activity, setActivity] = useState({});

    useEffect(() => {
        if (vendor) {
            jobActions.getJobById(id, vendorToken)
                .then(({data}) => {
                    setActivity(data.data);
                });

            return;
        }

        jobActions.getJobById(id, customerToken)
            .then(({data}) => {
                setActivity(data.data);

            });
    }, [customerToken, id, vendor, vendorToken]);

    const isActivity = () => {
        if (!activity.job?.title) return <Loader />;

        return (
            <>
                <div className={'activities-inner__nav sub'}>
                    <img
                        src={goBackArrow}
                        alt="go back arrow"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    <div className={'activities__title sub'}>{vendor ? 'Activity details' :activity.title}</div>
                    <div />
                </div>

                <Details activity={activity} vendor={vendor} />

                {!vendor &&
                    <button
                        className={'activities__button active'}
                        onClick={() => {
                            history.push(`/provider/${activity.vendor.id}`);
                        }}
                    >Show vendor/service provider info</button>
                }
            </>
        );
    }

    return (
        <div className={'activities-wrapper'}>
            <HeaderMobile />

            <div className={'activities-inner'}>
                {isActivity()}
            </div>
        </div>
    );
};

export default ActivityDetails;
