import {SET_QR_RESULT} from "../action-types";

const initialState = {
    result: ''
}

const reducer = (state= initialState, action) => {
    switch (action.type) {
        case SET_QR_RESULT: {
            return {
                ...state,
                result: action.payload
            }
        }

        default: return state;
    }
}

export default reducer;
