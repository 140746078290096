import React, {useState} from 'react';
import './VendorSignUp.css';

import backArrow from "../../../assets/arrows/blue-left-arrow.png";
import crossIcon from '../../../assets/close.png';

import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {VendorSignUpSchema} from "../../../validators";

import {phoneNormalizer} from '../../../helpers/phone-normalizer';
import {vendorSignup} from "../../../action/vendor.actions";

import Checkbox from "../../customer-authorization/Checkbox/Checkbox";
import VendorTerms from "../../customer-authorization/TermsMobile/VendorTerms";
import CodeSelector from "../../selectors/CodeSelector";
import Modal from "../../Modal/Modal";

const VendorSignUp = () => {
    const history = useHistory();

    const [code, setCode] = useState('');

    const [checked, setChecked] = useState(false);

    const [terms, setTerms] = useState(false);

    const [modalActive, setModalActive] = useState(false);

    const [errorModal, setErrorModal] = useState(false);
    const [createVendorError, setCreateVendorError] = useState(null);

    const { register, handleSubmit, formState: {errors}} = useForm({
        mode: 'onBlur',
        resolver: yupResolver(VendorSignUpSchema)
    });

    const vendorSignUp = (data) => {
        setChecked(true);
        setModalActive(false);

        const phonecode = code ? code : '+1';
        const phone = phonecode + ' ' + data.phoneNumber;
        data.phone = phone;

        delete data.phoneNumber;

        vendorSignup(data)
            .then(({data}) => {
                localStorage.setItem('vendorPhone', phone);
                history.push('/vendor/verification');
            })
            .catch((e) => {
                if (e.message === 'Request failed with status code 401') {
                    setCreateVendorError('Vendor already exist');
                    setErrorModal(true);
                }
            });
    }

    return (
        <>
            <form
                style={{display: terms ? 'none' : 'flex'}}
                className={'signup-form'}
                noValidate={true}
                onSubmit={handleSubmit(vendorSignUp)}
            >
                <div className={'signup-form__title'}>
                    <img
                        src={backArrow}
                        alt="back arrow"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    Register
                    <div />
                </div>

                <div className={'signup-form__text-description'}>
                    <div>New to MOREWARDS?</div>
                    <div>If you are a local service provider</div>
                    <div>you can join us as a Vendor.</div>
                </div>

                <input
                    className={'signup-form__input'}
                    {...register('company_name')}
                    id={'company_name'}
                    type={'text'}
                    placeholder={'Company Name'}
                />
                <input
                    className={'signup-form__input'}
                    {...register('firstname')}
                    id={'firstname'}
                    type={'text'}
                    placeholder={'First Name'}
                />
                <input
                    className={'signup-form__input'}
                    {...register('lastname')}
                    id={'lastname'}
                    type={'text'}
                    placeholder={'Last Name'}
                />

                <div className={'signup-form__inputs'}>
                    <CodeSelector code={code} setCode={setCode} />

                    <input
                        className={'signup-form__input phone-number'}
                        {...register('phoneNumber')}
                        id={'phoneNumber'}
                        type={'tel'}
                        placeholder={'999-999-9999'}
                        onChange={(event) => {
                            const {value} = event.target;
                            event.target.value = phoneNormalizer(value);
                        }}
                    />
                </div>

                <input
                    className={'signup-form__input'}
                    {...register('email')}
                    id={'email'}
                    type={'email'}
                    placeholder={'Email address'}
                />

                <input
                    className={'signup-form__input'}
                    {...register('referral_code')}
                    id={'referral_code'}
                    type={'text'}
                    placeholder={'Referral code (if you have one)'}
                />

                <div className={'signup-form__terms'}>
                    <Checkbox
                        checked={checked}
                        setChecked={setChecked}
                        setTerms={setTerms}
                    />
                    <div className={'signup-form__terms-message'}>
                        By registering you agree to our terms and conditions
                    </div>
                </div>

                <button
                    className={'signup-form__button active'}
                    type={'submit'}
                    onClick={() => {
                        if (Object.keys(errors).length || !code) {
                            setModalActive(true);
                        }
                    }}
                >Apply</button>
            </form>

            {modalActive &&
                <Modal active={modalActive} setActive={setModalActive}>
                    <div className={'vendor-errors'}>
                        <img
                            src={crossIcon}
                            alt="cross"
                            onClick={() => {
                                setModalActive(false);
                            }}
                        />

                        <div>
                            <span>Error</span>
                            {errors?.company_name && <div>{errors.company_name.message}</div>}
                            {errors?.firstname && <div>{errors.firstname.message}</div>}
                            {errors?.lastname && <div>{errors.lastname.message}</div>}
                            {!code &&<div>Please select the mobile code</div>}
                            {errors?.phoneNumber && <div>{errors.phoneNumber.message}</div>}
                            {errors?.email && <div>{errors.email.message}</div>}
                            {errors?.referral_code && <div>{errors.referral_code.message}</div>}
                        </div>
                    </div>
                </Modal>
            }
            {createVendorError &&
                <Modal active={errorModal} setActive={setErrorModal}>
                    <div className={'vendor-errors'}>
                        <img
                            src={crossIcon}
                            alt="cross"
                            onClick={() => {
                                setCreateVendorError(false);
                            }}
                        />

                        <div>
                            <span>Error</span>
                            <div>{createVendorError}</div>
                        </div>
                    </div>
                </Modal>
            }
            {terms && <VendorTerms setTerms={setTerms}/>}
        </>
    );
};

export default VendorSignUp;
