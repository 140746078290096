const IrelandZipCodeNormalizer = (value, previousValue) => {
    if (!value) return value;

    const currentValue = value.replace(/\s/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
        if (cvLength < 4) return currentValue;

        if (cvLength < 7) return `${currentValue.slice(0, 3)} ${currentValue.slice(3)}`;

        return `${currentValue.slice(0, 3)} ${currentValue.slice(3, 7)}`;
    }
};

const CanadaZipCodeNormalizer = (value, previousValue) => {
    if (!value) return value;

    const currentValue = value.replace(/\s/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
        if (cvLength < 4) return currentValue;

        return `${currentValue.slice(0, 3)} ${currentValue.slice(3, 6)}`;
    }
};

const USAZipCodeNormalizer = (value, previousValue) => {
    if (!value) return value;

    const currentValue = value.replace(/\s/g, '');
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
        if (cvLength < 4) return currentValue;

        return `${currentValue.slice(0, 5)}`;
    }
};

export {
    IrelandZipCodeNormalizer,
    CanadaZipCodeNormalizer,
    USAZipCodeNormalizer
}
