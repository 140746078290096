const getQrData = (data) => {
    const result = [...data];
    const array = ['', '', '', ''];
    let i = 0;

    result.forEach(item => {
        if (item !== ',') {
            array[i] += item;
            return;
        }

        i++
    });

    const qrTime = Date.now() - array[1];

    if (qrTime > 180000) {
        return {ok: false}
    }

    return {
        ok: true,
        data: {
            transactionType: array[0],
            customerId: array[2]
        }
    }
}

export default getQrData;
