import React, {useState} from 'react';

import {customerActions} from '../../action';
import Loader from "react-spinners/BarLoader";
import {useSelector} from "react-redux";

const Customer = ({customer, vendorId, setModalActive, setErrorMessage, setProviderSharedToUser}) => {
    // const token = localStorage.getItem('customerToken');
    const token = useSelector(({customerReducer: {customerToken}}) => customerToken);

    const data = {
        vendor_id: parseInt(vendorId),
        customer_id: customer.id,
        link: 'http://pwa.locallyselected.com/provider/' + vendorId
    }

    const [isLoading, setIsLoading] = useState(false);

    const sendData = () => {
        if (isLoading) return;

        setIsLoading(true);

        customerActions.residualCustomer(data, token)
            .then(response => {
                if (response.ok) {
                    setProviderSharedToUser(true);
                    setIsLoading(false);

                    return;
                }

                if (!response.ok) {
                    setIsLoading(false);
                    setModalActive(true);
                    setErrorMessage(response.message);
                }
            });
    }

    return (
        <div
            className={'customer'}
            onClick={sendData}
        >
            <div>
                <div className={'customer__name'}>{customer.first_name} {customer.last_name}</div>
                <div className={'customer__email'}>{customer.email}</div>
            </div>

            <div className={'customer__share'}>
                Share
            </div>
        </div>
    );
};

export default Customer;
