import React, {useRef , useEffect} from "react";
import "./tour.css";
import cancelSvg from '../../assets/tour/cancel.svg'

const Tour = (props) => {
  const {tourCount , setTourCount} = props;

  const componentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        // Click occurred outside of the component
        // Handle your logic here
        console.log('Click occurred outside of the component');
        setTourCount(tourCount + 1);
      }
    };

    // Bind the event listener
    document.addEventListener('click', handleClickOutside);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div ref={componentRef} className={`bubble bubble_${tourCount}`} contenteditable>
        <div className="closeBtn" onClick={()=>{setTourCount(tourCount + 1)}}><img src={cancelSvg}/></div>
        
        <div className="innerWrap">
            <h3>Type any text here </h3>
            <p>Type any text here and the bubble will grow to fit the text no matter how many lines. Isn't that nifty?</p>
        </div>
    </div>
  );
};

export default Tour;
