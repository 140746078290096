import React, { useRef, useState } from "react";

import goBackArrow from "../../../assets/arrows/blue-left-arrow.png";
import crossIcon from "../../../assets/close.png";

import QrReader from "react-qr-reader";
import { useHistory } from "react-router-dom";

import getQrData from "../../../helpers/getQrData";
import { jobActions } from "../../../action";

import HeaderMobile from "../../headers/HeaderMobile/HeaderMobile";
import Modal from "../../Modal/Modal";
import Loader from "../../Loader/Loader";

const ReturnScan = () => {
  const history = useHistory();

  const vendorTokenLocal = localStorage.getItem("vendorToken");

  const [modalActive, setModalActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loaderActive, setLoaderActive] = useState(false);

  const qrRef = useRef(null);

  const onScanFile = () => {
    qrRef.current.openImageDialog();
  };

  const handleScanFile = (result) => {
    if (result) {
      const response = getQrData(result);

      const isCashOrRewards =
        response.data?.transactionType === "payedWithCashOrCredit" ||
        response.data?.transactionType === "payedWithRewards";
      const isReturn = response.data?.transactionType === "return";

      if (isReturn) {
        if (!response.ok) {
          setErrorMessage(
            "QR Code is not valid. Please ask customer to generate new one."
          );
          setModalActive(true);

          return;
        }

        if (response.ok) {
          setLoaderActive(true);
          const id = parseInt(response.data.customerId);

          jobActions.getJobById(id, vendorTokenLocal).then(({ data }) => {
            if (data.ok) {
              localStorage.setItem(
                "confirmReturn",
                JSON.stringify({
                  qrCodeTransactionType: "return",
                  jobId: id,
                  amount_paid: data.data.job.amount_paid,
                  category: data.data.job.title,
                })
              );

              history.push("/confirm-return");
            }
          });
        }

        return;
      }

      if (isCashOrRewards) {
        setErrorMessage(
          "In order to scan this QR code please select " +
            "'Scan QR code' option on home page."
        );
        setModalActive(true);

        return;
      }

      if (!isReturn && !isCashOrRewards) {
        setErrorMessage(
          "Wrong QR code. Please make sure you are scanning MoRewards QR Code."
        );
        setModalActive(true);
      }
    }
  };

  const handleErrorFile = (error) => {};

  if (loaderActive)
    return (
      <div className={"find-customer__loader"}>
        <Loader />
      </div>
    );

  return (
    <>
      <div className={"find-customer__wrapper"}>
        <HeaderMobile vendor={true} />

        <div className={"find-customer__inner"}>
          <div className={"find-customer__title"}>
            <img
              src={goBackArrow}
              alt="go back arrow"
              onClick={() => {
                history.goBack();
              }}
            />
            <div>Find customer</div>
            <div />
          </div>

          <label onClick={onScanFile} className={"find-customer__label"}>
            Scan Customer QR code
          </label>

          <div className={"find-customer__qr-code"}>
            <QrReader
              ref={qrRef}
              delay={300}
              style={{ width: "100%" }}
              onError={handleErrorFile}
              onScan={handleScanFile}
              legacyMode={true}
            />
          </div>
        </div>

        <Modal active={modalActive} setActive={setModalActive}>
          <div className={"vendor-errors"}>
            <img
              src={crossIcon}
              alt="cross"
              onClick={() => {
                setModalActive(false);
              }}
            />

            <div>
              <span>Error</span>

              <div>{errorMessage}</div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ReturnScan;
