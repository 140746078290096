import React, {useCallback, useEffect, useRef, useState} from 'react';

import goBackArrow from "../../assets/arrows/blue-left-arrow.png";

import {useHistory} from "react-router-dom";

import {jobActions} from "../../action";
import {useObserver} from "../../hooks/useObserver";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import Loader from "../Loader/Loader";
import Activity from "../Activities/Activity";
import {useSelector} from "react-redux";

const ReturnActivities = () => {
    const history = useHistory();

    // const customerTokenLocal = localStorage.getItem('customerToken');
    const customerTokenLocal = useSelector(({customerReducer: {customerToken}}) => customerToken);

    const [activities, setActivities] = useState([]);

    const lastElement = useRef();
    const [page, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [isLoading, setIsLoading] = useState(true);

    useObserver(lastElement, page <= totalPages, isLoading,  () => {
        setCurrentPage(page + 1);
    });

    const getActivities = useCallback(() => {
        setIsLoading(true);

        jobActions.getJobs(page, customerTokenLocal)
            .then(({data}) => {
                if (page === data.jobs.last_page) {
                    setIsLoading(false);
                    return;
                }

                setTotalPages(data.jobs.last_page);

                const activitiesList = data.jobs.data.filter(activity => {
                    return activity.status === 'complete' || activity.status === 'reviewed';
                });

                if (!activitiesList.length) {
                    setCurrentPage(page + 1);

                    return;
                }

                setActivities([...activities, ...activitiesList]);
                setIsLoading(false);
            });
    }, [customerTokenLocal, page]);

    useEffect(() => {
        getActivities();
    }, [getActivities]);

    useEffect(() => {
        if (page === totalPages) setIsLoading(false);
    }, [page, totalPages]);

    return (
        <div className={'activities-wrapper'}>
            <HeaderMobile />

            <div className={'activities-inner'}>
                <div className={'activities-inner__nav sub m'}>
                    <img
                        src={goBackArrow}
                        alt="go back arrow"
                        onClick={() => {
                            history.push('/account');
                        }}
                    />
                    <div className={'activities__title sub'}>My activities</div>
                    <div />
                </div>

                {activities.map(activity => <Activity activity={activity} key={activity.id} returns={true}/>)}
                {isLoading && <Loader />}
                {!isLoading && <div ref={lastElement} />}

            </div>
        </div>
    );

    // className={'last-element'}
};

export default ReturnActivities;
