import React from 'react';
import './VendorAuth.css';

import {Route, Switch} from 'react-router-dom';
import useWindowDimensions from "../../hooks/windowDimensions.hook";

import VendorIntro from "../../components/vendor-authorization/VendorIntro/VendorIntro";
import VendorSignUp from "../../components/vendor-authorization/VendorSignUp/VendorSignUp";
import Verification from "../../components/customer-authorization/Verification/Verification";
import DeviceId from "../../components/vendor-authorization/DeviceID/DeviceID";
import ContactMessage from "../../components/vendor-authorization/VendorSignUp/ContactMessage";

const VendorAuth = () => {
    const { width } = useWindowDimensions();
    const isMobileScreen = width < 600;

    const headerDependsOfScreen = () => {
        if (isMobileScreen) {
            return (
                <Switch>
                    <Route path={'/vendor/contact-message'} component={ContactMessage} />

                    <Route path={'/vendor/device-id'}>
                        <DeviceId />
                    </Route>
                    <Route path={'/vendor/verification'}>
                        <Verification vendorVerification={true} />
                    </Route>
                    <Route path={'/vendor/signup'} >
                        <VendorSignUp />
                    </Route>

                    <Route path={'/vendor'} component={VendorIntro} />
                    <Route path={'*'} component={VendorIntro} />
                </Switch>
            );
        }

        return (
            <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{backgroundColor: '#FFF', borderRadius: '20px', fontSize: '20px', width: '500px', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <h2 style={{color: '#000'}}>
                        Please open this link in a mobile device browser
                    </h2>
                </div>
            </div>
        );
    }

    return (
        <div className={'vendor-wrapper'}>
            <div className={'vendor-page'}>
                {headerDependsOfScreen()}
            </div>
        </div>
    );
};

export default VendorAuth;
