import {CanadaRegions, IrelandRegions, UnitedStatesRegions} from '../data/regions';

let code = '';

export const regionCode = (country, state) => {
    if (country === 'Canada') {
        CanadaRegions.forEach(region => {
            if (region.region === state) {
                code = region.code;
            }
        });

        return code;
    }

    if (country === 'United States') {
        UnitedStatesRegions.forEach(region => {
            if (region.region === state) {
                code = region.code;
            }
        });

        return code;
    }

    if (country === 'Ireland') {
        IrelandRegions.forEach(region => {
            if (region.region === state) {
                code = region.code;
            }
        });

        return code;
    }
}
