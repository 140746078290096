import React, {useEffect, useState} from 'react';
import s from "./WebCategory.module.css";

import {categoriesActions} from '../../action';
import backArrow from "../../assets/arrows/blue-left-arrow.png";

const SubCategories = (
	{
		parentCategoryId,
		searchValue,
		setSearchBySubCategoryName,
		setSearchByProviderName,
		setShowCategoryProvidersID,
		setSearchByCategoryName,
	}) => {
	const customerTokenLocal = localStorage.getItem('customerToken');

	const [subCategories, setSubCategories] = useState([]);

	useEffect(() => {
		categoriesActions.getCategoriesListSub(parentCategoryId, customerTokenLocal)
			.then(({data}) => {
				setSubCategories(data.subCategories);
				// console.log('sub categories web: ', data);
			})
	}, [customerTokenLocal, parentCategoryId]);

	const searchSubCategory = () => {
		return subCategories.filter(category => {
			return category.name.toLowerCase().includes(searchValue.toLowerCase());
		})
	}

	return (
		<>
			<div className={s.categories__title}>Sub categories</div>

			<img
				// style={{marginTop: '20px'}}
				className={s.goBack__arrow}
				src={backArrow}
				alt="go back arrow"
				onClick={() => setSearchByCategoryName(true)}
			/>

			<div className={s.categories}>
				{searchSubCategory().map(category => <div
					key={category.id}
					className={s.category}
					onClick={() => {
						setSearchBySubCategoryName(false);

						setShowCategoryProvidersID(category.id);
						setShowCategoryProvidersID(category.user_id);

						setSearchByProviderName(true);
						// setParentCategoryId(category.id);

						// setShowCategoryProvidersID(category.id);
						// setSearchByProviderName(true);
					}}
				>{category.name}</div>)}
			</div>
		</>
	);
};

export default SubCategories;
