import React, {useState} from 'react';
import './CountriesSelector.css';

const CategorySelector = ({categories, category, setCategory}) => {
    const [isActive, setIsActive] = useState();

    const codeSelectorActive = () => {
        if (isActive) {
            return (
                <div className={'selector-content category'}>
                    {categories.map(category => {
                        return (
                            <div
                                className={'selector-content__item category'}
                                key={category.id}
                                onClick={() => {
                                    setCategory(category);
                                    setIsActive(false);
                                }}
                            >
                                {category.name}
                            </div>
                        );
                    })}
                </div>
            );
        }
    }

    return (
        <div className={'selector-wrapper category'}>
            <div
                className={'selector-button'}
                onClick={() => {
                    setIsActive(!isActive);
                }}
                style={{
                    color: category?.name ? '#4B4846' : '#8E8E93'
                }}
            >
                {category.name ? category.name : 'Choose category'}
            </div>

            {codeSelectorActive()}
        </div>
    );
};

export default CategorySelector;
