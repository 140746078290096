import React, {useState} from 'react';
import './CountriesSelector.css';

import {countries} from '../../data/countries';

const CountriesSelector = ({country, setCountry, setCountryCode, setState, setStates, setZipCodeInputName}) => {
    const [isActive, setIsActive] = useState(false);

    const isCountriesSelectorActive = () => {
        if (isActive) {
            return (
                <div className={'selector-content'}>
                    {countries.map(country => {
                        return (
                            <div
                                className={'selector-content__item'}
                                key={country.country}
                                onClick={e => {
                                    setStates && setState('');
                                    setStates && setStates(country.states);

                                    setZipCodeInputName && setZipCodeInputName(e.target.textContent.trim() + 'ZipCode');

                                    setCountry(e.target.textContent);
                                    setCountryCode(country.code);

                                    setIsActive(false);
                                }}
                            >
                                {country.country}
                            </div>
                        );
                    })}
                </div>
            );
        }
    }

    return (
        <div className={'selector-wrapper countries'}>
            <div
                className={'selector-button'}
                onClick={() => {
                    setIsActive(!isActive);
                }}
                style={{
                    color: country ? '#4B4846' : '#8E8E93'
                }}
            >
                {country ? country : 'Country'}
            </div>

            {isCountriesSelectorActive()}
        </div>
    );
};

export default CountriesSelector;
