import React, {useRef} from 'react';
import {GoogleMap} from "@react-google-maps/api";

const containerStyle = {
	width: '100%',
	height: '285px'
};

const defaultOptions = {
	panControl: true,
	zoomControl: false,
	mapTypeControl: false,
	scaleControl: false,
	streetViewControl: false,
	rotateControl: false,
	clickableIcons: false,
	keyboardShortcuts: false,
	scrollwheel: true,
	disableDoubleClickZoom: false,
	fullscreenControl: false,
}

const Map = ({center, children}) => {
	const mapRef = useRef(undefined);

	// const [map, setMap] = React.useState(null);

	const onLoad = React.useCallback(function callback(map) {
		mapRef.current = map;
	}, [])

	const onUnmount = React.useCallback(function callback() {
		mapRef.current = undefined;
	}, []);

	// const a = { lat: 40.756795, lng: -73.954298 };
	// const b = { lat: 41.756795, lng: -78.954298 };
	//
	// const d = {
	// 	routes: [a, b],
	// 	origin: a,
	// 	destination: b,
	// 	travelMode: 'DRIVING'
	// };

	return (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={center}
			zoom={10}
			onLoad={onLoad}
			onUnmount={onUnmount}
			options={defaultOptions}
		>
			{ /* Child components, such as markers, info windows, etc. */ }
			{/*<LocationMarker position={{
				lat: 51,
				lng: 3
			}} />*/}
			{children}
			{/*<DirectionsRenderer*/}
			{/*	map={null}*/}
			{/*	origin={{ lat: 40.756795, lng: -73.954298 }}*/}
			{/*	destination={{ lat: 40.616329, lng: -74.15686 }}*/}
			{/*/>*/}
			{/*<DirectionsRenderer directions={d} />*/}
		</GoogleMap>
	);
};

export default Map;
