import React, {useState} from 'react';
import './CreateNewPassword.css';

import crossIcon from "../../../assets/close.png";

import {useHistory, useParams} from "react-router-dom";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {createNewPasswordSchema} from "../../../validators";

import Modal from "../../Modal/Modal";

import {passwordActions} from "../../../action";

const CreateNewPassword = () => {
    const history = useHistory();
    const {token} = useParams();

    const [modalActive, setModalActive] = useState(false);

    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const { register, handleSubmit, formState: {errors} } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(createNewPasswordSchema)
    });

    const createNewPassword = (data) => {
        delete data.confirmPassword;
        data.token = token;

        passwordActions.setNewPassword(data)
            .then(({data}) => {

                history.push('/');
            })
            .catch(e => {
            });
    }

    return (
        <>
            <div className={'cnp-wrapper'}>
                <div className={'cnp-inner'}>
                    <div className={'cnp-title'}>Create new password</div>

                    <form noValidate={true} onSubmit={handleSubmit(createNewPassword)}>
                        <input
                            {...register('password')}
                            placeholder={'New Password'}
                            className={'cnp-input'}
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                        />

                        <input
                            {...register('confirmPassword')}
                            placeholder={'Confirm New Password'}
                            className={'cnp-input'}
                            type="password"
                            value={newPassword}
                            onChange={(e) => {
                                setNewPassword(e.target.value);
                            }}
                        />

                        <button
                            style={{backgroundColor: (password && newPassword) ? '#1893D2' : '#8E8E93'}}
                            className={'cnp-button active'}
                            type={'submit'}
                            onClick={() => {
                                if (Object.keys(errors).length) {
                                    setModalActive(true);
                                }
                            }}
                        >Submit</button>
                    </form>

                    <button
                        className={'cnp-button bold'}
                        onClick={() => {
                            history.push('/');
                        }}
                    >
                        Back to log in
                    </button>
                </div>
            </div>

            {modalActive &&
                <Modal active={modalActive} setActive={setModalActive}>
                    <div className={'vendor-errors'}>
                        <img
                            src={crossIcon}
                            alt="cross"
                            onClick={() => {
                                setModalActive(false);
                            }}
                        />

                        <div>
                            <span>Error</span>

                            {errors?.password &&
                                <div>{errors.password.message}</div>}
                            {errors?.confirmPassword &&
                                <div>{errors.confirmPassword.message}</div>}
                        </div>
                    </div>
                </Modal>
            }
        </>
    );
};

export default CreateNewPassword;
