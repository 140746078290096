import React, {useEffect, useState} from 'react';
import './VendorReviews.css';

import goBackArrow from '../../assets/arrows/blue-left-arrow.png'

import {reviewActions} from '../../action';
import {useHistory} from "react-router-dom";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import ReviewCard from "./ReviewCard";
import Loader from "../Loader/Loader";

const VendorReviews = () => {
    const history = useHistory();

    const vendorTokenLocal = localStorage.getItem('vendorToken');
    // const vendorTokenLocal = '384|eeu6jLfXXmZiI0MmU3iA79xmnM911zScjQ43Nx4c';

    const [isLoading, setIsLoading] = useState(false);
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        setIsLoading(true);

        reviewActions.getReviews(vendorTokenLocal)
            .then(response => {
                setReviews(response.data);
                setIsLoading(false);
            });
    }, [vendorTokenLocal]);

    return (
        <div className={'reviews-wrapper'}>
            <HeaderMobile vendor={true} />

            <div className="reviews__inner">
                <div className="reviews__title">
                    <img
                        src={goBackArrow}
                        alt="go back"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    Reviews
                    <div />
                </div>

                {isLoading && <div className={'r-l'}><Loader /></div>}
                {reviews.map(review => <ReviewCard title={review.job.title} rating={review.rating} id={review.id}/>)}
            </div>
        </div>
    );
};

export default VendorReviews;
