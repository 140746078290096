import axios from 'axios';
import {BASE_URL} from "../constants";

const getQRCodeCustomer = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    return await axios.get(`${BASE_URL}/api/qr-code/customer`, config);
}

const getQRCodeVendor = async (token, data) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    data
        ? config.params = data
        : config.params = {}

    // 185|vwU15Y6rVmuTtLTyw75ljQYM3zTY8teEcVr5cDnY

    return await axios.get(`${BASE_URL}/api/qr-code/vendor`, config);

    // {
    //     "ok": true,
    //     "data": {
    //     "user": {
    //         "id": 165,
    //             "tin": "123456",
    //             "name": "QA",
    //             "url": "www.dummy.com",
    //             "main_tel": "+13460221091",
    //             "contact_lname": null,
    //             "contact_fname": null,
    //             "contact_email": "nazar785@yopmail.com",
    //             "contact_mob_tel": "+13460221091",
    //             "contact_work_tel": "+13460221091",
    //             "mrbe_id": 2163,
    //             "invite_customer_mrbe_id": null,
    //             "mrbe_status": 1,
    //             "created_at": "2021-11-16T18:59:19.000000Z",
    //             "updated_at": "2021-11-16T18:59:19.000000Z",
    //             "auth_code": "AocNbdj1bvzJdkX"
    //     },
    //     "balance": [
    //         {
    //             "id": 347,
    //             "customer_id": null,
    //             "vendor_id": 165,
    //             "mrp_balance": 0,
    //             "created_at": "2021-11-16T18:59:19.000000Z",
    //             "updated_at": "2021-11-16T18:59:19.000000Z"
    //         }
    //     ],
    //         "VMA": [],
    //         "categories": [
    //         {
    //             "id": 383,
    //             "user_id": 165,
    //             "category_id": 171
    //         },
    //         {
    //             "id": 384,
    //             "user_id": 165,
    //             "category_id": 175
    //         }
    //     ]
    // }
    // }
}

const qrCodeGetPurchase = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }


    return await axios.post(`${BASE_URL}/api/get-purchase`, data, config);
}

const qrCodeGetPurchaseRewards = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }


    return await axios.post(`${BASE_URL}/api/get-purchase`, data, config);
}

const mrbeApplyJob = async (data) => {

    return await axios.post(`${BASE_URL}/api/mrbe/apply-job`, data);
}

export {
    getQRCodeCustomer,
    getQRCodeVendor,
    qrCodeGetPurchase,
    qrCodeGetPurchaseRewards,
    mrbeApplyJob
}
