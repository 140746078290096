import axios from 'axios';
import {BASE_URL} from "../constants";

const sendRecoveryEmail = async (data) => {
    return await axios.post(`${BASE_URL}/api/auth/recovery/send-email`, data);

    // {
    //     "ok": true,
    //     "data": {
    //     "message": "recovery link successful send"
    // }
    // }
}

const setNewPassword = async (data) => {
    return await axios.post(`${BASE_URL}/api/auth/recovery/set-password`, data);

    // {
    //     "ok": true,
    //     "data": {
    //     "id": 284,
    //         "add_1": null,
    //         "city": "La",
    //         "region": 6,
    //         "pc": null,
    //         "country_id": null,
    //         "last_name": "Lll",
    //         "first_name": "Lll",
    //         "email": "nazar115@yopmail.com",
    //         "phone": "+1 606 763 0137",
    //         "mrbe_id": null,
    //         "mrbe_status": 0,
    //         "created_at": "2021-11-09T10:18:53.000000Z",
    //         "updated_at": "2021-11-10T08:23:41.000000Z",
    //         "customer_id": "4792",
    //         "auth_code": "123",
    //         "referral_code": "123456",
    //         "email_verified": 0,
    //         "phone_verified": 0,
    //         "status": "pending",
    //         "username": null,
    //         "one_signal_device_id": null
    //      }
    // }
}

const passwordIsVerify = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    return await axios.post(`${BASE_URL}/api/password`, data, config);
}

const changePassword = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    return await axios.put(`${BASE_URL}/api/password`, data, config);
}

export {
    sendRecoveryEmail,
    setNewPassword,
    passwordIsVerify,
    changePassword
}
