import React, {useEffect, useState} from 'react';

import goBackArrow from "../../assets/arrows/blue-left-arrow.png";
import StarRatings from "react-star-ratings";
import crossIcon from "../../assets/close.png";

import emailIcon from '../../assets/activity-icons/email-icon.svg';
import globeIcon from '../../assets/activity-icons/globe-icon.svg';
import heartIcon from '../../assets/activity-icons/heart-icon.svg';
import phoneIcon from '../../assets/activity-icons/phone-icon.svg';
import shareIcon from '../../assets/activity-icons/share-icon.svg';
import heartFavoriteIcon from "../../assets/providers-icons/heart-favorite.svg";

import {useHistory, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";

import {jobActions, providerActions, reviewActions} from "../../action";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import Modal from "../Modal/Modal";
import {useSelector} from "react-redux";

const LeaveReview = () => {
    const history = useHistory();
    const { id } = useParams();

    // const customerToken = localStorage.getItem('customerToken');
    const customerToken= useSelector(({customerReducer: {customerToken}}) => customerToken);

    const [activity, setActivity] = useState([]);
    const [rating, setRating] = useState(0);
    const [modalActive, setModalActive] = useState(false);
    const [reviewError, setReviewError] = useState('The review field is required.');

    const [isFavorite, setIsFavorite] = useState(false);
    const [vendorId, setVendorId] = useState('');

    useEffect(() => {
        jobActions.getJobById(id, customerToken)
            .then(({data}) => {
                setActivity(data.data);
                setVendorId(data.data.vendor.id);

                setIsFavorite(data.data.isFavorite);
            });
    }, [customerToken, id]);

    const addOrRemoveProviderFromFavorites = () => {
        providerActions.addProviderToFavorite(customerToken, vendorId)
            .then(response => {
                if (response.ok) setIsFavorite(!isFavorite);
            });
    }

    const { register, handleSubmit } = useForm({
        mode: 'onBlur',
    });

    const changeRating = (userRating, name) => {
        setRating(userRating)
    }

    const sendReview = (data) => {
        if (!data.review) {
            setModalActive(true);
            return;
        }

        data.job_id = id;
        data.rating = rating;

        reviewActions.leaveReview(data, customerToken)
            .then((data) => {
                if (data.ok) {
                    history.push('/reviewed');
                }

                if (!data.ok) {
                    setReviewError(data.message.validate_errors.review[0]);
                    setModalActive(true);
                }
            })
            .catch((e) => {
                setReviewError(e.message);
                setModalActive(true);
            });
    }

    return (
        <div className={'activities-wrapper'}>
            <HeaderMobile />

            <div className={'activities-inner'}>
                <div className={'activities-inner__nav sub'}>
                    <img
                        src={goBackArrow}
                        alt="go back arrow"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    <div className={'activities__title sub'}>Leave a review</div>
                    <div />
                </div>

                <div className={'reviewed__activity'}>
                    <div className={'reviewed__activity-name'}>
                        {activity.job?.title}
                    </div>

                    <div className={'reviewed__activity-icons'}>
                        <div>
                            <img src={phoneIcon} alt=""/>
                            <img src={emailIcon} alt=""/>
                            <img src={globeIcon} alt=""/>

                            <img
                                src={isFavorite ? heartFavoriteIcon : heartIcon}
                                alt="favorite provider"
                                onClick={addOrRemoveProviderFromFavorites}
                            />
                        </div>

                        <div>
                            <img
                                src={shareIcon}
                                alt=""
                                onClick={() => {
                                    history.push(`/customers/${vendorId}`)
                                }}
                            />
                        </div>
                    </div>
                </div>

                <form noValidate={true} onSubmit={handleSubmit(sendReview)}>
                    <textarea
                        {...register('review', {required: false})}
                        id={'review'}
                        placeholder={'Write your review here'}
                        className={'reviewed__activity-textarea'}
                    />

                    <div className={'reviewed__activity-start'}>
                        <StarRatings
                            rating={rating}
                            starRatedColor='rgba(255, 188, 58, 1)'
                            changeRating={changeRating}
                            numberOfStars={5}
                            starSpacing={'4px'}
                            isSelectable={false}
                            name='rating'
                            svgIconViewBox={'0 0 15 14'}
                            svgIconPath={'M6.54894 0.927048C6.8483 0.00573778 8.1517 0.0057404 8.45106 0.927051L9.40837 3.87336C9.54224 4.28538 9.9262 4.56434 10.3594 4.56434H13.4574C14.4261 4.56434 14.8289 5.80395 14.0451 6.37336L11.5389 8.19427C11.1884 8.44892 11.0417 8.90028 11.1756 9.31231L12.1329 12.2586C12.4323 13.1799 11.3778 13.946 10.5941 13.3766L8.08778 11.5557C7.7373 11.3011 7.2627 11.3011 6.91221 11.5557L4.40594 13.3766C3.62222 13.946 2.56774 13.1799 2.8671 12.2586L3.82441 9.3123C3.95828 8.90028 3.81162 8.44892 3.46114 8.19427L0.954859 6.37335C0.171145 5.80395 0.573923 4.56434 1.54265 4.56434H4.64057C5.0738 4.56434 5.45776 4.28538 5.59163 3.87336L6.54894 0.927048Z'}
                        />
                    </div>

                    <button className={'reviewed__activity-button active'}>Send review</button>
                </form>
            </div>

            <Modal active={modalActive} setActive={setModalActive}>
                <div className={'vendor-errors'}>
                    <img
                        src={crossIcon}
                        alt="cross"
                        onClick={() => {
                            setModalActive(false);
                        }}
                    />

                    <div>
                        <span>Error</span>

                        <div>{reviewError}</div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default LeaveReview;
