import React, {useEffect} from 'react';
import './Account.css';

import arrowIcon from '../../assets/arrows/right-arrow-small.svg';
import infoIcon from "../../assets/info.png";

import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

import {
    setAccountEdited,
    setCurrentCustomer,
    setCustomerToken,
    setPasswordChanged
} from "../../redux/action-creators";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import goBackArrow from '../../assets/arrows/blue-left-arrow.png';

const Account = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const passwordChanged = useSelector(({messageReducer: {passwordChanged}}) => passwordChanged);
    const accountEdited = useSelector(({messageReducer: {accountEdited}}) => accountEdited);

    const logOut = () => {
        dispatch(setCustomerToken(null));
        dispatch(setCurrentCustomer({}));

        localStorage.removeItem('customerToken');
        localStorage.removeItem('currentCustomer');
        localStorage.removeItem('currentCustomerBalance');

        history.push('/');
    }

    useEffect(() => {
        if (passwordChanged) {
            setTimeout(() => {

                dispatch(setPasswordChanged(false));
            }, 2000);

            return;
        }

        if (accountEdited) {
            setTimeout(() => {
                dispatch(setAccountEdited(false));
            }, 2000);
        }
    }, [passwordChanged, accountEdited, dispatch]);

    return (
        <div className={'account__wrapper'}>
            <HeaderMobile />

            <div className={'account__inner'}>
                <div
                    className={
                        passwordChanged || accountEdited
                            ? 'sales-inner__message active'
                            : 'sales-inner__message'
                    }
                >
                    <img src={infoIcon} alt="info about transaction"/>

                    <div className={'sales-inner__message-text sub'}>
                        {passwordChanged && <div>Password was changed successfully</div>}
                        {accountEdited && <div>Account was edited successfully</div>}
                    </div>
                </div>
                <div className="inner-padding">
                    <div className={'inner-title'}>
                        <img
                            className='account__go_back'
                            src={goBackArrow}
                            alt="go back arrow"
                            onClick={() => {
                                history.goBack();
                            }}
                            />
                        <div className={'account__title'}>Account</div>
                        <div></div>
                    </div>
                </div>

                <div className={'account__link'} onClick={() => history.push('/add-funds')}>
                    Add funds
                    <img src={arrowIcon} alt="arrow"/>
                </div>

                <div className={'account__link'} onClick={() => history.push('/account-edit')}>
                    Edit account
                    <img src={arrowIcon} alt="arrow"/>
                </div>

                <div className={'account__link'} onClick={() => history.push('/change-password')}>
                    Change password
                    <img src={arrowIcon} alt="arrow"/>
                </div>

                <div className={'account__link'} onClick={() => history.push('/return-activities')}>
                    Returns
                    <img src={arrowIcon} alt="arrow"/>
                </div>

                <div
                    className={'account__link logout'}
                    onClick={logOut}
                >Logout</div>

            </div>
        </div>
    );
};

export default Account;
