import React from 'react';
import './AuthIntro.css';

import { Link } from 'react-router-dom';

const AuthIntro = () => {
    return (
        <div className={'intro'}>
            <div className="intro__inner">
                <div className={'intro__title'}>Unlock profits everywhere</div>
                <div className="intro__title-underline" />
                <div className={'intro__subtitle'}>Take part in different services benefits programs now!</div>

                <Link to={'/auth'} className={'intro__button active'}>Get Started</Link>
            </div>
        </div>
    );
};

export default AuthIntro;
