import React, {useState} from 'react';

const CodeSelector = ({code, setCode}) => {
    const [isActive, setIsActive] = useState();

    const mobilesCodes = ['+1', '+353'];

    const codeSelectorActive = () => {
        if (isActive) {
            return (
                <div className={'selector-content code'}>
                    {mobilesCodes.map(code => {
                        return (
                            <div
                                className={'selector-content__item code'}
                                key={code}
                                onClick={() => {
                                    setCode(code);
                                    setIsActive(false);
                                }}
                            >
                                {code}
                            </div>
                        );
                    })}
                </div>
            );
        }
    }

    return (
        <div className={'selector-wrapper code'}>
            <div
                className={'selector-button code'}
                onClick={() => {
                    setIsActive(!isActive);
                }}
                style={{
                    color: code ? '#4B4846' : '#8E8E93'
                }}
            >
                {code ? code : '+1'}
            </div>

            {codeSelectorActive()}
        </div>
    );
};

export default CodeSelector;
