import axios from 'axios';
import {BASE_URL} from "../constants";

// 320|S0NpMCMndIHqGSF5fDC9bLjRrBjpvr0jY9B9ybC0

const setUuid = async (token, data) => {
	const config = {
		headers: {
			Authorization: `Bearer ${token}`
		}
	}

	await axios.post(`${BASE_URL}/api/user/uuid`, data, config);
}

export {
	setUuid
}
