import React, { useEffect, useState } from "react";

import backArrow from "../../assets/arrows/blue-left-arrow.png";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { providerActions } from "../../action";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import Provider from "./Provider";

const FavoriteProviders = () => {
  const history = useHistory();

  // const customerTokenLocal = localStorage.getItem('customerToken');
  const customerTokenLocal = useSelector(
    ({ customerReducer: { customerToken } }) => customerToken
  );

  // const [isLoading, setIsLoading] = useState(false);
  const [favoriteProviders, setFavoriteProviders] = useState([]);

  const [removed, setRemoved] = useState(false);

  useEffect(() => {
    // setIsLoading(true);

    providerActions
      .getFavoriteProviders(customerTokenLocal)
      .then((response) => {
        setFavoriteProviders(response.data);
        // setIsLoading(false);
      });
  }, [customerTokenLocal, removed]);

  return (
    <div className={"providers__wrapper"}>
      <HeaderMobile />

      <div className={"providers__inner"}>
        <div className={"providers__title-wrapper"}>
          <img
            src={backArrow}
            alt="go back arrow"
            onClick={() => {
              history.goBack();
            }}
          />
          <div className={"providers__title sub"}>Favorite providers</div>
          <div />
        </div>

        {favoriteProviders.map((provider) => {
          return (
            <Provider
              key={provider.id}
              id={provider.id}
              rating={provider.ratingInStars ? provider.ratingInStars : 0}
              categories={[]}
              name={provider.name}
              reviews={provider.avgReviewsCount}
              address={provider?.location?.add_1}
              isFavorite={true}
              removed={removed}
              setRemoved={setRemoved}
              hideAddress={false}
              providerSub={true}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FavoriteProviders;
