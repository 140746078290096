const defaultCenter = {
	lat: 51,
	lng: 3
}

export const getBrowserLocation = () => {
	return new Promise((resolve, reject) => {
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const {latitude: lat, longitude: lng} = position.coords;
					resolve({lat, lng});
				},
				() => {
					reject(defaultCenter);
				}
			);
		} else {
			reject(defaultCenter);
		}
	});
}
