import React from 'react';
import './TermsMobile.css';

import openTermsIcon from '../../../assets/terms-of-service/open-terms.svg';
import downloadTermsIcon from '../../../assets/terms-of-service/download-terms.svg';

import termsPDF from '../../../assets/terms-of-service/CustomerTerms.pdf';

import backArrow from "../../../assets/arrows/blue-left-arrow.png";
import {useHistory} from "react-router-dom";

const CustomerTerms = ({setTerms, web}) => {
    const history = useHistory();

    const termsTitle = () => {
        if (web) return <div className={'terms-title'}>
            <img
                src={backArrow}
                alt="back arrow"
                onClick={() => {
                    history.goBack();
                   /* setTerms(false);*/
                }}
            />
            <div className={'web-terms-title'}>
                Terms of Service

                <div>
                    <a href={termsPDF} target="_blank">
                        <img src={openTermsIcon} alt="open terms"/>
                    </a>
                    <a href={termsPDF} download>
                        <img src={downloadTermsIcon} alt="download terms"/>
                    </a>
                </div>
            </div>
        </div>

        return <div className={'terms-title'}>
            <img
                src={backArrow}
                alt="back arrow"
                onClick={() => {
                    setTerms(false);
                }}
            />
            <div className={'web-terms-title'}>Terms of Service</div>
            <div />
        </div>
    }

    return (
        <div className={'terms-wrapper'}>
            <div className={'terms-inner'}>
                {termsTitle()}

                <div className={'terms-text-wrapper'}>
                    <h3 className={'terms-header'}>
                        Morewards US Consumer Agreement Terms and Conditions
                        <span className={'terms-red-text bold'}> Draft</span>
                    </h3>

                    <div className={'terms-small-text'}>Last Updated: October 21, 2020</div>

                    <div className={'terms-text'}>
                        These Terms and Conditions (“
                        <span className={'terms-bold-text'}>Terms</span>
                        ”) form a legal agreement between you, the consumer and Morewards, Inc., a Connecticut
                        corporation (“
                        <span className={'terms-bold-text'}>Morewards</span>”, “
                        <span className={'terms-bold-text'}>we</span>”, “
                        <span className={'terms-bold-text'}>us</span>”, “
                        <span className={'terms-bold-text'}>Morewards</span>
                        ”) and govern your access to and use of: (1) the software, hardware, devices, applications,
                        websites and any other web-based or mobile technology that Morewards owns or operates and that
                        links to or references these Terms (the “
                        <span className={'terms-bold-text'}>Tools</span>
                        ”); (2) the services that Morewards itself furnishes through the Tools (the “
                        <span className={'terms-bold-text'}>Services</span>
                        ”); and (3) your Morewards consumer account (“
                        <span className={'terms-bold-text'}>Consumer Account</span>”, “
                        <span className={'terms-bold-text'}>Account</span>”)
                        and Morewards reward card (“Reward Card’). The Tools and Services function together as the
                        Morewards network (the “
                        <span className={'terms-bold-text'}>Network</span>”).
                    </div>

                    <h3 className={'terms-header'}>Please read these Terms carefully before using the Network.</h3>
                    <h3 className={'terms-header'}>1. Acceptance of Terms</h3>

                    <div className={'terms-text'}>
                        By installing, downloading, registering with, or using the Tools, or by using the Services,
                        in any way, you agree to be bound by each and every one of these Terms. If you do not agree
                        with these Terms, please do not install, download, register with, or use the Network.
                    </div>

                    <h3 className={'terms-header'}>2. Your Morewards User Account</h3>

                    <div className={'terms-text'}>
                        We offer two types of accounts: personal accounts (“
                        <span className={'terms-bold-text'}>Consumer Account</span>”, “
                        <span className={'terms-bold-text'}>Account</span>
                        ”) and approved business accounts. You may only have one Consumer Account. Your Morewards
                        account is a Consumer Account unless you have received our express written approval to open
                        a business account. To access the password-required portions of the Network, you must first
                        register on the Application by creating an Account. To create an Account, you must be of legal
                        age to form a binding contract. If you are not of legal age to form a binding contract, or if
                        your access to the Network was previously terminated by us, you may not register to use the
                        Network. Your Morewards Consumer Account is free. No fees apply to your Account or your Reward
                        Card. You can download the “
                        <span className={'terms-bold-text'}>Application</span>
                        ” online on Morewards.com or on the “
                        <span className={'terms-bold-text'}>MOR App</span>
                        ” at the application store for your mobile device.
                    </div>

                    <div className={'terms-text'}>
                        When registering to create a Consumer Account, you must furnish your name, e-mail address,
                        password and certain other information collected by Morewards (collectively, the “
                        <span className={'terms-bold-text'}>Account Information</span>
                        ”). You agree that the Account Information that you provide to us is, at all times true,
                        accurate, current, and complete. You may change, correct, add or remove any information from
                        your Account through the Tools and Services.
                    </div>

                    <div className={'terms-text'}>
                        You may not transfer or share your Account password with anyone or create or maintain more
                        than one Account. You are responsible for maintaining the confidentiality of your Account
                        password and for all activities that occur under your Account. Morewards reserves the right
                        to take any and all action, as it deems necessary or reasonable, regarding the security of the
                        Tools, the Network and your Account Information. In no event and under no circumstances shall
                        Morewards be held liable to you for any liabilities or damages resulting from or arising out
                        of your use of the Network, your use of the Account Information or your release of the Account
                        Information to a third party. You may not use anyone else's account at any time. Morewards
                        reserves the right to require additional verification of your identity, Reward Card or Account
                        ownership before you are able to apply a Reward Card to an Item purchase. If you have reason to
                        believe that your Account is no longer secure, change your username and password or immediately
                        close your Account. You must also promptly notify us at our Contact Us page on morewards.com of
                        your concerns about the security of your Account, of the basis of your concerns, and of any
                        steps you have taken and/or plan to take to deal with your concerns.
                    </div>

                    <h3 className={'terms-header'}>
                        3. Your Morewards Reward Card, Account Balance, Rewards Redemption.
                    </h3>

                    <div className={'terms-text'}>
                        Your “Reward Card” is issued by Morewards and is issued as “
                        <span className={'terms-bold-text'}>Rewards</span>”; “
                        <span className={'terms-bold-text'}>MOR Rewards</span>
                        ”; Reward Dollars; or “
                        <span className={'terms-bold-text'}>MOR Rewards</span>
                        .” Rewards are automatically added to your account balance (“
                        <span className={'terms-bold-text'}>Rewards Balance</span>
                        ”) when you purchase eligible products, goods, and services (“
                        <span className={'terms-bold-text'}>Items</span>
                        ”) at a Morewards authorized merchant (“
                        <span className={'terms-bold-text'}>Authorized Merchant</span>”, “
                        <span className={'terms-bold-text'}>Merchant</span>
                        ”). An Authorized Merchant means a merchant, store, service provider, retailer, or vendor that
                        has been approved by us to accept our Rewards Network for Consumer purchases of eligible items.
                    </div>

                    <div className={'terms-text'}>
                        Your Rewards Balance displays the total remaining balance of all Rewards that have been applied
                        to your account but not yet applied to a purchase. We may also refer to your Rewards Balance
                        as your “
                        <span className={'terms-bold-text'}>Morewards Balance</span>”, “
                        <span className={'terms-bold-text'}>MOR Dollar Balance</span>”, “
                        <span className={'terms-bold-text'}>MOR Balance</span>”, or “
                        <span className={'terms-bold-text'}>Balance</span>
                        ”. To view your Rewards Balance, visit Your Account on morewards.com or on the MOR App.
                        You can shop at a participating Merchant using any payment method allowed by the Merchant.
                        Cash, credit, and debit purchases will generate Rewards added to your Rewards Balance.
                        You can also purchase Items with our Merchants directly using your Rewards Dollars. When making
                        purchases through our Network, you must choose to pay the Merchant for Items either with cash
                        (or cash equivalent) OR with Rewards but NOT with a combination of the two. Regardless of the
                        method of payment, the Merchant will input the Total Price of your Item(s) into our Network
                        which will generate your Consumer Reward.
                    </div>

                    <div className={'terms-text'}>
                        All Rewards shall be valued in United States Dollar (USD) currency. Purchases using Rewards are
                        deducted from your Rewards Balance. Any unused MOR Dollars will remain associated with your
                        Account. If a purchase exceeds your Balance, you have the option to either Add Rewards Funds to your
                        Reward Balance (see Section 4 below) or pay the entire purchase with cash or cash equivalent.
                    </div>

                    <div className={'terms-text bold'}>
                        When you purchase Items at participating Morewards Merchants, you will earn Rewards for every
                        eligible item you purchase, every time you shop, regardless of the method of payment you
                        choose. Rewards can be used to purchase Items at any participating Merchant regardless of
                        where you earned the Rewards. Your Rewards Balance does not expire and may be applied to all
                        eligible Item purchases.
                    </div>

                    <h3 className={'terms-header'}>
                        4. Add Reward Funds Feature
                    </h3>

                    <div className={'terms-text'}>
                        Additional Reward Dollars can also be loaded to your Rewards Balance through our add funds (“
                        <span className={'terms-bold-text'}>Add Reward Funds</span>”, “
                        <span className={'terms-bold-text'}>Add Funds</span>
                        ”) feature available on the Network. If you choose to Add Reward Funds, you agree to pay the
                        amount due in advance of receiving any additional Reward Dollars. By providing us with your
                        payment information, you agree that Morewards is authorized to immediately invoice and charge
                        you for all amounts due and payable and that no additional notice or consent is required. You
                        authorize Morewards to charge your Account by Automatic Clearing House withdrawal (ACH debit
                        transfer) from your bank checking account. Morewards will add MOR Reward Dollars to your Rewards
                        Balance when you purchase Reward Dollars through our Add Funds feature. You will receive one (1)
                        MOR Reward Dollar for every one ($1) US dollar you add. We may, in our discretion, receive
                        updated banking information including your Bank account numbers, routing numbers, and account
                        type, from you or your financial institution.
                    </div>

                    <h3 className={'terms-header'}>
                        5. About Us
                    </h3>

                    <div className={'terms-text'}>
                        We are a technology company that owns and/or operates the Morewards Network to allow Merchants
                        to make their Items available to Consumers who seek such Items. Our mission is to empower
                        you (the “
                        <span className={'terms-bold-text'}>Consumer</span>
                        ”) by removing barriers to access using technology solutions to help Consumers and
                        Merchants connect and by making marketing efforts more direct and affordable.
                    </div>

                    <div className={'terms-text'}>
                        Morewards itself does not produce, sell, or process the sale of any Items. Our business concern
                        and mission are to provide a convenient technology for Merchants to connect with you and reward
                        you for selecting their offerings and making referrals as far as is practical and lawful. As
                        such, Morewards exclusively controls the generation and distribution of Consumer Rewards.
                    </div>

                    <h3 className={'terms-header'}>
                        6. About the Merchants
                    </h3>

                    <div className={'terms-text'}>
                        Our Merchants are stores, service providers, retailers, vendors and other merchants who desire
                        to use our Network to make available and furnish certain Items to Consumers. Merchants have
                        entered into contracts with Morewards for the use of the Network. Merchants who market Items
                        using the Network are businesses independent of Morewards. Merchants are neither the employees,
                        servants, agents, partners nor affiliates of Morewards. The Items furnished by Merchants are not
                        performed on behalf of Morewards. Each Merchant shall be individually responsible for collecting
                        payment and all applicable sales taxes for Items and for using our Network to process Rewards
                        to Consumers.
                    </div>

                    <h3 className={'terms-header'}>
                        7. Right to Modify Terms
                    </h3>

                    <div className={'terms-text'}>
                        We may at our sole discretion change, add, or delete portions of these Terms (each a “
                        <span className={'terms-bold-text'}>Terms Update</span>
                        ”) at any time, without prior notice to you, for any reason whatsoever. Your continued use
                        of the Network following notice of any such changes will indicate your acknowledgement of such
                        changes and agreement to be bound by the revised Terms, inclusive of such changes. If you
                        object to any changes to these Terms, your sole recourse will be to cease using the Network.
                        We will not be liable to you or any third-party should we exercise our right to modify or
                        discontinue the Services. In addition, certain features of the Network may be subject to
                        additional terms of use. By using such features, or any part thereof, you agree to be bound
                        by the additional terms of use applicable to such features. In the event that any of the
                        additional terms of use governing such areas conflict with these Terms, the additional terms
                        will govern. You agree to review any Terms Update available on the Network each time you
                        access the Network so that you may be aware of any changes to these Terms.
                    </div>

                    <h3 className={'terms-header'}>
                        8. Access Rights and Expected Conduct
                    </h3>

                    <div className={'terms-text'}>
                        We hereby grant to you a limited, non-exclusive, nontransferable right to access the Network
                        and use the Services solely for your personal, non-commercial use and only as permitted under
                        these Terms (“
                        <span className={'terms-bold-text'}>Access Rights</span>
                        ”). We reserve the right, without notice to you, to void Rewards (including as a
                        component of your Rewards Balance), suspend or terminate your Account, suspend or terminate your
                        ability to use the Network, cancel or limit Reward transactions, and bill alternative forms of
                        payment if we suspect that Reward Dollars are added, obtained, used, or applied to a Rewards
                        Account (or your Rewards Balance is applied to a purchase) fraudulently, unlawfully, or
                        otherwise in violation of these Terms. You are expected to conduct yourself when accessing the
                        Tools and using the Network as follows:
                    </div>

                    <div className={'terms-text'}>
                        You agree that you will not, and will not attempt to: reverse engineer, disassemble, decompile,
                        or translate any software or other components of the Tools, Services, or Network; distribute
                        viruses or other harmful computer code through the Network; or otherwise use the Network in
                        any manner that exceeds the scope of use granted above.
                    </div>

                    <div className={'terms-text'}>
                        By using your Reward Card, you agree to comply with these Terms. You agree that you will use
                        the Network for lawful purposes only. Your failure to do so may subject you to civil or
                        criminal liability or both, and to termination of your privilege to utilize the Tools and
                        Services. You agree that you will not engage in any activity that, in our sole discretion,
                        disrupts, or is harmful to our reputation, goodwill, business, operations or the participating
                        Merchants. When posting ratings or reviews for Merchant products, goods, and services (“
                        <span className={'terms-bold-text'}>Reviews</span>
                        ”), you agree that you will refrain from using abusive language, and posting comments that are
                        inappropriate, unlawful, or illegal. Finally, you agree to take caution and refrain from using
                        the Network on public computers and not to store or share your Account password with anyone.
                    </div>

                    <h3 className={'terms-header'}>
                        9. Rewards Limitations, Restrictions, and Prohibited Activities
                    </h3>

                    <div className={'terms-text'}>
                        There are certain restrictions in connection with the use of your Reward Card. If you engage in these
                        activities, Morewards may prevent you from claiming or redeeming Rewards, or may take further action
                        on your Account.
                    </div>

                    <ul className={'terms-list'}>
                        <li>
                            Rewards may only be redeemed toward the purchase of eligible Items provided by authorized
                            Morewards Merchants. Eligible items and authorized Merchants are subject to change.
                        </li>

                        <li>
                            Your Morewards Balance cannot be used to purchase any other reward cards or any third-party
                            rewards of any kind.
                        </li>

                        <li>
                            Rewards cannot be reloaded; resold; exchanged; used for payment outside of authorized
                            Morewards Merchants; used for unauthorized advertising, marketing, sweepstakes, promotional
                            or commercial purposes, including to facilitate the resale or shipment of Items from Merchants.
                        </li>

                        <li>
                            You cannot use your Reward Card for any illegal or unauthorized purpose.
                        </li>

                        <li>
                            Rewards cannot be used to purchase certain ineligible Items such as collectible coins, alcohol,
                            tobacco, firearms, money orders, money transfers, lottery items, and items containing
                            endangered species.
                        </li>

                        <li>
                            You'll need to provide to Merchant, a valid method of payment for a purchase that includes any
                            ineligible items.
                        </li>

                        <li>
                            Rewards cannot be used to pay tips or gratuities of any kind.
                        </li>

                        <li>
                            Rewards must be redeemed in the currency designated in the account, and provided that the
                            Merchant accepts that designated currency.
                        </li>

                        <li>
                            Rewards cannot be redeemed for more than face value; transferred for value; redeemed for cash;
                            returned for a cash refund (except to the extent required by law).
                        </li>

                        <li>
                            No portion of your Morewards Balance may be transferred to another account.
                        </li>

                        <li>
                            Neither your Rewards Balance nor your Rewards Dollars may be applied to the purchase of Items
                            at any property outside of the United States.
                        </li>

                        <li>
                            You cannot add funds to your Reward Card through an unauthorized third party as we cannot
                            guarantee that they are legitimate.
                        </li>

                        <li>
                            A non-authorized merchant or individual asking for Rewards as a payment method for Items may
                            be a scam. See our common reward and gift card scams link here page for more details.
                        </li>

                        <li>
                            The Items eligible for purchase with your Rewards Card are subject to change in our sole
                            discretion.
                        </li>

                        <li>
                            You cannot redeem a Reward to an account if you are not the intended recipient. An example
                            of when you are the intended recipient is if you purchase an Item at one of our authorized
                            Merchants. You are not the intended recipient if you fraudulently deceived a Merchant into
                            generating a Reward even if they willingly do so at the time of transfer.
                        </li>
                    </ul>

                    <div className={'terms-text'}>
                        The list of prohibited activities above is not intended to be a complete or exhaustive list of all prohibited
                        activities. Engaging in any prohibited activities, or using your Morewards Reward Card in violation of
                        these Terms may result in our taking action(s) against you, including, but not limited to, preventing you
                        from claiming or redeeming Rewards, revoking your Reward Balance, or closing your Account consistent
                        with these Terms.
                    </div>

                    <h3 className={'terms-header'}>
                        <span className={'terms-red-text bold'}>10. Item Returns </span>
                        <span className={'terms-red-text'}>and Refunds</span>
                    </h3>

                    <div className={'terms-text'}>
                        <span className={'terms-red-text'}>
                            The Merchant is responsible for processing Item returns. When you purchase an Item from
                            an AuthorizedMerchant and the Item is returned or the transaction is refunded, the
                            corresponding Reward will bededucted from your Reward Balance.
                        </span>
                    </div>

                    <div className={'terms-text'}>
                        <span className={'terms-red-text'}>
                            Rewards may be invalidated and reversed by us if, among other reasons, we sent the Reward
                            to you inerror; the funding transaction is declined or reversed; the payment to the
                            Authorized Merchant wasunauthorized or unfunded; we determine that an item purchase is
                            not eligible for the Rewards or if thepayment was for activities that violated these Terms.
                        </span>
                    </div>

                    <div className={'terms-text'}>
                        <span className={'terms-red-text'}>
                            As the sender of a payment that is later invalidated for any reason, you may be liable to
                            us for the fullamount of the Reward and we may recover the amount of the Reward from you.
                            For example, if youmake an Item payment funded by a bank account and the bank or Merchant
                            informs us it cannot coverthe payment due to a lack of funds or a dispute, we may reverse
                            that Reward from your Account to cover the liability.
                        </span>
                    </div>

                    <h3 className={'terms-header'}>
                        <span className={'terms-red-text bold'}>11. Processing Errors</span>
                    </h3>

                    <div className={'terms-text'}>
                        <span className={'terms-red-text'}>
                            We will rectify any Rewards processing error that we discover. If the error results in: (i)
                            you receiving lessthan the correct Reward amount to which you were entitled, then we will
                            credit your Reward Balance forthe difference between what you should have received and what
                            you actually received; (ii) you receivingmore than the correct Reward amount to which you
                            were entitled, then we will debit your Reward Balancefor the difference between what you
                            actually received and what you should have received; (iii) our notcompleting a Reward
                            transaction on time or in the correct amount, then we will be responsible to you for your
                            losses or damages directly caused by this failure, unless: (a) through no fault of ours,
                            you did nothave enough available funds to complete the transaction; (b) our system was not
                            working properly andyou knew about the breakdown when you started the transaction; or (c)
                            the error was due to extraordinarycircumstances outside our control (such as fire, flood or
                            loss of Internet connection), despite ourreasonable precautions.
                        </span>
                    </div>

                    <div className={'terms-text'}>
                        <span className={'terms-red-text'}>
                            Processing errors are not: (i) delays that result from us applying Merchant holds or
                            limitations; (ii) delaysbased on an Item payment review or dispute; (iii) delays related
                            to the time it may take for a purchasetransaction to be completed in some situations;
                            (iv) delays related to Item returns or refunds.
                        </span>
                    </div>


                    <h3 className={'terms-header'}>
                        12. Risk of Loss
                    </h3>

                    <div className={'terms-text'}>
                        The risk of loss and title for Rewards pass to you, upon our electronic transmission of the
                        Reward to you, the designated recipient. Rewards must be obtained from Morewards, and you are
                        responsible for safeguarding your Reward Card from unauthorized use. We are not responsible if
                        any Reward Card is lost, stolen, or destroyed, or if your Reward Card is used without your
                        permission. There are a variety of reward and gift card scams that request payment by such
                        cards. Morewards is not responsible for and assumes no liability to you for any unlawful
                        conduct or fraud by any third party associated with any reward or gift card.
                    </div>

                    <h3 className={'terms-header'}>
                        13. Privacy
                    </h3>

                    <div className={'terms-text'}>
                        Consumer and Merchant privacy are vital to the success of Morewards. Unless required by law,
                        your data will not be shared or sold to third parties. Morewards complies with applicable
                        federal privacy and security laws and maintains safeguards to protect the security of your
                        personal data. Additionally, the information you make available to us is confidential, to the
                        extent protected by applicable laws. We devote considerable effort toward ensuring that your
                        Account Information and Add Funds purchasing information communicated through the Tools and
                        Services are secure. Details regarding our use of your information are provided in our Privacy
                        Policy,
                        <span className={'terms-red-text'}> add link </span>
                        and your acceptance of these Terms constitutes an acceptance of our Privacy Policy.
                    </div>

                    <h3 className={'terms-header'}>
                        14. Communications
                    </h3>

                    <div className={'terms-text'}>
                        As part of providing you with Rewards and access to and use of the Network, and connecting you
                        with Merchants and other Morewards Consumers, we may provide you with certain communications,
                        such as notifications, notices, administrative messages, and other communications about the
                        Services (“
                        <span className={'terms-bold-text'}>Communications</span>
                        ”). These Communications are considered part of the Network and your Account. You agree and
                        consent that any Communications that we send you electronically will satisfy any legal
                        communication requirements, including that such communications be in writing.
                    </div>

                    <div className={'terms-text'}>
                        You understand that by using the Network you are agreeing to these Terms and that such action
                        constitutes a legal signature. You agree that we may send to you Communications through
                        electronic means including but not limited to: (1) by email, using the address that you
                        provided to us during registration, (2) push notifications on your tablet or mobile device,
                        or (3) by SMS messages using the number that you provided to us during registration, or (4)
                        by posting Communications on the Tools. The delivery of any Communications from us is effective
                        when sent by us, regardless of whether you read the Communication. You can withdraw your
                        consent to receive Communications by deactivating your Account. Morewards cannot ensure the
                        security or confidentiality of messages sent by email. Information relating to your Account
                        are stored on secure, encrypted servers maintained by Morewards.
                    </div>

                    <div className={'terms-text'}>
                        Message and data rates may apply for any Communications sent to you from us and for any
                        communications to us from you. You will receive varied frequency of Communications. If you
                        have any questions about your text plan or data plan, it is best to contact your wireless or
                        data provider. We are not responsible for the internet or data bandwidth and signal strength
                        of your mobile device.
                    </div>

                    <h3 className={'terms-header'}>
                        15. Disclaimer of Warranties
                    </h3>

                    <div className={'terms-text'}>
                        You expressly agree that use of the Network is at your sole risk. The Network is provided on
                        an "as is" and "as available" basis. Moreover, Morewards does not warrant that access to or use
                        of the Network will be uninterrupted or that defects in the Network will be corrected. To the
                        full extent permissible by law, we make no warranties, express or implied, with respect to
                        Rewards or your Reward Balance, including without limitation, any express or implied warranty
                        of merchantability or fitness for a particular use or purpose, non-infringement, title,
                        operability, condition, quiet enjoyment, value, accuracy of data and system integration.
                        To the full extent permissible by law, in the event the Tools, Services, or Reward Card are
                        non-functional, your sole remedy, and our sole liability, will be the replacement of that
                        Reward Card.
                    </div>

                    <h3 className={'terms-header'}>
                        16. Limitation of Liability
                    </h3>

                    <div className={'terms-text'}>
                        You understand that to the extent permitted under applicable law, in no event will Morewards
                        or our officers, employees, directors, parents, subsidiaries, affiliates, agents or licensors
                        be liable for any indirect, incidental, special, consequential, punitive, or exemplary damages,
                        including but not limited to, damages for loss of goodwill, use, data or other intangible
                        losses arising out of or related to your use of the Network, regardless of whether such damages
                        are based on contract, tort (including negligence and strict liability), warranty, statute or
                        otherwise. We do not provide any warranties against viruses, spyware or malware that may be
                        installed on your device.  To the extent that we may not, as a matter of applicable law, limit
                        its liabilities, the extent of our liability will be the minimum permitted under such
                        applicable law.
                    </div>

                    <h3 className={'terms-header'}>
                        17. Disputes and Dispute Resolution
                    </h3>

                    <div className={'terms-text'}>
                        You may share your concerns through
                        <div>
                            <a className={'terms-link'} href={'mailto:support@morewards.com'}>support@morewards.com</a>
                        </div>
                    </div>

                    <div className={'terms-text'}>
                        Any dispute or claim relating in any way to your Consumer Account will be resolved by binding
                        arbitration, rather than in court, except that you may assert claims in small claims court if
                        your claims qualify. The U.S. Federal Arbitration Act (“
                        <span className={'terms-bold-text'}>FAA</span>
                        ”) and U.S. federal arbitration law apply to this agreement. There is no judge or jury in
                        arbitration, and court review of an arbitration award is limited. To begin an arbitration
                        proceeding, you must send a letter requesting arbitration and describing your claim to our
                        registered agent: ____________.
                    </div>

                    <div className={'terms-text'}>
                        To the extent permitted by applicable law, the parties agree to keep all materials related to
                        the dispute, including the existence of the dispute itself, content of the arbitration, and
                        all the submissions by the parties in the arbitration and awards rendered by the arbitral
                        tribunal, confidential. Each party hereby waives to the fullest extent permitted by applicable
                        law, any right it may have to a trial by jury of any arbitrable claim under these Terms and
                        in connection with the enforcement of an arbitral award rendered pursuant to this agreement.
                    </div>

                    <div className={'terms-text'}>
                        Each party shall pay its own proportionate share of Arbitrator fees and expenses.
                        Notwithstanding any choice of law or other provision in these Terms, the parties agree and
                        acknowledge that these Terms evidence a transaction involving interstate commerce and that the
                        Federal Arbitration Act, 9 U.S.C. § 1 et seq. will govern its interpretation, enforcement and
                        proceedings. It is the intent of the parties that the FAA shall preempt all state laws to the
                        fullest extent permitted by law. If the FAA Rules are found to not apply to any issue that
                        arises under these Terms or the enforcement thereof, then that issue shall be resolved under
                        the laws of the state of Connecticut.
                    </div>

                    <div className={'terms-text'}>
                        Neither party may bring any class, collective, or representative action against the other party,
                        and will preclude a party from participating in or recovering relief under any current or future
                        class, collective, consolidated, or representative action brought against the other party by
                        someone else. The parties each retain the right to seek injunctive or other equitable relief in
                        a court of competent jurisdiction to prevent the actual or threatened infringement,
                        misappropriation or violation of a party’s copyrights, trademarks, trade secrets, patents or
                        other intellectual property rights.
                    </div>

                    <h3 className={'terms-header'}>
                        18. Termination
                    </h3>

                    <div className={'terms-text'}>
                        You may deactivate your Account and end your registration with the Network at any time, for any
                        reason, by using the Tools and Services. Morewards may suspend or terminate your use of the
                        Network, your Account and/or registration for any reason at any time. Subject to applicable law,
                        Morewards reserves the right to maintain, delete or destroy all communications and materials
                        posted or uploaded to the Tools or through the Services pursuant to its internal record
                        retention and/or content destruction policies. After such termination, Morewards will have no
                        further obligation to make available the Tools or Services, except to the extent required
                        by law.
                    </div>

                    <h3 className={'terms-header'}>
                        19. Indemnification
                    </h3>

                    <div className={'terms-text'}>
                        You agree to indemnify, defend and hold harmless Morewards, its officers, directors, employees,
                        agents, subsidiaries, affiliates, licensors, and suppliers, harmless from and against any claim,
                        actions, demands, liabilities and settlements, including without limitation reasonable legal
                        and accounting fees (“Claims”), resulting from, or alleged to result from, your violation of
                        these Terms.
                    </div>

                    <h3 className={'terms-header'}>
                        20. Force Majeure
                    </h3>

                    <div className={'terms-text'}>
                        We will not be deemed to be in breach of these Terms or liable for any breach of these Terms
                        or our Privacy Policy due to any event or occurrence beyond our reasonable control, including
                        without limitation, acts of God, terrorism, war, invasion, failures of any public networks,
                        electrical shortages, earthquakes or floods, civil disorder, strikes, fire or other disaster.
                    </div>

                    <h3 className={'terms-header'}>
                        21. Linked Locations
                    </h3>

                    <div className={'terms-text'}>
                        We will not be liable for any information, software, or links found at any other website,
                        internet location, or source of information, nor for your use of such information, software or
                        links, nor for the acts or omissions of any such websites or their respective operators.
                    </div>

                    <h3 className={'terms-header'}>
                        22. Ownership
                    </h3>

                    <div className={'terms-text'}>
                        The Tools and Services, and their entire contents, features and functionality (including but
                        not limited to all information, software, text, displays, images, and the design, selection
                        and arrangement thereof), are owned by Morewards, its licensors or other providers of such
                        material and are protected by United States and international copyright, patent, trademark,
                        trade dress, trade secret and other intellectual property or proprietary rights laws. These
                        Terms permit you to use the Network for your personal, non-commercial use only. You must not
                        reproduce, distribute, modify, create derivative works of, publicly display, publicly perform,
                        republish, download, store or transmit any of the material on the Network except as generally
                        and ordinarily permitted through the Tools according to these Terms.
                    </div>

                    <h3 className={'terms-header'}>
                        23. Marks
                    </h3>

                    <div className={'terms-text'}>
                        Certain of the names, logos, and other materials displayed on the Network may constitute
                        trademarks, trade names, service marks or logos ("
                        <span className={'terms-bold-text'}>Marks</span>
                        ") of ours or other entities. You are
                        not authorized to use any such Marks without the express written permission of Morewards.
                        Ownership of all such Marks and the goodwill associated remains with us or those other entities.
                    </div>

                    <h3 className={'terms-header'}>
                        24. Governing Law
                    </h3>

                    <div className={'terms-text'}>
                        These Terms and your use of the Tools and Services shall be governed by the laws of the State of
                        Connecticut, without giving effect to the principles of conflict of laws. You hereby waive any objections to
                        such jurisdiction.
                    </div>

                    <h3 className={'terms-header'}>
                        25. Miscellaneous
                    </h3>

                    <div className={'terms-text'}>
                        No waiver by Morewards of any term or condition set forth in these Terms shall be deemed a
                        further or continuing waiver of such term or condition or a waiver of any other term or
                        condition, and any failure of Morewards to assert a right or provision under these Terms shall
                        not constitute a waiver of such right or provision. If any provision of these Terms is held by
                        a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable
                        for any reason, such provision shall be eliminated or limited to the minimum extent such that
                        the remaining provisions of the Terms will continue in full force and effect. These Terms are
                        the entire agreement between you and us and supersede all prior or contemporaneous negotiations,
                        discussions or agreements between you and us about the Network. The proprietary rights,
                        disclaimer of warranties, representations made by you, indemnities, limitations of liability
                        and privacy provisions shall survive any termination of these Terms.
                    </div>

                    <div className={'terms-text'}>
                        Please send any questions or report any violations of these Terms to
                        <a className={'terms-link'} href={'mailto:support@morewards.com'}> support@morewards.com</a>
                    </div>
                </div>

                {web
                    ? <button
                        className={'terms-button active'}
                        onClick={() => {
                            history.push('/')
                        }}
                    >Back</button>

                    : <button
                        className={'terms-button active'}
                        onClick={() => {
                            setTerms(false);
                        }}
                    >Close</button>
                }
            </div>
        </div>
    );
};

export default CustomerTerms;
