import axios from "axios";
import { BASE_URL } from "../constants";

const addProviderToFavorite = async (token, vendorId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${BASE_URL}/api/user/favorites/${vendorId}`,
    config
  );

  return response.data;
};

const getFavoriteProviders = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${BASE_URL}/api/user/favorites`, config);
  return response.data;
};

const getAllProviders = async (token, page) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${BASE_URL}/api/vendors/page/${page}`,
    config
  );
  return response.data;
};

export { addProviderToFavorite, getFavoriteProviders, getAllProviders };
