import React from "react";
import s from "./Job.module.css";
import moment from "moment";

const Job = ({ job }) => {
  const date = moment(job.updated_at).format("MMM Do");

  const activityStatus = () => {
    if (job.status === "complete" || job.status === "pending")
      return "Waiting for review";

    return job.status;
  };
  return (
    <div className={s.job__wrapper}>
      <div className={s.job__item}>
        <div>Job ID</div>
        <div className={s.job__item - date}>{job.id}</div>
      </div>
      <div className={s.job__item}>
        <div>Date</div>
        <div>{date}</div>
      </div>
      <div className={s.job__item}>
        <div>Status</div>
        <div>{activityStatus()}</div>
      </div>
      <div className={s.job__item}>
        <div>Vendor</div>
        <div className={s.job__item - date}>{job?.user_provider?.name}</div>
      </div>
      <div className={s.job__item}>
        <div>Category</div>
        <div>{job.title}</div>
      </div>
      <div className={s.job__item}>
        <div>Transaction Type</div>
        <div>
          {job.payment === "Reward"
            ? "Paid with reward"
            : job.payment === "Cash/credit" && "Paid with cash/credit"}
        </div>
      </div>
      <div className={s.job__item}>
        <div>Cost</div>
        <div>$ {job.amount_paid}</div>
      </div>
      {/*<div className={s.job__item}>*/}
      {/*	*/}
      {/*	*/}
      {/*	*/}
      {/*	*/}
      {/*</div>*/}

      {/*<div className={s.job__info}>*/}
      {/*	*/}
      {/*	*/}
      {/*	*/}
      {/*	*/}
      {/*	*/}
      {/*</div>*/}
    </div>
  );
};

export default Job;
