import React, { useEffect, useState } from "react";
import "./Verification.css";
import crossIcon from "../../../assets/close.png";
import { useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PhoneVerificationSchema,
  PhoneRegisterSchema,
} from "../../../validators";
import { useDispatch } from "react-redux";
import {
  setCustomerToken,
  setCurrentCustomer,
} from "../../../redux/action-creators";
import { verificationActions } from "../../../action";
import Modal from "../../Modal/Modal";
import { Link } from "react-router-dom";
import { phoneNormalizer } from "../../../helpers/phone-normalizer";
import { countries } from "../../../data/countries";
import { countryByIsoValue } from "../../../helpers/isoValueOfCountry";

const Verification = ({ vendorVerification }) => {
  // console.log('vendorVerification: ', vendorVerification);
  const [isMobileVerificationSection, setIsMobileVerificationSection] =
    useState(false);
  const [userData, setUserData] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [phoneValidationError, setPhoneValidationError] = useState(null);

  const { token } = useParams();
  const vendorPhone = localStorage.getItem("vendorPhone");
  const phone = localStorage.getItem("phone");
  // console.log('PHONE: ', phone);

  const history = useHistory();
  const dispatch = useDispatch();

  const [modalActive, setModalActive] = useState(false);

  const [verificationCodeError, setVerificationCodeError] = useState(null);
  const [errorModal, setErrorModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(PhoneVerificationSchema),
  });
  const {
    register: phoneNumberRegister,
    handleSubmit: phoneNumberHandleSubmit,
    formState: { errors: phoneNumberError },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(PhoneRegisterSchema),
  });

  const routeDependsOnRole = () => {
    if (vendorVerification) {
      return "/vendor";
    }

    return "/";
  };

  const sendVerificationCode = async (data) => {
    try {
      setModalActive(false);
      if (vendorVerification) {
        await verificationActions.verifySmsVendor(data);
        return;
      }

      const verifyRes = await verificationActions.verifySmsCustomer(data);
      console.log("\n\n verifyRes...", verifyRes);
      if (verifyRes?.data && verifyRes?.data.ok === true) {
        localStorage.removeItem("phone");
        dispatch(setCustomerToken(verifyRes.data?.data?.token));
        dispatch(setCurrentCustomer(verifyRes.data?.data?.user));

        localStorage.setItem("customerToken", verifyRes.data?.data?.token);
        localStorage.setItem('verification',true)
        localStorage.setItem(
          "currentCustomer",
          JSON.stringify(verifyRes.data?.data?.user)
        );
        history.push("/");
      }
    } catch (err) {
      let message = "Wrong verification code.";
      if (err?.response?.data && err?.response?.data?.ok === false) {
        message = err?.response?.data?.message || "Wrong verification code.";
      } else {
        message = err.message || "Wrong verification code.";
      }
      console.log("\n resendCode error...", message);
      setVerificationCodeError(message);
      setErrorModal(true);
      setTimeout(() => {
        setVerificationCodeError(null);
        setErrorModal(false);
      }, 2000);
    }
  };

  const resendCode = async () => {
    try {
      if (vendorVerification) {
        await verificationActions.sendVerifySmsVendor({
          phone: vendorPhone?.replace(/\s/g, ""),
        });
        return;
      }
      if (!phone) {
        throw new Error("Missing phone number");
      }
      await verificationActions.sendVerifySmsCustomer({
        phone: phone?.replace(/\s/g, ""),
      });
    } catch (err) {
      let message = "Something went wrong.";
      if (err?.response?.data && err?.response?.data?.ok === false) {
        message = err?.response?.data?.message || "Something went wrong.";
      } else {
        message = err.message || "Something went wrong.";
      }
      console.log("\n resendCode error...", message);
      setPhoneError(message);
      setTimeout(() => {
        setPhoneError(null);
      }, 2000);
    }
  };

  useEffect(() => {
    const verifyToken = async () => {
      try {
        if (vendorVerification) {
          verificationActions
            .sendVerifySmsVendor({ phone: vendorPhone?.replace(/\s/g, "") })
            .then(({ data }) => {
              // console.log('send verify sms vendor: ', data);
            })
            .catch((e) => {
              // console.log('ERROR send verify sms vendor: ', e.message);
            });

          return;
        }

        if (token) {
          const verifyResponse = await verificationActions.verifyEmailCustomer({
            token: token,
          });
          if (verifyResponse?.data?.data && verifyResponse?.data?.ok === true) {
            if (
              verifyResponse?.data?.data &&
              verifyResponse?.data?.data.length > 0
            ) {
              setUserData(verifyResponse?.data?.data[0]);
              const country = verifyResponse?.data?.data[0]?.country || 0;
              const countryName = countryByIsoValue(country);
              const countryData = countries.find(
                (country) =>
                  country.country.toLowerCase() === countryName.toLowerCase()
              );
              if (countryData && countryData.code) {
                setCountryCode(countryData.code);
              }
            }
          }
        }
      } catch (err) {
        let message = "Something went wrong.";
        if (err?.response?.data && err?.response?.data?.ok === false) {
          message = err?.response?.data?.message || "Something went wrong.";
        } else {
          message = err.message || "Something went wrong.";
        }
        setPhoneError(message);
        setTimeout(() => {
          setPhoneError(null);
        }, 2000);
      }
    };
    verifyToken();
  }, [token, vendorVerification, vendorPhone]);

  const sendCodeToPhoneNumber = async (data) => {
    try {
      if (!userData) {
        throw new Error("user information not found. Please contact admin.");
      }
      if (!userData.id) {
        throw new Error("user Id not found.");
      }

      if (!countryCode) {
        throw new Error("Country Code not found.");
      }

      const phone = `${countryCode} ${data.phoneNumber}`;
      console.log("\n phone1....", phone);
      //const phone = `+919729050850`;
      const otpResponse = await verificationActions.sendPhoneNumberForCustomer({
        phone: phone?.replace(/\s/g, ""),
        userId: userData.id,
      });
      console.log("\n otpResponse...", otpResponse);
      localStorage.setItem("phone", phone);
      setIsMobileVerificationSection(true);
    } catch (err) {
      console.log("\n error in sendCodeToPhoneNumber..", err.message || err);
      let message = "Something went wrong.";
      if (err?.response?.data && err?.response?.data?.ok === false) {
        message = err?.response?.data?.message || "Something went wrong.";
      } else {
        message = err.message || "Something went wrong.";
      }
      setPhoneError(message);
      setTimeout(() => {
        setPhoneError(null);
      }, 2000);
    }
  };

  useEffect(() => {
    try {
      if (phoneNumberError?.phoneNumber?.message) {
        setPhoneValidationError(phoneNumberError?.phoneNumber?.message);
        setTimeout(() => {
          setPhoneValidationError(null);
        }, 4000);
        return;
      }
      setPhoneValidationError(null);
    } catch (err) {
      console.log("\n error in phoneNumberError..", err.message || err);
    }
  }, [phoneNumberError]);

  return (
    <>
      {(vendorVerification || isMobileVerificationSection) && (
        <div className={"verification-wrapper"}>
          <div className={"verification-inner"}>
            <div className={"verification__title"}>Phone verification</div>

            <div className={"verification__text"}>
              <span>We have sent you a code to your</span>
              <span> mobile phone. Please enter it here</span>
            </div>

            <form
              id={"web-code-form"}
              noValidate={true}
              onSubmit={handleSubmit(sendVerificationCode)}
            >
              <input
                {...register("code")}
                className={"verification__input"}
                type="number"
                placeholder={"Verification Code"}
              />

              <button
                className={"verification__button active"}
                type={"submit"}
                onClick={() => {
                  if (Object.keys(errors).length) {
                    setModalActive(true);
                  }
                }}
              >
                {vendorVerification ? "Apply" : "Sign up"}
              </button>
            </form>

            <button
              className={"verification__button bold"}
              onClick={() => {
                history.push(routeDependsOnRole());
              }}
            >
              Back to log in
            </button>

            <div className={"verification__help"}>
              Didn’t receive a code?
              <span
                onClick={() => {
                  resendCode();
                }}
              >
                Resend
              </span>
            </div>

            <button
              form={"web-code-form"}
              className={"send-button-web"}
              type={"submit"}
              onClick={() => {
                if (Object.keys(errors).length) {
                  setModalActive(true);
                }
              }}
            >
              Sign up
            </button>
          </div>

          <div className={"verification-resend-code"}>
            Didn’t receive a code?
            <span
              onClick={() => {
                resendCode();
              }}
            >
              Resend
            </span>
          </div>
        </div>
      )}
      {!vendorVerification && !isMobileVerificationSection && (
        <div className={"verification-wrapper"}>
          <div className={"verification-inner"}>
            <div className={"verification__title"}>
              Phone Number verification
            </div>
            <div
              className={
                "verification__text signup-form__section-title text-align-left"
              }
            >
              Phone Number
            </div>
            <div className={"verification__text text-align-left"}>
              <span>
                Enter your phone number to receive a onetime passcode on your
                mobile device.
              </span>
            </div>
            <div className={"verification__text text-align-left"}>
              <span>
                Message and data rates may apply Reply "HELP" for help. Reply
                "STOP" to cancel.
              </span>
            </div>
            <div className="link-section">
              <Link to={"/terms-of-service"}>Terms and Conditions</Link> |{" "}
              <Link to={"/privacy-policy"}>Privacy Policy</Link>
            </div>
            <form
              id={"web-code-form"}
              noValidate={true}
              onSubmit={phoneNumberHandleSubmit(sendCodeToPhoneNumber)}
            >
              <div className={"verification__input margin-top margin-left"}>
                <div
                  className={"signup-form__input country-code"}
                  style={{
                    color: countryCode ? "#4B4846" : "#8E8E93",
                  }}
                >
                  {countryCode ? countryCode : "+1"}
                </div>
                <input
                  className={"signup-form__input phone-number"}
                  {...phoneNumberRegister("phoneNumber")}
                  id={"phoneNumber"}
                  type={"tel"}
                  placeholder={"999-999-9999"}
                  onChange={(event) => {
                    try {
                      const { value } = event.target;
                      event.target.value = phoneNormalizer(value);
                    } catch (err) {
                      console.log("\n error in on change phone no...");
                    }
                  }}
                />
              </div>
              {(phoneError || phoneValidationError) && (
                <div className="phone-error-section">
                  {phoneValidationError || phoneError}
                </div>
              )}

              <button className="send-otp-btn" type={"submit"}>
                Submit
              </button>
            </form>
          </div>
        </div>
      )}

      {modalActive && (
        <Modal active={modalActive} setActive={setModalActive}>
          <div className={"vendor-errors"}>
            <img
              src={crossIcon}
              alt="cross"
              onClick={() => {
                setModalActive(false);
              }}
            />

            <div>
              <span>Error</span>

              {errors?.code && <div>{errors?.code?.message}</div>}
            </div>
          </div>
        </Modal>
      )}

      {errorModal && (
        <Modal active={errorModal} setActive={setErrorModal}>
          <div className={"vendor-errors"}>
            <img
              src={crossIcon}
              alt="cross"
              onClick={() => {
                setErrorModal(false);
              }}
            />

            <div>
              <span>Error</span>

              <div>{verificationCodeError}</div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Verification;
