import {SET_PROVIDER_REVIEWS} from "../action-types";

const initialState = {
	reviews: []
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PROVIDER_REVIEWS: {
			return {
				...state,
				reviews: action.payload
			}
		}

		default: return state;
	}
}

export default reducer;
