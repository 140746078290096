import * as yup from "yup";

const CustomerSignUpSchema = yup.object().shape({
  firstname: yup
    .string()
    .required("First name is a required field")
    .max(50, "First name must be at most 50 characters")
    .matches(
      /^([A-Z]{1}[a-z]{1,23})$/,
      "First name should contain only 1 uppercase and no characters other than letters"
    ),

  lastname: yup
    .string()
    .required("Last name is a required field")
    .max(50, "Last name must be at most 50 characters")
    .matches(
      /^([A-Z]{1}[a-z]{1,23})$/,
      "Last name should contain only 1 uppercase and no characters other than letters"
    ),

  city: yup
    .string()
    .required("City is a required field")
    .trim()
    .matches(/^[\w\s ,.]+$/, "Enter correct city"),

  address1: yup
    .string()
    .required("Address is a required field")
    .matches(/^[\w\s ,.]+$/, "Incorrect address"),

  CanadaZipCode: yup
    .string()
    .matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, "Invalid code")
    .notRequired(),

  IrelandZipCode: yup
    .string()
    .max(8)
    .matches(/[A-Z]|\d{3}[\s.-][A-Z]|\d{4}$/, "Invalid code 2")
    .notRequired(),

  UnitedStatesZipCode: yup
    .string()
    .max(5)
    .matches(/\d{5}([ ]\d{4})?/, "Invalid code")
    .notRequired(),

  email: yup.string().required("Email is a required field").email(),

  password: yup
    .string()
    .required("Password is a required field")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Password should contain at least 1 uppercase, at least 1 digit and 1 special character, namely 1 out of 33 " +
        "following characters: space ! \" # $ % & ' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _` { | } ~);"
    ),

  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")])
    .required("Confirm password is a required field"),

  referral_code: yup
    .string()
    .matches(
      /^[A-Za-z0-9]*$/,
      "Referral code can only contain letters and digits"
    ),
});

export { CustomerSignUpSchema };

/* phoneNumber: yup
        .string()
        .required('Phone number is a required field')
        .matches(/^((8|\+7)[ ]?)?(\(?\d{3}\)?[ ]?)?[\d\- ]{7,10}$/, 'Enter correct phone number'),
 */
