import {useDispatch, useSelector} from "react-redux";
import {setCurrentCustomer, setCustomerToken} from "../redux/action-creators";
import {setCurrentVendor, setVendorToken} from "../redux/action-creators";

const useAuth = () => {
    const dispatch = useDispatch();

    const customerToken = useSelector(({customerReducer: {customerToken}}) => customerToken);
    const vendorToken = useSelector(({vendorReducer: {vendorToken}}) => vendorToken);

    const customerTokenLocal = localStorage.getItem('customerToken');
    const vendorTokenLocal = localStorage.getItem('vendorToken');

    if (!customerToken && customerTokenLocal) {
        dispatch(setCustomerToken(customerTokenLocal));
        dispatch(setCurrentCustomer(JSON.parse(localStorage.getItem('currentCustomer'))));
    }

    if (!vendorToken) {
        dispatch(setVendorToken(vendorTokenLocal));
        dispatch(setCurrentVendor(JSON.parse(localStorage.getItem('currentVendor'))));
    }

    const isAuthenticated = !!customerToken || !!vendorToken;

    return isAuthenticated;
}

export default useAuth;
