import React, { useEffect, useRef, useState } from "react";
import s from "./WebActivitiesPage.module.css";

import searchIcon from "../assets/search.svg";
import filterIcon from "../assets/filter-web.svg";

import { jobActions } from "../action";
import { useObserver } from "../hooks/useObserver";

import Activity from "../WEB-components/Activity/Activity";
import Popup from "../components/Popup/Popup";

import AddedFunds from "../WEB-components/WebFilteredActivities/AddedFunds";
import Reviewed from "../WEB-components/WebFilteredActivities/Reviewed";
import WaitingForReviews from "../WEB-components/WebFilteredActivities/WaitingForReviews";
import Refunds from "../WEB-components/WebFilteredActivities/Refunds";
import RewardForInvites from "../WEB-components/WebFilteredActivities/RewardForInvites";
import Rewards from "../WEB-components/WebFilteredActivities/Rewards";
import BonusRewards from "../WEB-components/WebFilteredActivities/BonusRewards";

const WebActivitiesPage = () => {
  const customerTokenLocal = localStorage.getItem("customerToken");

  const [jobs, setJobs] = useState([]);

  const lastElement = useRef();
  const [page, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [addFavorite, setAddFavorite] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [searchInputActive, setSearchInputActive] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [showFilters, setShowFilters] = useState(false);

  const [showReview, setShowReview] = useState(false);
  const [showPending, setShowPending] = useState(false);
  const [showRefund, setShowRefund] = useState(false);
  const [showInvites, setShowInvites] = useState(false);
  const [showRewards, setShowRewards] = useState(false);
  const [showReviews, setShowReviews] = useState(false);
  const [showFunds, setShowFunds] = useState(false);

  useObserver(lastElement, page <= totalPages, isLoading, () => {
    setCurrentPage(page + 1);
  });

  useEffect(() => {
    setIsLoading(true);

    jobActions
      .getJobs(page, customerTokenLocal)
      .then(({ data }) => {
        setTotalPages(data.jobs.last_page);

        if (page === data.jobs.last_page) {
          setIsLoading(false);
          return;
        }
        const allJobs = [...jobs, ...data.jobs.data];
        const uniqueJobs = [
          ...new Map(allJobs.map((item) => [item.id, item])).values(),
        ];
        setJobs(uniqueJobs);
        setIsLoading(false);
      })
      .catch((e) => console.log(e, "lol"));
  }, [customerTokenLocal, page, addFavorite]);

  const searchActivity = () => {
    return jobs.filter((job) => {
      if (!inputValue) return job;
      return job?.user_provider?.name
        ?.toLowerCase()
        .includes(inputValue.toLowerCase());
    });
  };

  const activities = () => {
    if (showFunds) return <AddedFunds />;
    if (showReview) return <Reviewed />;
    if (showPending) return <WaitingForReviews />;
    if (showRefund) return <Refunds />;
    if (showInvites) return <RewardForInvites />;
    if (showReviews) return <Rewards />;
    if (showRewards) return <BonusRewards />;

    return (
      <>
        {searchActivity().map((job) => (
          <Activity
            key={job.id}
            job={job}
            setAddFavorite={setAddFavorite}
            addFavorite={addFavorite}
          />
        ))}
        {!isLoading && <div ref={lastElement} />}
      </>
    );
  };

  return (
    <div className={"web-container"}>
      <div className={s.sf_activities}>
        <div className={s.activities__search}>
          <div className={s.activities__search_title}>My activities</div>
          <div
            className={
              searchInputActive
                ? `${s.activities__search_input} ${s.active}`
                : s.activities__search_input
            }
            onClick={() => setSearchInputActive(true)}
          >
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <img src={searchIcon} alt="search" />
          </div>
        </div>

        <img
          src={filterIcon}
          alt="filter activities"
          onClick={() => setShowFilters(true)}
        />
      </div>

      <div className={s.my__activities}>{activities()}</div>

      <Popup popupActive={showFilters} setPopupActive={setShowFilters}>
        <div className={s.filters_title}>Sort by:</div>

        <div
          className={s.filters_option}
          onClick={() => {
            setShowFilters(false);
            setShowFunds(true);

            setShowReview(false);
            setShowPending(false);
            setShowRefund(false);
            setShowInvites(false);
            setShowRewards(false);
            setShowReviews(false);
          }}
        >
          Added Funds
        </div>

        <div
          className={s.filters_option}
          onClick={() => {
            setShowFilters(false);
            setShowRewards(true);

            setShowFunds(false);
            setShowReview(false);
            setShowPending(false);
            setShowRefund(false);
            setShowInvites(false);
            setShowReviews(false);
          }}
        >
          Bonus Rewards
        </div>

        <div
          className={s.filters_option}
          onClick={() => {
            setShowReview(false);
            setShowPending(false);
            setShowInvites(false);
            setShowRewards(false);
            setShowReviews(false);
            setShowFunds(false);

            setShowFilters(false);
            setShowRefund(true);
          }}
        >
          Refunds
        </div>

        <div
          className={s.filters_option}
          onClick={() => {
            setShowPending(false);
            setShowRefund(false);
            setShowInvites(false);
            setShowRewards(false);
            setShowReviews(false);
            setShowFunds(false);

            setShowFilters(false);
            setShowReview(true);
          }}
        >
          Reviewed
        </div>

        <div
          className={s.filters_option}
          onClick={() => {
            setShowReview(false);
            setShowPending(false);
            setShowRefund(false);
            setShowRewards(false);
            setShowReviews(false);
            setShowFunds(false);

            setShowFilters(false);
            setShowInvites(true);
          }}
        >
          Reward for invites
        </div>

        <div
          className={s.filters_option}
          onClick={() => {
            setShowReview(false);
            setShowPending(false);
            setShowRefund(false);
            setShowInvites(false);
            setShowRewards(false);
            setShowFunds(false);

            setShowFilters(false);
            setShowReviews(true);
          }}
        >
          Rewards for reviews
        </div>

        <div
          className={s.filters_option}
          onClick={() => {
            setShowReview(false);
            setShowRefund(false);
            setShowInvites(false);
            setShowRewards(false);
            setShowReviews(false);
            setShowFunds(false);

            setShowFilters(false);
            setShowPending(true);
          }}
        >
          Waiting for reviews
        </div>

        <div
          className={`${s.filters_option} ${s.clear}`}
          onClick={() => {
            setShowReview(false);
            setShowPending(false);
            setShowRefund(false);
            setShowInvites(false);
            setShowRewards(false);
            setShowReviews(false);
            setShowFunds(false);
            setShowFilters(false);
          }}
        >
          Clear All
        </div>

        <div className={s.filters_option} onClick={() => setShowFilters(false)}>
          Cancel
        </div>
      </Popup>
    </div>
  );
};

export default WebActivitiesPage;
