import React, {useState} from 'react';
import './Account.css';

import goBackArrow from "../../assets/arrows/blue-left-arrow.png";
import crossIcon from "../../assets/close.png";

import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {createNewPasswordSchema} from "../../validators";

import {useDispatch, useSelector} from "react-redux";
import {setPasswordChanged} from "../../redux/action-creators";

import {passwordActions} from "../../action";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import Modal from "../Modal/Modal";

const ChangePasswordNew = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    // const token = localStorage.getItem('customerToken');
    const token = useSelector(({customerReducer: {customerToken}}) => customerToken);

    const [modalActive, setModalActive] = useState(false);

    const { register, handleSubmit, formState: {errors} } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(createNewPasswordSchema)
    });

    const changePassword = (data) => {
        setModalActive(false);

        data.password_confirmation = data.confirmPassword;
        delete data.confirmPassword;

        passwordActions.changePassword(data, token)
            .then(({data}) => {

                if (data.ok) {
                    dispatch(setPasswordChanged(true));
                    history.push('/account');
                }
            });
    }

    return (
        <div className={'account__wrapper'}>
            <HeaderMobile />

            <div className={'account__inner'}>
                <div className={'account__title sub'}>
                    <img
                        src={goBackArrow}
                        alt="go back arrow"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    Change password
                    <div />
                </div>

                <div className={'account__inner-subtitle'}>
                    Please enter your new password:
                </div>

                <form noValidate onSubmit={handleSubmit(changePassword)}>
                    <input
                        className={'account__form-input'}
                        {...register('password', {required: true})}
                        type="password"
                        id={'password'}
                        placeholder={'New password'}
                    />

                    <input
                        className={'account__form-input'}
                        {...register('confirmPassword', {required: true})}
                        type="password"
                        id={'password_confirmation'}
                        placeholder={'Repeat password'}
                    />

                    <button
                        type={"submit"}
                        className={'account__button active'}
                        onClick={() => {
                            if (Object.keys(errors).length) {
                                setModalActive(true);
                            }
                        }}
                    >Save</button>
                </form>
            </div>

            <Modal active={modalActive} setActive={setModalActive}>
                <div className={'vendor-errors'}>
                    <img
                        src={crossIcon}
                        alt="cross"
                        onClick={() => {
                            setModalActive(false);
                        }}
                    />

                    <div>
                        <span>Error</span>

                        {errors?.password &&
                        <div>{errors.password.message}</div>}
                        {errors?.confirmPassword &&
                        <div>{errors.confirmPassword.message}</div>}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ChangePasswordNew;
