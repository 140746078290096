import React, { useEffect, useState } from "react";
import s from "./WebCategory.module.css";

import { categoriesActions, providerActions } from "../../action";
import WebFavorite from "../WebFavorite/WebFavorite";
import backArrow from "../../assets/arrows/blue-left-arrow.png";

const WebCategories = ({
  searchValue,
  setSearchByProviderName,
  setShowCategoryProvidersID,
  setSearchByCategoryName,
  setSearchBySubCategoryName,
  setParentCategoryId,
}) => {
  const customerTokenLocal = localStorage.getItem("customerToken");

  const [showFavorites, setShowFavorites] = useState(false);

  const [categories, setCategories] = useState([]);
  const [favoriteProviders, setFavoriteProviders] = useState([]);

  useEffect(() => {
    categoriesActions
      .getCategoriesListParent(customerTokenLocal)
      .then(({ data }) => {
        setCategories(data);
      });

    providerActions
      .getFavoriteProviders(customerTokenLocal)
      .then(({ data }) => {
        setFavoriteProviders(data);
      });
  }, [customerTokenLocal]);

  const searchCategory = () => {
    return categories.filter((category) => {
      return category.name.toLowerCase().includes(searchValue.toLowerCase());
    });
  };

  const isFavoritesShowed = () => {
    if (!showFavorites)
      return (
        <>
          Favorites <span>({favoriteProviders?.length})</span>
        </>
      );

    return <>Back to categories</>;
  };

  return (
    <>
      <div
        className={s.categories__title}
        onClick={() => {
          setShowFavorites(!showFavorites);
        }}
        style={{ cursor: "pointer" }}
      >
        {isFavoritesShowed()}
      </div>
      {showFavorites && <WebFavorite />}

      {!showFavorites && (
        <div className={s.categories}>
          {searchCategory().map((category) => (
            <div
              key={category.id}
              className={s.category}
              onClick={() => {
                setSearchByCategoryName(false);

                setSearchBySubCategoryName(true);
                setParentCategoryId(category.id);

                // setShowCategoryProvidersID(category.id);
                // setSearchByProviderName(true);
              }}
            >
              {category.name}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default WebCategories;
