import React from 'react';
import './TermsMobile.css';

import backArrow from "../../../assets/arrows/blue-left-arrow.png";

const VendorTerms = ({setTerms}) => {
    return (
        <div className={'terms-wrapper'}>
            <div className={'terms-inner'}>
                <div className={'terms-title'}>
                    <img
                        src={backArrow}
                        alt="back arrow"
                        onClick={() => {
                            setTerms(false);
                        }}
                    />
                    Terms of Service
                    <div/>
                </div>

                <div className={'terms-text-wrapper'}>
                    <h3 className={'terms-header'}>
                        Morewards US Merchant Agreement Terms and Conditions
                        <span className={'terms-red-text bold'}> Draft</span>
                    </h3>

                    <div className={'terms-small-text'}>Last Updated: October 21, 2020</div>

                    <div className={'terms-text'}>
                        These Terms and Conditions (“
                        <span className={'terms-bold-text'}>Terms</span>
                        ”) form a legal agreement between you, the merchant, store,
                        service provider, retailer, or vendor (collectively, “
                        <span className={'terms-bold-text'}>Merchant</span>”, “
                        <span className={'terms-bold-text'}>you</span>”, “
                        <span className={'terms-bold-text'}>your</span>”) and Morewards, Inc., a
                        Connecticut corporation (“
                        <span className={'terms-bold-text'}>Morewards</span>”, “
                        <span className={'terms-bold-text'}>we</span>”, “
                        <span className={'terms-bold-text'}>us</span>”, “
                        <span className={'terms-bold-text'}>our</span>”) and govern your access to and use of: (1) the
                        software, hardware, devices, applications, websites and any other web-based or mobile technology
                        that
                        Morewards owns or operates and that links to or references these Terms (the “
                        <span className={'terms-bold-text'}>Tools</span>
                        ”); and (2) the
                        services that Morewards itself furnishes through the Tools (the “
                        <span className={'terms-bold-text'}>Services</span>
                        ”) and (3) your Morewards merchant account (“
                        <span className={'terms-bold-text'}>Merchant Account</span>”, “
                        <span className={'terms-bold-text'}>Account</span>”). The Tools and Services function together
                        as the
                        Morewards network (the “
                        <span className={'terms-bold-text'}>Network</span>”).
                    </div>

                    <h3 className={'terms-header'}>1. Acceptance of Terms</h3>

                    <div className={'terms-text'}>
                        By installing, downloading, registering with, or using the Tools, or by using the Network, in
                        any way, you
                        agree to be bound by each and every one of these Terms. If you do not agree with these Terms,
                        please
                        do not install, download, register with, or use the Network. If the individual accepting this
                        agreement is
                        accepting on behalf of a Merchant or other legal entity, such individual represents that they
                        have the
                        authority to bind such entity to these Terms, in which case the term “Merchant” shall refer to
                        such entity. If
                        the individual accepting this agreement does not have such authority, or does not agree with
                        these
                        Terms, such individual must not accept this agreement and may not use the Network.
                    </div>

                    <h3 className={'terms-header'}>2. Consumer and Merchant Accounts</h3>
                    <h3 className={'terms-header'}>
                        2.1 Consumer Rewards Accounts, Rewards Cards, Balances, Redemption.
                    </h3>

                    <div className={'terms-text'}>
                        A “
                        <span className={'terms-bold-text'}>Reward Card</span>
                        ” is issued to a Morewards Network shopper (a “
                        <span className={'terms-bold-text'}>Consumer</span>
                        ”). Rewards are issued as “
                        <span className={'terms-bold-text'}>Rewards</span>”, “
                        <span className={'terms-bold-text'}>MOR Rewards</span>”, “
                        <span className={'terms-bold-text'}>Reward Dollars</span>”, or “
                        <span className={'terms-bold-text'}>MOR Dollars</span>” and are added to a Consumer’s
                        account balance (“
                        <span className={'terms-bold-text'}>Consumer Rewards Balance</span>”) when Consumers
                        purchase eligible products, goods, and services (“
                        <span className={'terms-bold-text'}>Items</span>”); through the Network at a Morewards
                        authorized Merchant. A Consumer Rewards
                        Balance displays the total remaining balance of all Rewards that have been applied to a
                        Consumer’s
                        account but not yet applied to a purchase. We may also refer to a Consumer Rewards Balance as
                        their “
                        <span className={'terms-bold-text'}>Rewards Balance</span>”, “
                        <span className={'terms-bold-text'}>MOR Dollar Balance</span>”, or “
                        <span className={'terms-bold-text'}>MOR Balance</span>”. Consumers can shop at an
                        authorized
                        Merchant using any payment method allowed by the Merchant. Cash, or cash equivalent (credit, and
                        debit) purchases will generate Rewards added through our Network to a Consumer’s Rewards
                        Balance.
                        Consumers can also purchase Items directly using their Rewards Dollars. All Reward values shall
                        be in
                        United States Dollar (USD) currency. Purchases using Rewards are deducted from a Consumer’s
                        Reward
                        Balance. Any unused MOR Dollars will remain associated with their Account. If a purchase exceeds
                        their
                        MOR Dollar Balance, the Consumer has the option to either add Reward funds through the Network
                        to
                        their Reward Balance or pay the entire purchase with cash or cash equivalent.
                    </div>

                    <div className={'terms-text bold'}>
                        When Consumers purchase Items at authorized Merchants, they will earn Rewards for every
                        eligible item they purchase, every time they shop, regardless of the method of payment they
                        choose. Rewards can be used to purchase Items at any authorized Merchant regardless of where
                        they earned the Rewards. A Consumer Rewards Balance does not expire and may be applied to all
                        eligible item purchases.
                    </div>

                    <h3 className={'terms-header'}>
                        2.2 Merchant Account Setup and Registration for Consumer Rewards Services
                    </h3>

                    <div className={'terms-text'}>
                        Merchant Accounts must be applied for and explicitly authorized. You must open a Morewards
                        Account to process Rewards. Merchants can download the “
                        <span className={'terms-bold-text'}>Application</span>
                        ” online on morewards.com or with the
                        Merchant App at the application store for your business’ mobile device (“
                        <span className={'terms-bold-text'}>MOR Merchant App</span>
                        ”). To access the password-required portions of the Tools and Services, you must first register
                        on the
                        Application and create an Account. If your access to the Network was previously terminated by
                        us, you may not register to use the Network.
                    </div>

                    <div className={'terms-text'}>
                        You may only have one Merchant account per business Location. You confirm that you are either a
                        legal
                        resident of the United States, a United States citizen, or a business entity authorized to
                        conduct business
                        by the state(s) in which you operate and that you are an authorized signatory for the business
                        you
                        represent. You must use your or your business’ true and accurate name when opening a Morewards
                        Account. You attest that neither you nor your Business is establishing a Merchant Account for
                        personal,
                        family, or household purposes. When registering to create an Account, you must furnish your
                        Business or
                        Business representative’s name, e-mail address, password, business location and certain other
                        information collected by Morewards (collectively, the “
                        <span className={'terms-bold-text'}>Account Information</span>
                        ”). You agree that the
                        Account Information that you provide to us is, at all times true, accurate, current, and
                        complete. You are
                        responsible for updating your business location and contact information. You may change,
                        correct, add or
                        remove any information from your Account through the Tools and Services. On activation of your
                        Merchant account (“
                        <span className={'terms-bold-text'}>Account</span>”), account balances (“
                        <span className={'terms-bold-text'}>Merchant Balances</span>”, “
                        <span className={'terms-bold-text'}>Account Balances</span>”, “
                        <span className={'terms-bold-text'}>Balances</span>”) will be established and adjusted through
                        the Network as described in <span className={'terms-red-text'}>Section 6.9</span>
                    </div>

                    <h3 className={'terms-header'}>
                        2.3 Merchant Account Setup Underwriting and Identity Verification
                    </h3>

                    <div className={'terms-text'}>
                        Morewards will review the information you submit while signing up for your Account and for the
                        Payment
                        Services described in
                        <span className={'terms-red-text'}> Section 6.10</span>
                        . You authorize Morewards to request identity verifying information
                        about you, including a consumer report that contains your name and address. Morewards may
                        periodically obtain additional reports to determine whether you continue to meet the
                        requirements for a
                        Merchant Account. You permit Morewards to share information about you and your application
                        (including
                        whether you are approved or declined), and your Merchant Account with your bank or other
                        financial
                        institution, or as otherwise specified in our Privacy Policy
                        <span className={'terms-red-text'}> link here</span>
                        . Morewards or its processor and/or
                        acquiring bank may conclude that you will not be permitted to use the Network.
                    </div>

                    <h3 className={'terms-header'}>
                        2.4 Requests for Additional Information and Inspection
                    </h3>

                    <div className={'terms-text'}>
                        Morewards may request additional information from you at any time. For example, Morewards may
                        ask
                        you to present a government issued identification such as a passport or driver’s license, a
                        business
                        license, or other information. Morewards may also ask for permission to inspect your business
                        location. If
                        you refuse any of these requests, your Merchant Account may be denied, suspended or terminated.
                    </div>

                    <div className={'terms-text'}>
                        You are responsible for maintaining the confidentiality of your Account, your Account password
                        and for all
                        activities that occur under your Merchant Account. We reserve the right to take any and all
                        action, as we
                        deem necessary or reasonable, regarding the security of the Tools, the Network and your Account
                        Information. You are responsible for maintaining the integrity of the information related to
                        Merchant
                        access and use of the Network, including any password, login or key information. You represent
                        and
                        warrant that you will not share such information with any third party. In no event and under no
                        circumstances shall we be held liable to you for any liabilities or damages resulting from or
                        arising out of
                        your use of the Network, your use of the Account Information or your release of the Account
                        Information
                        to a third party. Morewards reserves the right to require additional verification of your
                        business identity. If
                        you have reason to believe that your Account is no longer secure, change your username and
                        password
                        or immediately close your Account. You must also promptly notify us at our
                        <span className={'terms-red-text'}> Contact Us </span>
                        page on morewards.com of your concerns about the security of your Account, of the basis of your
                        concerns, and of any steps you have taken and/or plan to take to deal with your concerns.
                    </div>

                    <h3 className={'terms-header'}>
                        3. About Us
                    </h3>

                    <div className={'terms-text'}>
                        We are a technology company that owns and/or operates the Morewards Network to allow you, the
                        Merchant, to make your products and services more easily available to consumers. Our mission is
                        to
                        empower and connect consumers and businesses by removing barriers to access and make marketing
                        efforts more direct and affordable. Morewards itself does not produce, sell, or process the sale
                        of any
                        Items. Our business concern and mission are to provide convenient technology for you to connect
                        with
                        consumers and reward them for selecting your offerings as far as is practical and lawful. As
                        such,
                        Morewards exclusively controls the Network. We a neither a Marketplace Facilitator nor a Limited
                        Payment Collection Agent. Each Merchant shall be individually responsible for collecting payment
                        and all
                        applicable sales taxes for Items and for using the Network to process Rewards to Consumers.
                    </div>

                    <h3 className={'terms-header'}>
                        4. Right to Modify Terms
                    </h3>

                    <div className={'terms-text'}>
                        We may at our sole discretion change, add, or delete portions of these Terms (a “
                        <span className={'terms-bold-text'}>Terms Update</span>
                        ”) at any time. A Terms Update is available on our website on morewrds.com or the MOR Merchant
                        App. Merchant
                        is responsible for regularly reviewing the Terms and any applicable modifications or Terms
                        Updates and
                        information from Morewards. Continued use of the Network after any such modifications or updates
                        shall
                        constitute your consent to such changes. If you object to any changes to these Terms, your sole
                        recourse
                        will be to cease using the Network. We will not be liable to you or any third-party should we
                        exercise our
                        right to modify or discontinue the Services. In addition, certain features of the Network may be
                        subject to
                        additional terms of use. By using such features, or any part thereof, you agree to be bound by
                        the
                        additional terms of use applicable to such features. In the event that any of the additional
                        terms of use
                        governing such areas conflict with these Terms, the additional terms will govern.
                    </div>

                    <h3 className={'terms-header'}>
                        5. Morewards Obligations
                    </h3>

                    <div className={'terms-text'}>
                        Nothing in these Terms will be deemed to create any joint venture, joint enterprise, or agency
                        relationship
                        among the parties (except as otherwise expressly set forth above), and no party will have the
                        right to
                        enter into contracts on behalf of, to legally bind, to incur debt on behalf of, or to otherwise
                        incur any
                        liability or obligation on behalf of, the other party hereto, in the absence of a separate
                        writing, executed by
                        an authorized representative of the other party. Except as may be expressly agreed in these
                        Terms, each
                        party will be responsible for its own expenses and costs during its performance under these
                        Terms. Each
                        party will be solely responsible for its employees and contractors used in connection with such
                        party’s
                        performance obligations under these Terms.
                    </div>

                    <h3 className={'terms-header'}>
                        5.1 Access Rights and Ownership
                    </h3>

                    <div className={'terms-text'}>
                        We hereby grant to you, the Merchant, a limited, non-exclusive, nontransferable right to access
                        the
                        Network and use the Services and only as permitted under these Terms and any separate agreements
                        you may have entered into with us (“
                        <span className={'terms-bold-text'}>Access Rights</span>
                        ”). The purpose of these rights is to enable
                        Consumers to search, shop, and be rewarded for the purchase of Items from Merchant. Subject to
                        these
                        Terms, we will make the Network available to you, solely for use by you at locations that are
                        owned and
                        operated by you (each, a “
                        <span className={'terms-bold-text'}>Location</span>
                        ”). We reserve the right, in our sole discretion, to deny or suspend use
                        of the Network to you for any reason.
                    </div>

                    <div className={'terms-text'}>
                        The Tools and Services, and their entire contents, features and functionality (including but not
                        limited to
                        all information, software, text, displays, images, and the design, selection and arrangement
                        thereof), are
                        owned by Morewards, its licensors or other providers of such material and are protected by
                        United States
                        and international copyright, patent, trademark, trade dress, trade secret and other intellectual
                        property or
                        proprietary rights laws. You must not reproduce, distribute, modify, create derivative works of,
                        publicly
                        display, publicly perform, republish, download, store or transmit any of the material on the
                        Tools except
                        as generally and ordinarily permitted through the Network according to these Terms. Morewards
                        will
                        retain sole and absolute control over the Network (and all elements of the user experience and
                        user
                        interface relating to the Network), including with respect to: (i) the personalization of the
                        Morewards Tools
                        for Consumers; (ii) the prioritization and display of options available to Consumers; (iii) the
                        search
                        functionality and results provided to Consumers; (iv) the Merchant review functionality; (v)
                        adding,
                        removing or otherwise modifying any feature or functionality made available through the Network
                        to
                        optimize reliability or efficiency of the Services and Tools.
                    </div>

                    <h3 className={'terms-header'}>
                        5.2 Merchant Account Balance Maintenance
                    </h3>

                    <div className={'terms-text'}>
                        Subject to these Terms, Our Services will adjust your Merchant Balances based on Consumer Item
                        purchases, Account Balance thresholds, fees and our Payments to you described in
                        <span className={'terms-red-text'}> Section 6.9 and 6.10</span>.
                    </div>

                    <h3 className={'terms-header'}>
                        5.3 Communications
                    </h3>

                    <div className={'terms-text'}>
                        As part of providing the Services, and connecting you with Consumers, we may provide you with
                        certain
                        communications, such as notifications, notices, administrative messages, and other
                        communications
                        about the Services (“Communications”). These Communications are considered part of the Network
                        and
                        your Account. You agree and consent that any Communications that we send you electronically will
                        satisfy any legal communication requirements, including that such communications be in writing.
                    </div>

                    <div className={'terms-text'}>
                        You understand that by using the Network you are agreeing to these Terms and that such action
                        constitutes a legal signature. You agree that we may send to you Communications through
                        electronic
                        means including but not limited to: (1) by email, using the address that you provided to us
                        during
                        registration, (2) push notifications on your tablet or mobile device, or (3) By SMS messages
                        using the
                        number that you provided to us during registration, or (4) by posting Communications on the
                        Tools. The
                        delivery of any Communications from us is effective when sent by us, regardless of whether you
                        read the
                        Communication. You can withdraw your consent to receive Communications by deactivating your
                        Account. We cannot ensure the security or confidentiality of messages sent by email. Information
                        relating
                        to your Account is stored on secure, encrypted servers maintained by Morewards.
                    </div>

                    <div className={'terms-text'}>
                        Message and data rates may apply for any Communications sent to you from us and for any
                        communications to us from you. You will receive varied frequency of Communications. If you have
                        any
                        questions about your text plan or data plan, it is best to contact your wireless or data
                        provider. We are not
                        responsible for the internet or data bandwidth and signal strength of your mobile device.
                    </div>

                    <h3 className={'terms-header'}>
                        5.4 Processing Errors
                    </h3>

                    <div className={'terms-text'}>
                        We will rectify any Payment or Account Balance processing error that we discover. If the error
                        results in:
                        (i) you receiving less than the correct Payment or Balance amount to which you were entitled,
                        then we will
                        credit your Account for the difference between what you should have received and what you
                        actually
                        received; (ii) you receiving more than the correct Payment or Balance amount to which you were
                        entitled,
                        then we will debit your Account for the difference between what you actually received and what
                        you
                        should have received; (iii) our not completing a Payment on time or in the correct amount, then
                        we will be
                        responsible to you for your losses or damages directly caused by this failure, unless: (a)
                        through no fault
                        of ours, you did not have enough available funds to complete the transaction; (b) our system was
                        not
                        working properly and you knew about the breakdown when you started the transaction; or (c) the
                        error
                        was due to extraordinary circumstances outside our control (such as fire, flood or loss of
                        Internet
                        connection), despite our reasonable precautions. Processing errors are not: (i) delays that
                        result from us
                        applying holds or limitations; (ii) delays based on a payment review; (iii) delays related to
                        the time it may
                        take for a purchase transaction to be completed in some situations; (iv) your errors in
                        processing a
                        transaction (for example, mistyping the Total Price for Item purchases).
                    </div>

                    <h3 className={'terms-header'}>
                        6. Merchant Obligations
                    </h3>

                    <h3 className={'terms-header'}>
                        6.1 Item Pricing: Retail Price, Sales Taxes, Total Price
                    </h3>

                    <div className={'terms-text'}>
                        Our Network connects you with Consumers who wish to purchase your Items. You are the merchant,
                        vendor, service provider, retailer, or seller of all Items to be made available for sale through
                        the Network.
                        As such, you are responsible for determining and setting the retail price for each Item (the “
                        <span className={'terms-bold-text'}>Retail Price</span>
                        ”), and you are responsible for the collection and remittance of all payments and applicable
                        Sales Taxes,
                        where required under applicable law. The term “
                        <span className={'terms-bold-text'}>Sales Tax</span>
                        ” includes any sales, sellers use, transaction
                        privilege, privilege, general excise, gross receipts, and similar transaction taxes, as well as
                        any bottle,
                        bag, plastic, or other similar fees. For clarity, the Retail Price for each Item excludes
                        separately stated
                        Sales Taxes. The “
                        <span className={'terms-bold-text'}>Total Price</span>
                        ” for an Item will be the Retail Price plus all Sales Taxes collected from the Consumer by
                        Merchant.
                    </div>

                    <h3 className={'terms-header'}>
                        6.2 Item Sales and Rewards Processing
                    </h3>

                    <div className={'terms-text'}>
                        Morewards is not a marketplace facilitator and shall make no claim to process the sale of
                        Merchant Items
                        or collect Sales Taxes on such Items. In addition to processing and collecting payment for all
                        Item
                        purchases and applicable Sales Taxes, Merchant shall process the Consumer Rewards using the
                        Morewards Tools made available by these Terms for all eligible items purchased by the Consumer.
                    </div>

                    <div className={'terms-text'}>
                        Morewards Consumers must choose to pay you for Items either with cash or cash equivalent (debit,
                        credit, check) OR with Rewards Dollars but NOT with a combination of the two. Regardless of the
                        method
                        of payment, Merchant must input the Total Price (as defined in Section 6.1 above) into the
                        Morewards
                        Tools which will process the Consumer Reward.
                    </div>

                    <h3 className={'terms-header'}>
                        6.3 Equal Pricing
                    </h3>

                    <div className={'terms-text'}>
                        Merchant may not make any Item available to Consumers through the Network at a price that is
                        higher
                        than the price that Merchant charges in-store or online for similar Items. You agree that you
                        will not make
                        an Item available under these Terms at a price higher than the amount Merchant is charging for
                        similar
                        Items through any comparable platform.
                    </div>

                    <h3 className={'terms-header'}>
                        6.4 Item Responsibility
                    </h3>

                    <div className={'terms-text'}>
                        Merchant acknowledges and agrees that neither Morewards nor any person other than the Consumer
                        takes title to any purchased Item at any time. Merchant shall be responsible for any
                        reimbursement costs
                        related to Consumer refunds for substandard Items or other related issues within your control
                        (including
                        any costs associated with retrieving any substandard or otherwise unsatisfactory Items),
                        including
                        missing, incomplete, or defective Items. You agree that Morewards will not provide any delivery
                        services
                        and shall make no claim to offer delivery services to Consumers.
                    </div>

                    <h3 className={'terms-header'}>
                        <span className={'terms-red-text bold'}>6.5 Item Returns</span>
                        <span className={'terms-red-text'}>, Refunds and Chargebacks</span>
                    </h3>

                    <div className={'terms-text'}>
                        <span className={'terms-red-text'}>
                            Merchant is responsible for processing Item returns. When a Consumer purchases an Item from
                            Merchant and the Item is returned or the transaction is refunded, the money will be sent
                            back to theConsumer. The corresponding Reward will be deducted from the Consumers Reward
                            Balance. Thereturn or refund transaction will be reversed, and your Merchant Balance will
                            be adjusted to reflect the transaction.
                        </span>
                    </div>

                    <div className={'terms-text'}>
                        <span className={'terms-red-text'}>
                            There may be times when your customer may not be the authorized user of a Reward Card; or your
                            customer may otherwise contest a transaction. In these instances, the amount of a transaction and the
                            corresponding Consumer Reward may be reversed (“Chargeback”) if the transaction (a) is disputed, (b)
                            is reversed for any reason by a processor, or a customer or its financial institution, (c) was not authorized
                            or we have any reason to believe that the transaction was not authorized, or (d) is allegedly unlawful,
                            suspicious, or in violation of the these Terms. You agree to comply with the Chargeback process. You will
                            assist us when requested, at your expense, to investigate any of your transactions processed through the
                            Network. To that end, you permit us to share information about a Chargeback with the Consumer, the
                            Consumer’s financial institution, and your financial institution in order to investigate and/or mediate a
                            Chargeback. We will request necessary information from you to contest the Chargeback. We may also
                            use any content you upload through the Services to respond to Chargebacks on your behalf. If the
                            Chargeback is contested successfully, we will replace corresponding Reward funds to your Account. You
                            acknowledge that your failure to assist us in a timely manner when investigating a transaction, including
                            providing necessary documentation within fifteen (15) days of our request, may result in an irreversible
                            Reward grant. We reserve the right, upon notice to you, to charge a fee for mediating and/or investigating
                            Chargeback disputes.
                        </span>
                    </div>

                    <h3 className={'terms-header'}>
                        6.6 Devices.
                    </h3>

                    <div className={'terms-text'}>
                        If Morewards supplies a mobile, tablet, scanner, or other hardware device (“
                        <span className={'terms-bold-text'}>Device</span>
                        ”) to Merchant to use in connection with the generation of Rewards for Item purchases, you agree
                        that: (i) Device(s) may only
                        be used for the purpose of processing Rewards, via the Tools and (ii) Device(s) may not be
                        transferred,
                        loaned, sold or otherwise provided in any manner to any third party. Devices(s) will at all
                        times remain the
                        property of Morewards, and upon expiration or termination of the Agreement, or the extended
                        absence of
                        all of Merchant’s location(s) from the Morewards Network for longer than thirty (30) days,
                        Merchant will
                        return all applicable Device(s) to Morewards within ten (10) days. If Merchant receives a
                        wireless data
                        plan for the Device, Morewards may require a weekly reimbursement from Merchant for the costs
                        associated with the wireless data plan of each applicable Device. Merchant agrees that the loss
                        or theft of
                        a Device, the failure to timely return a Device, or any damage to a Device outside of normal
                        wear and
                        tear, may result in a fee (“
                        <span className={'terms-bold-text'}>Damage Fee</span>”).
                    </div>

                    <h3 className={'terms-header'}>
                        6.7 Morewards Network Use Restrictions, No Development
                    </h3>

                    <div className={'terms-text'}>
                        In connection with the access to and use of the Network, you will not (and will not allow any
                        third party to):
                        (i) reverse engineer or attempt to discover any source code or underlying ideas or algorithms
                        used to
                        provide our Services and Tools (except to the extent applicable law prohibits reverse
                        engineering
                        restrictions); (ii) provide, lease, lend, disclose, or otherwise use or allow others to use, in
                        each case, for
                        the direct benefit of any third party, the Morewards Tools or Services (except as otherwise
                        authorized by
                        us); or (iii) possess or use, or allow the transfer, transmission, export, or re-export of any
                        software or
                        portion thereof in violation of any export control laws or regulations administered by the U.S.
                        Commerce
                        Department, U.S. Treasury Department's Office of Foreign Assets Control, or any other government
                        agency or (iv) distribute viruses or other harmful computer code through the Tools and Services;
                        or
                        otherwise use the Network in any manner that exceeds the scope of use granted in
                        <span className={'terms-red-text'}> Section 5.1 </span>
                        above.
                        You will not (and will not allow any third party to) use our Network or any other transactional,
                        operational,
                        performance or other data or information that is related to the sale of Items to Consumers
                        through the
                        Network to directly or indirectly compete with us or the Services. Each party acknowledges and
                        agrees
                        that there shall be no development of technology, content, media or other intellectual property
                        by either
                        party for the other party pursuant to this agreement. Any development activities relating to any
                        technology, content, media or other intellectual property must be the subject of a separate
                        written
                        agreement between Merchant and Morewards prior to the commencement of any such activities.
                    </div>

                    <div className={'terms-text'}>
                        You agree that you will use the Network for lawful purposes only. Your failure to do so may
                        subject you to
                        civil or criminal liability or both, and to termination of your privilege to utilize the
                        Network. You are
                        responsible for understanding and following the applicable laws and regulations in your
                        jurisdiction. You
                        agree that you will not engage in any activity that, in our sole discretion, disrupts, or is
                        harmful to our
                        reputation, goodwill, business, operations, Consumers or other Merchants.
                    </div>

                    <h3 className={'terms-header'}>
                        6.8 Rewards Restrictions, Limitations and Prohibited Activities
                    </h3>

                    <div className={'terms-text'}>
                        There are certain restrictions in connection with a Consumer ‘s use of their Rewards Card. These
                        restrictions also apply to Merchants. If you engage in these activities, we may terminate your
                        privilege to
                        utilize the Network, or may take further action on your Account.
                    </div>

                    <ul className={'terms-list'}>
                        <li>
                            Rewards may only be redeemed toward the purchase of eligible items provided by authorized
                            Morewards Merchants. Eligible items and authorized Merchants are subject to change.
                        </li>

                        <li>
                            A Consumer Rewards Balance cannot be used to purchase any other reward cards or any third-
                            party rewards of any kind.
                        </li>

                        <li>
                            Rewards cannot be reloaded; resold; exchanged; used for payment outside of authorized
                            Morewards Merchants; used for unauthorized advertising, marketing, sweepstakes, promotional
                            or commercial purposes, including to facilitate the resale or shipment of Items from
                            Merchants.
                        </li>

                        <li>
                            Rewards cannot be used for any illegal or unauthorized purpose.
                        </li>

                        <li>
                            Rewards cannot be used to purchase certain ineligible Items such as collectible coins,
                            <span className={'terms-red-text'}> alcohol, tobacco, firearms</span>
                            , money orders, money transfers, lottery items, and items containing
                            endangered species.
                        </li>

                        <li>
                            Consumers must provide a valid method of payment for a purchase that includes any ineligible
                            items.
                        </li>

                        <li>
                            Rewards cannot be used to pay tips or gratuities of any kind.
                        </li>

                        <li>
                            Rewards must be redeemed in the currency designated in the account, and provided that the
                            Merchant accepts that designated currency.
                        </li>

                        <li>
                            Rewards cannot be redeemed for more than face value; transferred for value; redeemed for
                            cash;
                            returned for a cash refund (except to the extent required by law).
                        </li>

                        <li>
                            No portion of a Rewards Balance may be transferred to another account.
                        </li>

                        <li>
                            Rewards Balance and Rewards Dollars may not be applied to the purchase of Items at any
                            property outside of the United States.
                        </li>

                        <li>
                            The Items eligible for purchase with our Rewards Dollars are subject to change in our sole
                            discretion.
                        </li>

                        <li>
                            Rewards cannot be redeemed to an account if the consumer is not the intended recipient.
                        </li>
                    </ul>

                    <div className={'terms-text'}>
                        The list of prohibited activities above is not intended to be a complete or exhaustive list of
                        all prohibited
                        activities. Engaging in any prohibited activities or using the Network in violation of these
                        Terms may result
                        in our taking action(s) against you, including, but not limited to, preventing you from
                        transferring
                        Consumer Rewards or using the Network, suspending, or closing your Account consistent with these
                        Terms.
                    </div>

                    <h3 className={'terms-header'}>
                        6.9 Merchant Fees
                    </h3>

                    <ul className={'terms-list'}>
                        <li>
                            <h3 className={'terms-header'}>Activation Fee</h3>
                            <div className={'terms-text'}>
                                Unless otherwise mutually agreed to by Morewards and Merchant, you will pay to us a one-time
                                activation fee of $1200.00 ("
                                <span className={'terms-bold-text'}>Activation Fee</span>
                                "). A portion of the Activation Fee will generate MOR
                                Dollars to your Merchant Rewards Account and the remainder of the fee (less any administrative
                                charges) will generate MOR Dollars allocated to participating Consumer Reward Balances.
                                Payment shall be by credit, debit, or Automatic Clearing House (ACH) transfer defined in this
                                Section below.
                            </div>
                        </li>

                        <li>
                            <h3 className={'terms-header'}>
                                Merchant Rewards Account Balances, Threshold Limit Transfers, Payments & Fees
                            </h3>
                            <div className={'terms-text'}>
                                You must maintain a minimum prepaid Account Balance sufficient to process Consumer Rewards
                                for a
                                <span className={'terms-red-text'}> one-week period</span>
                                . Merchant may not use the Services unless a sufficient balance is
                                maintained. An Account analysis is performed on all new accounts 30 days from the first
                                Rewards use and every 90 days thereafter. If your monthly balance is consistently above
                                or below your prepaid amount, Morewards will adjust your prepaid amount to a more
                                approximate
                                <span className={'terms-red-text'}> one-week </span>
                                level of activity. Morewards and Merchant mutually agree that Merchant Account Balances
                                will be maintained per schedule as follows:

                                <ul className={'terms-list circle'}>
                                    <li>
                                        If your Account Balance rises above a specified MOR Reward Dollar threshold:
                                        Morewards will withdraw MOR Dollars from your Account in exchange for cash payment
                                        (a “
                                        <span className={'terms-bold-text'}>Payment</span>
                                        ”) to Merchant. Withdrawal amounts will vary based on Merchant Account
                                        activity. Morewards will pay you one ($1) US dollar for each one (1) MOR Reward Dollar
                                        transferred less any applicable administrative fees incurred pursuant to these Terms. You
                                        will be paid by Automatic Clearing House (ACH) credit transfer from Morewards to your
                                        Merchant bank account.
                                    </li>

                                    <li>
                                        <div className={'terms-text'}>
                                            If your Account Balance falls below a specified MOR Reward Dollar threshold: your
                                            Account must be replenished (a “
                                            <span className={'terms-bold-text'}>Replenishment</span>
                                            ”) by a prepaid amount when your
                                            Account Balance decreases to or below the replenishment point specific to your Account
                                            activity. The date on which your Account will be replenished will vary based upon account
                                            activity. You agree and are aware that you are pre-authorizing Morewards to withdraw
                                            funds from your Merchant bank account each time your Account Balance decreases to or
                                            below the replenishment point specific to your Account. Morewards will add MOR Reward
                                            Dollars to Merchant Balance to replenish the Account Balance. Morewards will withdraw
                                            one ($1) US dollar for each one (1) MOR Reward Dollar transferred less any applicable
                                            administrative fees incurred pursuant to these Terms. Withdrawals are made by
                                            Automatic Clearing House (ACH) debit transfer from Merchant to Morewards bank
                                            account.
                                        </div>

                                        <div className={'terms-text'}>
                                            You agree and are aware that the amount of the ACH withdrawals from your checking
                                            account to maintain your Account Balance may vary between
                                            <span className={'terms-red-text'}> $0.01 and $3,000.00, </span>
                                            depending upon your Rewards Account activity.
                                        </div>

                                        <div className={'terms-text'}>
                                            Merchant authorizes Account Replenishment by automatic withdrawal from Merchant
                                            checking account (ACH transfer). Merchant authorizes Morewards to debit Merchant
                                            Account for the charges incurred. You grant us a security interest in, as well as a right of
                                            setoff against, and assign, convey, deliver, pledge and transfer to us, as security for
                                            repayment of any obligations due under these Terms and any other agreement you have
                                            with us, all of your right, title, and interest in your account with us. You grant us Recovery
                                            Authorizations concerning funds you are obligated to pay us. You authorize us to
                                            administratively freeze or direct any third-party bank holding the account to freeze all
                                            such accounts to allow us to protect our security interest, collection, charge and setoff
                                            rights as provided for in this Section. Your failure to fully pay amounts that you owe us on
                                            demand will be a breach of these Terms. You are liable for any of our costs associated
                                            with collection in addition to any amounts owed, including attorneys’ fees and expenses,
                                            collection agency fees, and any applicable interest.
                                        </div>

                                    </li>
                                </ul>
                            </div>

                            <div className={'terms-text'}>
                                Morewards, in its discretion, may receive updated Merchant banking information including credit
                                card information, routing numbers, account numbers, and account type, from Merchant or
                                Merchant financial institution. Morewards, in its discretion, may receive updated information about
                                Merchant Location from the United States Postal Service or other service. Your Account may be
                                suspended for failure to pay charges or insufficient funds. No interest will be paid on Balances in
                                your Account.
                            </div>

                            <div className={'terms-text'}>
                                You will receive a periodic Account statement unless there are no transactions and no financial
                                activity in your Account during the applicable period covered by such statement. It is your
                                obligation to review such statements and follow the
                                <span className={'terms-red-text'}> procedure in Section 10 </span>
                                if you wish to dispute
                                any balances or charges. You will be charged a fee for any request to retrieve a statement
                                previously provided. Depending on usage or other charges to your Account, there may be more
                                than one replenishment transaction within one statement period.
                            </div>

                            <div className={'terms-text'}>
                                <span className={'terms-red-text'}>
                                    If you do not process Rewards through your Morewards Account for an extended period of time
                                    or have not linked a valid bank account, you may have a Balance that is deemed “unclaimed” or
                                    “abandoned” under your state’s law. If this occurs, Morewards will provide you with notice as
                                    required by applicable law and instructions for how to transfer your Balance. If funds still remain
                                    in your Morewards Account, Morewards will escheat such funds as required by applicable law or,
                                    as permitted, to Morewards.
                                </span>
                            </div>
                        </li>

                        <li>
                            <h3 className={'terms-header'}>Sales Transaction Fee, Calculation</h3>
                            <div className={'terms-text'}>
                                For each Item sold by Merchant via the Network, Merchant will pay to Morewards a sales
                                commission as follows: The Total price of the Item or Items sold multiplied by the applicable fee
                                percentage (“
                                <span className={'terms-bold-text'}>Transaction Fee</span>
                                ”).  For clarity, the Total Price of the Item or Items is the Retail
                                Price plus any Sales Tax (as defined in section 6.1) collected by Merchant. Unless otherwise
                                agreed to by the parties or modified by requirement of applicable laws or regulations, Morewards
                                will charge Merchant a Transaction Fee of ten percent (10%) of the Total Price for each Item or
                                Items sold through the Network. The Transaction Fee will be assessed by deduction from the
                                Merchant Account Balance so that for each ten US dollars ($10) of Item sales, your Account
                                balance will decrease by one (1) MOR Dollar as described in Section 6.9 above. If required by
                                applicable law or regulation, Morewards may adjust the Transaction Fee. Such adjustment may
                                apply only to certain Items. Morewards reserves the right to suspend Merchant’s ability to make
                                Items available for purchase by Consumers through the Network if Merchant’s account is in
                                arrears. If a Consumer purchases Item(s) from you and requests Rewards on the Item purchase,
                                you must process such Rewards and you are responsible for the Transaction Fee. A portion of
                                the Transaction Fee will Reward Consumers with MOR Rewards Dollars redeemable at any
                                authorized Morewards Merchant.
                            </div>
                        </li>
                    </ul>

                    <h3 className={'terms-header'}>6.10 Cash Payment Schedule</h3>

                    <div className={'terms-text'}>
                        We will automatically initiate a payment to your valid, linked US bank account at the end of the business
                        day before 8 p.m. ET (5 p.m. PT), except on Friday. Friday Payments are initiated the following Sunday
                        before 8 p.m. ET (5 p.m. PT)). If you adjust your business’s close of day to a custom time, Morewards will
                        group Payments for the 24-hour period preceding your custom time and initiate a payout at the end of that
                        business day. Morewards will initiate a Payment on non-business days before the next business day. We
                        will settle Account Payments to your verified bank account or other account approved by Morewards,
                        subject to the Payment schedule described in Section 6.9 above. If Morewards is not able to debit or
                        credit the bank account you link to your Morewards Account, that bank account will be de-linked from
                        your Morewards Account. You must link an ACH-enabled bank account to your Morewards Account in
                        order to receive your Payment
                    </div>

                    <div className={'terms-text'}>
                        We may defer Payment or restrict access to your Payment if we need to conduct an investigation or
                        resolve any pending dispute related to your use of the Network. We may also defer payout or restrict
                        access to your Payments as necessary to comply with applicable law or court order, or if requested by a
                        governmental entity.
                    </div>

                    <div className={'terms-text'}>
                        When a Payment is made to your Account, we will update your Account activity and provide you a
                        transaction confirmation. The confirmation will serve as your receipt. Summaries of your Account activity,
                        including
                        <span className={'terms-red-text'}> monthly statements</span>
                        , are available on our website for up to one year of account activity. Except
                        as required by law, you are solely responsible for (a) compiling and retaining records of all transactions
                        and other data associated with your Account and your use of the Network, and (b) reconciling all
                        transactional information that is associated with your Account. If you believe that there is an error or
                        unauthorized transaction activity is associated with your Account, you must contact us immediately.
                    </div>

                    <div className={'terms-text'}>
                        While you have a Balance in your Account, funds for your Payments will be combined and held by us with
                        other merchants’ funds as an unsecured claim against us. The funds will be held in one or more pooled
                        custodial accounts, at one or more banks on your behalf and for the benefit of you and others holding
                        Balances (each a “Pooled Payment Account”). We have sole discretion over the establishment and
                        maintenance of any Pooled Payment Account.
                        <span className={'terms-red-text'}> We will not voluntarily make funds available to our
                        creditors in the event of bankruptcy or for any other purpose.</span> You will not receive interest or any other
                        earnings on any funds that we handle. As consideration for using the Network, you irrevocably assign to
                        us all rights and legal interests to any interest and/or other earnings that may accrue or are attributable to
                        our holding of your Payment funds in a Pooled Payment Account.
                    </div>

                    <h3 className={'terms-header'}>
                        6.11 Marks
                    </h3>

                    <div className={'terms-text'}>
                        Subject to these terms, each party hereby grants to the other party a limited, non-exclusive and non-
                        transferable license during the Term to use such party’s respective Marks (defined below) in the territory,
                        on a royalty-free basis, in connection with the activities related to these Terms or any other activities
                        relating to the Services and Tools. For purposes of these Terms, the term “
                        <span className={'terms-bold-text'}>Marks</span>
                        ” will mean the
                        trademarks, service marks, trade names, copyrights, logos, slogans, content, media, materials, identifying
                        symbols and indicia of the applicable party. All uses of a party’s Marks by the other party will be in the
                        form and format specified or approved by the owner of such marks. Other than as specifically set forth in
                        these Terms, neither party will use the other party’s Marks without the prior, express, written consent of
                        the other party (by email is sufficient). All goodwill related to the use of a party’s Marks by the other party
                        will inure to the benefit of the owner of such Marks. Except as expressly set forth herein, neither party will
                        be deemed to grant the other party any license or rights under any intellectual property or other
                        proprietary rights. All rights not granted are expressly reserved. Without limiting anything in these Terms,
                        Merchant represents and warrants that Merchant’s Marks do not infringe, misappropriate, or otherwise
                        violate any third party’s intellectual property or other proprietary rights. Merchant agrees that Morewards
                        may remove your Marks from the Services and Tools if we receive notice or otherwise reasonably believe
                        that such Marks may infringe, misappropriate, or otherwise violate any intellectual property or other
                        proprietary rights.
                    </div>

                    <h3 className={'terms-header'}>
                        6.12 Marketing
                    </h3>

                    <div className={'terms-text'}>
                        Morewards may showcase a Merchant’s offerings via the Services and Tools through various promotional
                        activities (e.g., through social media channels, websites, advertisements, or blogs).  Morewards (or a
                        party designated by acting on Morewards behalf) may take video and still images for marketing and other
                        efforts related to the Network (“
                        <span className={'terms-bold-text'}>Photographs</span>
                        ”). Merchant agrees that Photographs (including all
                        intellectual property rights therein) are and will remain the sole and exclusive property of Morewards.
                        Additionally, you may provide videos, still image or other materials to us (“
                        <span className={'terms-bold-text'}>Merchant Marketing Materials</span>
                        ”) for use in connection with the display of your Items on the Services and Tools or the
                        marketing and promotion of Morewards. You hereby grant a non-exclusive, perpetual, fully paid-up and
                        royalty free license to use and display such Merchant Marketing Materials in connection with your Items
                        and other promotional activities relating to the Network. Without limiting anything in these Terms, you
                        represent and warrant that the Merchant Marketing Materials do not infringe, misappropriate, or otherwise
                        violate any third party’s intellectual property or other proprietary rights. To the extent that the Merchant
                        Marketing Materials contain any third-party materials, you are solely responsible for and will secure any
                        and all rights, licenses, consents and permissions necessary to be able use the Merchant Marketing
                        Materials in accordance with this Section. You agree that we may remove Merchant Marketing Materials
                        from our Network if Morewards receives notice or otherwise reasonably believes that such Merchant
                        Marketing Materials may infringe, misappropriate, or otherwise violate any intellectual property or other
                        proprietary rights.
                    </div>

                    <h3 className={'terms-header'}>
                        6.13 Merchant Promotions and Merchant MOR Rewards Promotions
                    </h3>

                    <div className={'terms-text'}>
                        “<span className={'terms-bold-text'}>Merchant Promotion(s)</span>
                        ” means short-term offers that are available to consumers to stimulate consumer
                        demand. Promotional offerings on Merchant items are the sole responsibility of Merchant and must be
                        processed independently. When a Merchant Promotion is successfully applied, Merchant will enter the
                        Item charge to Consumers for the post-Promotional value of an Item (including Sales Taxes and
                        applicable fees).
                    </div>

                    <div className={'terms-text'}>
                        “<span className={'terms-bold-text'}>Merchant MOR Rewards Promotion(s)</span>
                        ” means short-term offers of additional MOR Rewards that are
                        available to Consumers to stimulate Consumer demand. Merchant MOR Rewards Promotion(s) may be
                        available for Items if arranged and mutually agreed to by both parties in advance.
                    </div>

                    <h3 className={'terms-header'}>6.14 Publicity</h3>

                    <div className={'terms-text'}>
                        Except as may be expressly set forth in these Terms or otherwise agreed by the parties in writing, neither
                        party may issue a press release or otherwise refer to the other party in any manner with respect to these
                        Terms or otherwise, without the prior written consent of such other party.
                    </div>

                    <h3 className={'terms-header'}>6.15 Proprietary information </h3>

                    <div className={'terms-text'}>
                        “<span className={'terms-bold-text'}>Proprietary Information</span>
                        ” means any confidential, proprietary or other non-public information disclosed
                        by or on behalf of one party (“
                        <span className={'terms-bold-text'}>Discloser</span>
                        ”) to the other (“
                        <span className={'terms-bold-text'}>Recipient</span>
                        ”), whether disclosed verbally, in writing,
                        or by inspection of tangible objects, and includes transactional, operational, performance and other data
                        or information that is related to the sale of Merchant’s Items to Consumers through the Network, and
                        these Terms. Proprietary Information will not include information that: (i) was previously known to the
                        Recipient without an obligation of confidentiality; (ii) was acquired by the Recipient without any obligation
                        of confidentiality from a third party with the right to make such disclosure; or (iii) is or becomes publicly
                        available through no fault of the Recipient. Each Recipient agrees that it will not disclose to any third
                        parties other than Representatives (defined below) or use in any way other than as necessary to perform
                        these Terms, the Discloser’s Proprietary Information. Each Recipient will ensure that Proprietary
                        Information will only be made available to Recipient’s affiliates and Recipient’s and Recipient’s affiliates
                        officers, directors, employees and agents who have a need to know such Proprietary Information and
                        who, prior to any disclosure of such Proprietary Information, are bound by written obligations of
                        confidentiality with respect to such Proprietary Information that are no less stringent than those set forth in
                        these Terms (each, a “
                        <span className={'terms-bold-text'}>Representative</span>
                        ”). Recipient will cause its Representatives to comply with these
                        Terms and will be solely responsible for any breach of these Terms by any of its Representatives. Each
                        Recipient will not, and will not authorize others to, remove or deface any notice of copyright, trademark,
                        logo, legend, or other notices of ownership from any originals or copies of the Discloser’s Proprietary
                        Information. The foregoing prohibition on use and disclosure of Proprietary Information will not apply to
                        the extent: (i) the Discloser has authorized such use or disclosure (and Merchant hereby authorizes
                        Morewards to disclose the Terms to Merchant’s franchisees and/or franchisor as applicable in connection
                        with executing contracts that reference these Terms) and (ii) a Recipient is required to disclose certain
                        Proprietary Information of the Discloser as a matter of law or by order of a court, provided that the
                        Recipient gives the Discloser prior written notice of such obligation to disclose and reasonably assist in
                        obtaining a protective order prior to making such disclosure. Upon expiration or termination of these
                        Terms and as requested by Discloser, each Recipient will deliver to the Discloser (or destroy at the
                        Discloser’s election) any and all materials or documents containing the Discloser’s Proprietary
                        Information, together with all copies thereof in whatever form.
                    </div>

                    <h3 className={'terms-header'}>6.16 Personal Data and Privacy</h3>

                    <div className={'terms-text'}>
                        Consumer and Merchant privacy is vital to the success of the Morewards Network. Unless required by
                        law, Consumer data and Merchant data will not be shared or sold to third parties. Morewards complies
                        with applicable federal privacy and security laws and maintains safeguards to protect the security of your
                        Personal Data. Additionally, the information you make available to us is confidential, to the extent
                        protected by applicable laws. We devote considerable effort toward ensuring that your Account
                        Information communicated through the Tools and Services is secure. Details regarding our use of your
                        information are provided in our Privacy Policy, -
                        <span className={'terms-red-text'}>add link- </span>
                        and your acceptance of these Terms constitutes
                        an acceptance of our Privacy Policy.
                    </div>

                    <div className={'terms-text'}>
                        Merchant agrees to use, disclose, store, retain or otherwise process Consumer Personal Data solely for
                        the purpose of providing Items and Rewards under these Terms. Merchant will maintain the accuracy and
                        integrity of any Personal Data provided by and in Merchant’s possession, custody or control. Merchant
                        agrees to retain Personal Data provided to Merchant by Morewards solely by using the Network provided
                        by Morewards. “
                        <span className={'terms-bold-text'}>Personal Data</span>
                        ” means any information obtained in connection with these Terms (i)
                        relating to an identified or identifiable natural person; (ii) that can reasonably be used to identify or
                        authenticate an individual, including name, contact information, precise location information, persistent
                        identifiers, and (iii) any information that may otherwise be considered “personal data” or “personal
                        information” under the applicable law. -
                        <span className={'terms-red-text'}>put in Security level language here-</span>
                    </div>

                    <h3 className={'terms-header'}>6.17 Data Re-Identification Restriction</h3>

                    <div className={'terms-text'}>
                        Without limiting any other provision of these Terms, including any provision in this Section 6, Merchant
                        will not merge any of the data collected or otherwise obtained in connection with these Terms, including
                        any Personal Data, with other data collected from any source or otherwise use any of the data collected
                        or otherwise obtained in connection with these Terms, including any Personal Data, for the purpose of re-
                        identification, targeted marketing, or any other similar purpose.
                    </div>

                    <h3 className={'terms-header'}>6.18 Feedback</h3>

                    <div className={'terms-text'}>
                        Merchant may, but is not obligated to, provide or otherwise make available to Morewards certain
                        feedback, suggestions, comments, ideas, or other concepts relating to our Network (“
                        <span className={'terms-bold-text'}>Feedback</span>”).
                        However, to the extent that you provide or otherwise make Feedback available, you hereby grant to
                        Morewards a perpetual, irrevocable, worldwide, royalty free, fully sublicensable right to use and otherwise
                        exploit such Feedback.
                    </div>

                    <h3 className={'terms-header'}>6.19 Consumer Ratings, Reviews, and Reports</h3>

                    <div className={'terms-text'}>
                        Merchant acknowledges and agrees that, after receiving Item(s), a Consumer may be prompted by the
                        Morewards Tools to provide a rating of such Item(s), and/or experience with Merchant (“
                        <span className={'terms-bold-text'}>Reviews</span>”). And,
                        at such Consumer’s option, to provide comments, feedback, or report issues related to the Consumer’s
                        experience with Merchant and the relevant Item(s) (“
                        <span className={'terms-bold-text'}>Consumer Feedback</span>”). Morewards reserves the
                        right to use, share, and display Consumer Feedback in any manner in connection with the Merchant
                        without attribution to or approval of Merchant. You acknowledge that Morewards is a distributor (without
                        any obligation to verify) and not a publisher of Consumer Feedback, provided that we reserve the right to
                        edit or remove comments in the event that such comments include obscenities or other objectionable
                        content, include an individual’s name or other Personal Data, violate any privacy or other applicable laws,
                        or our content policies.
                    </div>

                    <h3 className={'terms-header'}>6.20 Use of Morewards Network in Violation of these Terms and Conditions</h3>

                    <div className={'terms-text'}>
                        By using our Tools and Services, you agree to comply with these Terms, and not to use the Network in
                        any manner that is misleading, deceptive, unfair, or otherwise harmful to Morewards. We reserve the
                        right, without notice to you, to suspend or terminate your Account, suspend or terminate the ability to use
                        the Network, cancel or limit Reward transactions, and bill alternative forms of payment if we suspect that
                        Rewards are added, obtained, used, or applied to a Rewards Account fraudulently, unlawfully, or
                        otherwise in violation of these Terms.
                    </div>

                    <h3 className={'terms-header'}>7. Representations, Warranties, and Disclaimer</h3>

                    <div className={'terms-text'}>
                        You agree that use of the Network is at your sole risk. The Network is provided on an "as is" and "as
                        available" basis. Moreover, Morewards does not warrant that access to or use of the Network will be
                        uninterrupted or that defects in the Tools and Services will be corrected. To the full extent permissible by
                        law, we make no warranties, express or implied, with respect to the Network or your Account Balance.
                    </div>

                    <div className={'terms-text'}>
                        Each party hereby represents and warrants that: (i) it has full power and authority to enter into these
                        Terms and perform its obligations hereunder; (ii) it is duly organized, validly existing and in good standing
                        under the laws of the jurisdiction of its origin; (iii) it has not entered into, and during the Term will not enter
                        into, any agreement that would prevent it from complying with or performing under these Terms; (iv) it will
                        comply with all applicable laws and regulations in the performance of these Terms and any activities
                        hereunder (including all applicable consumer protection, data protection and privacy laws. In addition,
                        Merchant further represents and warrants that to the extent you have franchisees who participate in any
                        activities under these Terms, you will ensure that such franchisees will comply with, and be subject to, the
                        applicable provisions of these Terms when participating in such activities.
                    </div>

                    <div className={'terms-text'}>
                        Each party makes no representations, and hereby disclaims all warranties, express or implied, regarding
                        its services or Items or any portion thereof, including any implied warranty of merchantability or fitness for
                        a particular use or purpose, non-infringement, title, operability, condition, quiet enjoyment, value,
                        accuracy of data and system integration.
                    </div>

                    <h3 className={'terms-header'}>8. Indemnification</h3>

                    <div className={'terms-text'}>
                        Each party agrees to indemnify, defend and hold harmless the other, its officers, directors, employees,
                        agents, franchisees, subsidiaries, affiliates, licensors, and suppliers, harmless from and against any
                        claim, actions, demands, liabilities and settlements, including without limitation reasonable legal and
                        accounting fees (“
                        <span className={'terms-bold-text'}>Claims</span>
                        ”), resulting from, or alleged to result from, your violation of these Terms. Each
                        party will provide the other prompt written notice of any potential claim subject to indemnification
                        hereunder. The indemnifying party will assume the defense of the claim through counsel, however, such
                        counsel must be reasonably acceptable to the indemnified party. The indemnifying party will not settle or
                        compromise any claim, or consent to the entry of any judgment, without written consent of the indemnified
                        party, which will not be unreasonably withheld. The indemnified party will reasonably cooperate with the
                        indemnifying party in the defense of a claim, at indemnifying party’s expense.
                    </div>

                    <h3 className={'terms-header'}>9. Limitation of Liability</h3>

                    <div className={'terms-text'}>
                        You understand that to the extent permitted under applicable law, in no event will Morewards or our
                        officers, employees, directors, parents, subsidiaries, affiliates, agents or licensors be liable for any
                        indirect, incidental, special, consequential, punitive, or exemplary damages, including but not limited to,
                        damages for loss of goodwill, use, data or other intangible losses arising out of or related to your use of
                        the Network, regardless of whether such damages are based on contract, tort (including negligence and
                        strict liability), warranty, statute or otherwise. We do not provide any warranties against viruses, spyware
                        or malware that may be installed on your device. The maximum liability of Morewards to you with respect
                        to your use of this site shall be
                        <span className={'terms-red-text'}> one hundred thousand dollars ($100,000). </span>
                        To the extent that we may not,
                        as a matter of applicable law, limit its liabilities, the extent of our liability will be the minimum permitted
                        under such applicable law.
                    </div>

                    <h3 className={'terms-header'}>10. Disputes and Dispute Resolution</h3>

                    <div className={'terms-text'}>
                        You may share your concerns through
                        <a className={'terms-link'} href={'mailto:support@morewards.com'}> support@morewards.com.</a>
                    </div>

                    <div className={'terms-text'}>
                        Any dispute or claim relating in any way to your Consumer Account will be resolved by binding arbitration,
                        rather than in court, except that you may assert claims in small claims court if your claims qualify. The
                        U.S. Federal Arbitration Act (“
                        <span className={'terms-bold-text'}>FAA</span>
                        ”) and U.S. federal arbitration law apply to this agreement. There is no
                        judge or jury in arbitration, and court review of an arbitration award is limited. To begin an arbitration
                        proceeding, you must send a letter requesting arbitration and describing your claim to our registered
                        agent: ____________.
                    </div>

                    <div className={'terms-text'}>
                        To the extent permitted by applicable law, the parties agree to keep all materials related to the dispute,
                        including the existence of the dispute itself, content of the arbitration, and all the submissions by the
                        parties in the arbitration and awards rendered by the arbitral tribunal, confidential. Each party hereby
                        waives to the fullest extent permitted by applicable law, any right it may have to a trial by jury of any
                        arbitrable claim under these Terms and in connection with the enforcement of an arbitral award rendered
                        pursuant to this agreement
                    </div>

                    <div className={'terms-text'}>
                        Each party shall pay its own proportionate share of Arbitrator fees and expenses. Notwithstanding any
                        choice of law or other provision in these Terms, the parties agree and acknowledge that these Terms
                        evidence a transaction involving interstate commerce and that the Federal Arbitration Act, 9 U.S.C. § 1 et
                        seq. will govern its interpretation, enforcement and proceedings. It is the intent of the parties that the FAA
                        shall preempt all state laws to the fullest extent permitted by law. If the FAA Rules are found to not apply
                        to any issue that arises under these Terms or the enforcement thereof, then that issue shall be resolved
                        under the laws of the state of Connecticut.
                    </div>

                    <div className={'terms-text'}>
                        Neither party may bring any class, collective, or representative action against the other party, and will
                        preclude a party from participating in or recovering relief under any current or future class, collective,
                        consolidated, or representative action brought against the other party by someone else.
                        The parties each retain the right to seek injunctive or other equitable relief in a court of competent
                        jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party’s
                        copyrights, trademarks, trade secrets, patents or other intellectual property rights.
                    </div>

                    <h3 className={'terms-header'}>11. Assignment</h3>

                    <div className={'terms-text'}>
                        Merchant may not be assign, transfer, delegate or subcontract, in whole or in part without the prior written
                        consent of Morewards, provided that Morewards may assign these Terms, upon written notice to the
                        Merchant, (a) to an affiliate of Merchant, or (b) in connection with the sale of all or substantially all of such
                        Merchant’s equity, business or assets to which these Terms relate; provided that in the event of any such
                        transfer by Merchant, Merchant explicitly consents that any such transferee will have access to and
                        control of all Merchant Accounts related to such transfer, including its Accounts with us, access to
                        historical reporting information about Items related to such transfer, and other Account data relating to
                        such transfer. In the event of a change of ownership involving Merchant’s Location(s), the parties will
                        need to execute a Change of Ownership form and Merchant acknowledges and agrees that the Location
                        will not be able to accept or process any Rewards on the Network until the Change of Ownership is
                        executed. Subject to the foregoing, these Terms will be binding upon and will inure to the benefit of
                        Merchant and its respective successors and assigns. Any purported assignment, transfer, delegation or
                        subcontract in violation of this Section will be null and void.
                    </div>

                    <h3 className={'terms-header'}>12. Force Majeure</h3>

                    <div className={'terms-text'}>
                        We will not be deemed to be in breach of these Terms or liable for any breach of these Terms or our
                        Privacy Policy due to any event or occurrence beyond our reasonable control, including without limitation,
                        acts of God, riots, terrorism, war, invasion, failures of any public networks, electrical shortages,
                        earthquakes or floods, civil disorder, strikes, fire or other disaster.
                    </div>

                    <h3 className={'terms-header'}>13. Linked Locations </h3>

                    <div className={'terms-text'}>
                        We will not be liable for any information, software, or links found at any other website, internet location, or
                        source of information, nor for your use of such information, software or links, nor for the acts or omissions
                        of any such websites or their respective operators.
                    </div>

                    <h3 className={'terms-header'}>14. Term and Termination</h3>

                    <div className={'terms-text'}>
                        This Agreement will commence on the Effective Date and, unless earlier terminated as provided below,
                        will continue for a period of one (1) year from the Effective Date (“
                        <span className={'terms-bold-text'}>Initial Term</span>
                        ”) and will automatically
                        renew for successive one (1) year periods (each, a “
                        <span className={'terms-bold-text'}>Renewal Term</span>
                        ” and together with the Initial Term, the “
                        <span className={'terms-bold-text'}>Term</span>
                        ”). Morewards will not charge Merchant an activation fee (described in Section above) for any
                        Renewal Term. Either party may terminate these Terms in the event of a material breach by the other
                        party with two (2) days’ prior written notice thereof by the non-breaching party. Either party may terminate
                        these Terms at any time without cause by giving sixty (60) days’ prior written notice of termination to the
                        other party. In addition, Morewards may suspend or otherwise terminate these Terms on written notice in
                        the event of a Brand Matter. A “
                        <span className={'terms-bold-text'}>Brand Matter</span>
                        ” means an event involving Merchant that, in Morewards
                        reasonable judgment, causes it or its affiliates to have significant concern for the reputation of its
                        respective Marks or brand. All payment obligations, proprietary rights, representations, warranties,
                        disclaimers, indemnities, limitations of liability and privacy provisions shall survive any termination of
                        these Terms.
                    </div>

                    <h3 className={'terms-header'}>15. Governing Law</h3>

                    <div className={'terms-text'}>
                        These Terms and your use of the Network shall be governed by the laws of the State of Connecticut,
                        without giving effect to the principles of conflict of laws. You hereby waive any objections to such
                        jurisdiction.
                    </div>

                    <h3 className={'terms-header'}>15. Governing Law</h3>

                    <div className={'terms-text'}>
                        Any and all notices permitted or required to be given hereunder will be sent to the address listed below, or
                        such other address as may be provided, and deemed duly given by electronic mail to the designated
                        recipient. Notices to should be provided to,
                        <span className={'terms-red-text'}> ________________.</span>
                    </div>

                    <div className={'terms-text'}>
                        Notices to Merchant should be provided by electronic mail to the address provided by Merchant. The
                        parties agree that all legal documents (including complaints and subpoenas) directed to Morewards will
                        be served on Morewards’ registered agent for service of process. The name and current contact
                        information for the registered agent in each state are available online at
                        <span className={'terms-red-text'}>
                            <a href="https://ct.wolterskluwer.com/sop-locations" target={'_blank'} rel="noreferrer">
                                https://ct.wolterskluwer.com/sop-locations
                            </a>
                        </span>.
                    </div>

                    <h3 className={'terms-header'}>17. Miscellaneous </h3>

                    <div className={'terms-text'}>
                        No waiver by Morewards of any term or condition set forth in these Terms shall be deemed a further or
                        continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of
                        Morewards to assert a right or provision under these Terms shall not constitute a waiver of such right or
                        provision. If any provision of these Terms is held by a court or other tribunal of competent jurisdiction to
                        be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the
                        minimum extent such that the remaining provisions of the Terms will continue in full force and effect.
                        These Terms are the entire agreement between you and us and supersede all prior or contemporaneous
                        negotiations, discussions or agreements between you and us about the Network.
                    </div>

                    <div className={'terms-text'}>
                        Please send any questions or report any violations of these Terms to
                        <a className={'terms-link'} href={'mailto:support@morewards.com'}> support@morewards.com</a>
                    </div>

                </div>

                <button
                    className={'terms-button active'}
                    onClick={() => {
                        setTerms(false);
                    }}
                >Close</button>
            </div>
        </div>
    );
};

export default VendorTerms;
