import React from 'react';
import './AuthPage.css';

import {Route, Switch} from "react-router-dom";

import useWindowDimensions from '../../hooks/windowDimensions.hook';

import AuthIntro from '../../components/customer-authorization/AuthIntro/AuthIntro';
import AuthHeader from '../../components/headers/AuthHeader/AuthHeader';

import Auth from '../../components/customer-authorization/Auth/Auth';
import AuthMobile from "../../components/customer-authorization/AuthMobile/AuthMobile";
import EmailVerify from "../../components/customer-authorization/EmailVerify/EmailVerify";
import Verification from "../../components/customer-authorization/Verification/Verification";
import ForgottenPassword from "../../components/customer-authorization/ForgottenPassword/ForgottenPassword";
import CreateNewPassword from "../../components/customer-authorization/CreateNewPassword/CreateNewPassword";
import CustomerTerms from "../../components/customer-authorization/TermsMobile/CustomerTerms";

const AuthPage = () => {
    const { width } = useWindowDimensions();
    const isMobileScreen = width < 600;

    const headerDependsOfScreen = () => {
        if (isMobileScreen) {
            return (
                <Switch>
                    <Route path={'/password-email'}>
                        <EmailVerify resetPassword={true} />
                    </Route>

                    <Route path={'/reset-password'} component={ForgottenPassword} />
                    <Route path={'/verification/:token'} component={Verification} />

                    <Route path={'/verify-email'}>
                        <EmailVerify resetPassword={false} />
                    </Route>

                    <Route path={'/auth'} component={AuthMobile} />

                    <Route path={'/new-password/:token'} component={CreateNewPassword} />
                    <Route path={'/'}>
                        <Auth isMobile={true} />
                    </Route>
                    <Route path={'*'}>
                        <Auth isMobile={true} />
                    </Route>
                </Switch>
            );
        }

        return (
            <>
                <AuthHeader />

                <Switch>
                    {/*<Route path={'/verify-email'}>*/}
                    {/*    <EmailVerify resetPassword={false} />*/}
                    {/*</Route>*/}

                    <Route path={'/new-password/:token'} exact>
                        <Auth newPassword={true} />
                    </Route>
                    <Route path={'/terms-of-service'} exact>
                        <CustomerTerms web={true} />
                    </Route>
                    <Route path={'/password-email'} exact>
                        <Auth passwordEmail={true}/>
                    </Route>
                    <Route path={'/verification/:token'} exact>
                        <Auth verification={true}/>
                    </Route>
                    <Route path={'/auth'} component={Auth} />
                    <Route path={'/'} component={AuthIntro} />
                </Switch>
            </>
        );
    }

    return (
        <div className={'auth-wrapper'}>
            <div className={'auth-page'}>
                {headerDependsOfScreen()}
            </div>
        </div>
    );
};

export default AuthPage;
