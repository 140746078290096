import React from 'react';

import locationMarker from '../../assets/locationMarker.svg';

import {Marker} from "@react-google-maps/api";



const LocationMarker = ({position}) => {
	return (
		<div>
			<Marker position={position} icon={locationMarker}/>
		</div>
	);
};

export default LocationMarker;
