import * as yup from 'yup';

const PhoneVerificationSchema = yup.object().shape({
    code: yup
        .string()
        .required('Verification code is a required field')
});

export {
    PhoneVerificationSchema
};
