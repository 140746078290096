import React, { useEffect, useState } from "react";
import s from "./CategoryProviders.module.css";

import { categoriesActions, providerActions } from "../../action";
import CategoryProvider from "./CategoryProvider";
import backArrow from "../../assets/arrows/blue-left-arrow.png";

const CategoryProviders = ({
  setShowCategoryProvidersID,
  searchValue,
  prevCategoryId,
  setSearchBySubCategoryName,
  setParentCategoryId,
}) => {
  const customerTokenLocal = localStorage.getItem("customerToken");

  const [providers, setProviders] = useState([]);
  const [page, setPage] = useState(1);

  const [noProviders, setNoProviders] = useState(false);

  useEffect(() => {
    if (!setShowCategoryProvidersID) {
      providerActions
        .getAllProviders(customerTokenLocal, page)
        .then(({ data }) => {
          if (page > data.last_page) return;

          setProviders([...providers, ...data.data]);
          setPage(page + 1);
        });

      return;
    }

    categoriesActions
      .getCategoriesListSub(setShowCategoryProvidersID, customerTokenLocal)
      .then(({ data }) => {
        if (!data.subCategories.length) setNoProviders(true);
        setProviders(data.subCategories);
      });
  }, [customerTokenLocal, page]);

  const searchProvider = () => {
    return providers.filter((provider) => {
      return provider.name
        .toLowerCase()
        .includes(searchValue.trim().toLowerCase());
    });
  };

  return (
    <>
      <div className={s.category_providers__title}>Providers</div>

      {!!prevCategoryId && (
        <img
          // style={{margin: '20px 0 37px'}}
          className={s.goBack__arrow}
          src={backArrow}
          alt="go back arrow"
          onClick={() => {
            setSearchBySubCategoryName(true);
            setParentCategoryId(prevCategoryId);
            console.log(prevCategoryId);
          }}
        />
      )}
      <div className={s.category_providers}>
        {searchProvider().map((provider) => (
          <CategoryProvider key={provider.id} provider={provider} />
        ))}
      </div>
      {noProviders && (
        <div className={"providers-not-found"}>Providers not found 😔</div>
      )}
    </>
  );
};

export default CategoryProviders;
