import React, {useState} from 'react';

const PayTypeSelector = ({paymentType, setPaymentType}) => {
    const [isActive, setIsActive] = useState();
    const options = ['Pay with cash or credit', 'Pay with rewards'];

    const codeSelectorActive = () => {
        if (isActive) {
            return (
                <div className={'selector-content payment'}>
                    {options.map(option => {
                        return (
                            <div
                                className={'selector-content__item payment'}
                                key={option}
                                onClick={() => {
                                    setPaymentType(option);
                                    setIsActive(false);
                                }}
                            >
                                {option}
                            </div>
                        );
                    })}
                </div>
            );
        }
    }

    return (
        <div className={'selector-wrapper category'}>
            <div
                className={'selector-button'}
                onClick={() => {
                    setIsActive(!isActive);
                }}
                style={{
                    color: paymentType ? '#4B4846' : '#8E8E93'
                }}
            >
                {paymentType ? paymentType : 'Choose payment type'}
            </div>

            {codeSelectorActive()}
        </div>
    );
};

export default PayTypeSelector;
