import React, {useState} from 'react';

import arrowIcon from "../../assets/arrows/right-arrow-small.svg";

import {setCurrentVendor, setVendorToken} from "../../redux/action-creators";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

import {CopyToClipboard} from "react-copy-to-clipboard";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import Modal from "../Modal/Modal";

const VendorAccount = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const vendorDeviceId = localStorage.getItem('vendorDeviceId');

    const [modalActive, setModalActive] = useState(false);

    const logOutVendor = () => {
        dispatch(setVendorToken(null));
        dispatch(setCurrentVendor({}));

        localStorage.removeItem('vendorToken');
        localStorage.removeItem('currentVendor');

        history.push('/');
    }

    return (
        <div className={'account__wrapper'}>
            <HeaderMobile vendor={true} />

            <div className={'account__inner'}>
                <div className={'account__title'}>Account</div>

                <div className={'account__link'} onClick={() => history.push('/directory')}>
                    Directory
                    <img src={arrowIcon} alt="arrow"/>
                </div>

                <div className={'account__link'} onClick={() => history.push('/my-reviews')}>
                    My reviews
                    <img src={arrowIcon} alt="arrow"/>
                </div>

                <div className={'account__link'} onClick={() =>{
                    setModalActive(true);
                }}>
                    Show my device ID
                    <img src={arrowIcon} alt="arrow"/>
                </div>

                <div
                    className={'account__link logout'}
                    onClick={logOutVendor}
                >
                    Logout
                    <img src={arrowIcon} alt="arrow"/>
                </div>
            </div>

            <Modal active={modalActive} setActive={setModalActive}>
                <div className={'account_id__inner'}>
                    <div style={{width: '100%', textAlign: 'center'}} className={'device-id-window__title'}>
                        Your device ID:
                    </div>

                    <div style={{width: '100%', textAlign: 'center'}} className={'device-id-ID'}>
                        {vendorDeviceId ? vendorDeviceId : 'No device id'}
                    </div>

                    <div className={'device-id-window__buttons'}>
                        <CopyToClipboard text={vendorDeviceId}>
                            <button
                                className={'device-id-window__buttons copy'}
                                onClick={() => {
                                    setModalActive(true);
                                }}
                            >Copy</button>
                        </CopyToClipboard>

                        <button
                            className={'device-id-window__buttons cancel'}
                            onClick={() => {
                                setModalActive(false);
                            }}
                        >Cancel</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default VendorAccount;
