import React, {useState} from 'react';
import './InviteOptions.css';

import crossIcon from "../../assets/close.png";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {emailInvite, phoneInvite} from '../../validators';

import {inviteActions} from '../../action';

// import {phoneNormalizer} from "../../helpers/phone-normalizer";
import {useDispatch, useSelector} from "react-redux";
import {setProviderInviteSent} from "../../redux/action-creators";

import Modal from "../Modal/Modal";

const InviteOptions = ({setShareActive}) => {
    const dispatch = useDispatch();

    // const customerTokenLocal = localStorage.getItem('customerToken');
    const customerTokenLocal = useSelector(({customerReducer: {customerToken}}) => customerToken);


    const [emailModalActive, setEmailModalActive] = useState(false);
    const [phoneModalActive, setPhoneModalActive] = useState(false);

    const [inviteErrorModalActive, setInviteErrorModalActive] = useState(false);
    const [inviteErrorMessage, setInviteErrorMessage] = useState('');

    // { register, handleSubmit, formState: {errors} }

    const emailForm = useForm({
        mode: 'onBlur',
        resolver: yupResolver(emailInvite),
    });

    const phoneForm = useForm({
        mode: 'onBlur',
        resolver: yupResolver(phoneInvite),
    });

    const sendInvite = (data) => {
        const inviteData = {
            identifier: data.email ? data.email : data.phone,
            type: data.email ? 1 : 2
        }

        inviteActions.sendInvite(inviteData, customerTokenLocal)
            .then(response => {

                if (response.ok) {
                    dispatch(setProviderInviteSent(true));
                    setShareActive(false);

                    return;
                }

                if (!response.ok) {
                    setInviteErrorMessage(response.message);
                    setInviteErrorModalActive(true);
                }
            })
            .catch(e => {
            })
    }

    return (
        <div className={'options-wrapper'}>
            <div className={'options-container'}>
                <div className={'options-container__title'}>Please select invite option</div>
                <div
                    className={'options-container__item'}
                    onClick={() => {
                        setEmailModalActive(true);
                    }}
                >Email</div>
                <div
                    className={'options-container__item'}
                    onClick={() => {
                        setPhoneModalActive(true);
                    }}
                >Phone number</div>
            </div>

            <button
                className={'options-button'}
                onClick={() => {
                    setShareActive(false);
                }}
            >Cancel</button>

            <Modal active={emailModalActive} setActive={setEmailModalActive} isPhoneError={true}>
                <div className={'invite__form-title'}>Please enter email</div>

                <form noValidate={true} onSubmit={emailForm.handleSubmit(sendInvite)}>
                    {emailForm.formState.errors?.email &&<div className={'invite__form-error'}>
                        {emailForm.formState.errors.email.message}
                    </div>}
                    <input
                        className={'invite__form-input'}
                        {...emailForm.register('email', {required: false})}
                        type="email"
                        placeholder={'Provider\'s email'}
                    />

                    <div className={'invite__form-button'}>
                        <button
                            type={'button'}
                            onClick={() => {
                                setEmailModalActive(false);
                            }}
                        >CANCEL</button>
                        <button type={"submit"}>OK</button>
                    </div>
                </form>
            </Modal>

            <Modal active={phoneModalActive} setActive={setPhoneModalActive} isPhoneError={true}>
                <div className={'invite__form-title'}>Please enter phone</div>

                <form noValidate={true} onSubmit={phoneForm.handleSubmit(sendInvite)}>
                    {phoneForm.formState.errors?.phone &&<div className={'invite__form-error'}>
                        {phoneForm.formState.errors.phone.message}
                    </div>}
                    <input
                        className={'invite__form-input'}
                        {...phoneForm.register('phone', {required: false})}
                        type="phone"
                        placeholder={'Provider\'s phone'}
                        // onChange={(event) => {
                        //     const {value} = event.target;
                        //     event.target.value = phoneNormalizer(value);
                        // }}
                    />

                    <div className={'invite__form-button'}>
                        <button
                            type={'button'}
                            onClick={() => {
                                setPhoneModalActive(false);
                            }}
                        >CANCEL</button>
                        <button type={"submit"}>OK</button>
                    </div>
                </form>
            </Modal>

            <Modal active={inviteErrorModalActive} setActive={setInviteErrorModalActive}>
                <div className={'vendor-errors'}>
                    <img
                        src={crossIcon}
                        alt="cross"
                        onClick={() => {
                            setInviteErrorModalActive(false);
                        }}
                    />

                    <div>
                        <span>Error</span>

                        <div>{inviteErrorMessage}</div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default InviteOptions;
