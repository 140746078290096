import React, {useState} from 'react';
import './CountriesSelector.css';

const StateSelector = ({states, state, setState}) => {
    const [isActive, setIsActive] = useState(false);
    const [placeholder, setPlaceholder] = useState('State');

    const isStates = states.length;

    const isStatesSelectorActive = () => {
        if (isActive) {
            return (
                <div className={'selector-content states'}>
                    {states.map(state => {
                        return (
                            <div
                                className={'selector-content__item states'}
                                key={state}
                                onClick={e => {
                                    setState(e.target.textContent);
                                    setIsActive(false);
                                }}
                            >
                                {state}
                            </div>
                        );
                    })}
                </div>
            );
        }
    }

    return (
        <div className={'selector-wrapper states'}>
            <div
                className={'selector-button'}
                onClick={() => {
                    if (isStates) {
                        setPlaceholder('State');
                        setIsActive(!isActive);
                    } else {
                        setPlaceholder('No country selected');
                    }
                }}
                style={{
                    color: state ? '#4B4846' : '#8E8E93'
                }}
            >
                {state ? state : placeholder}
            </div>

            {isStatesSelectorActive()}
        </div>
    );
};

export default StateSelector;
