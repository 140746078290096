import React from 'react';
import './AuthMobileHeader.css';

import logo from '../../../assets/logos/new-logo.svg';

const AuthMobileHeader = () => {
    return (
        <div className={'auth-mobile-header'}>
            <img src={logo} alt='logo'/>
        </div>
    );
};

export default AuthMobileHeader;
