import React, { useCallback, useEffect, useState } from "react";
import "./RewardBalance.css";

import goBackArrow from "../../assets/arrows/blue-left-arrow.png";

import { useHistory, useLocation, useParams } from "react-router-dom";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import QRCode from "qrcode";
import { useSelector } from "react-redux";

const ReturnPurchase = () => {
  const history = useHistory();

  const { id } = useParams();
  const location = useLocation();

  const customer = useSelector(
    ({ customerReducer: { currentCustomer } }) => currentCustomer
  );

  const [QrCode, setQrCode] = useState("");
  const generateQrCode = useCallback(async () => {
    const data = `return,${Date.now()},${location.state.activity.id},${
      location.state.activity.title
    },${location.state.activity.amount_paid}`;
    const response = await QRCode.toDataURL(data, {
      errorCorrectionLevel: "M",
      maskPattern: 1,
      type: "image/jpeg",
      quality: 0.1,
      margin: 0,
      color: {
        dark: "#000",
        light: "#F1F0F0",
      },
    });
    setQrCode(response);
  }, [customer?.pc, id]);

  useEffect(() => {
    generateQrCode();
  }, [generateQrCode]);

  setTimeout(generateQrCode, 180000);

  return (
    <div className={"wrapper"}>
      <HeaderMobile />

      <div className={"inner"}>
        <div className={"inner-title"}>
          <img
            src={goBackArrow}
            alt="go back arrow"
            onClick={() => {
              history.goBack();
            }}
          />
          <div>Return purchase</div>
          <div />
        </div>

        <div className={"inner__subtitle"}>
          <div>Present this screen to the business</div>
          <div>where you want to return your</div>
          <div>purchase</div>
        </div>

        <img className={"inner__qr-code"} src={QrCode} alt="qr-code" />

        <button
          className={"inner__button bold"}
          onClick={() => {
            history.goBack();
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default ReturnPurchase;
