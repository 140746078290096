import axios from "axios";
import {BASE_URL} from "../constants";

const leaveReview = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.post(`${BASE_URL}/api/user/review`, data, config);

    return response.data;
}

const getReviews = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(`${BASE_URL}/api/reviews`, config);
    return response.data;
}

const getReview = async (token, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(`${BASE_URL}/api/reviews/${id}`, config);
    return response.data;
}

export {
    leaveReview,
    getReviews,
    getReview
}

// {
//     "ok": true,
//     "data": {
//     "review": {
//         "job_id": 3805,
//             "user_id": 214,
//             "user_id_reviewed": 166,
//             "review": "alalalala",
//             "rating": 100500,
//             "updated_at": "2021-12-16T14:25:36.000000Z",
//             "created_at": "2021-12-16T14:25:36.000000Z",
//             "id": 2156
//     },
//     "result": [
//         {
//             "InstructMRFEID": 1341,
//             "InstructMRFEParentID": 3805
//         }
//     ]
// }
// }
