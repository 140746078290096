import React, {useState} from 'react';
import './Account.css';

import crossIcon from "../../assets/close.png";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {PhoneVerificationSchema} from "../../validators";

import {customerActions, verificationActions} from '../../action';

import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setAccountEdited, setCurrentCustomer} from "../../redux/action-creators";

import Modal from "../Modal/Modal";

const ConfirmPhone = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    // const token = localStorage.getItem('customerToken');
    const token = useSelector(({customerReducer: {customerToken}}) => customerToken);

    const [modalActive, setModalActive] = useState(false);
    const [verificationError, setVerificationError] = useState(null);

    const { register, handleSubmit, formState: {errors} } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(PhoneVerificationSchema)
    });

    const sendVerificationCode = (data) => {
        verificationActions.verifyUpdatedPhone(data, token)
            .then(({data}) => {
                if (data.ok) {
                    dispatch(setAccountEdited(true));
                    history.push('/account');
                    customerActions.getCustomer(token)
                        .then(({data}) => {
                            dispatch(setCurrentCustomer(data.data.user));
                        });
                    return;
                }

                if (!data.ok) {
                    setModalActive(true);
                    setVerificationError(data.data.message);
                }
            })
            .catch((error) => {
                setModalActive(true);
                setVerificationError(error.message);
            });
    }

    return (
        <div className={'account__wrapper'}>
            <div className={'account__inner'}>
                <div className={'account__title'}>Confirm phone</div>

                <div className={'account__inner-subtitle'}>
                    We have sent you a code to your mobile phone. Please enter it here
                </div>

                <form noValidate={true} onSubmit={handleSubmit(sendVerificationCode)}>
                    <input
                        {...register('code')}
                        className={'account__form-input'}
                        type="number"
                        placeholder={'Verification Code'}
                    />

                    <button
                        className={'account__button active'}
                        type={'submit'}
                        onClick={() => {
                            if (Object.keys(errors).length) {
                                setModalActive(true);
                            }
                        }}
                    >Confirm</button>
                </form>
            </div>

            <Modal active={modalActive} setActive={setModalActive}>
                <div className={'vendor-errors'}>
                    <img
                        src={crossIcon}
                        alt="cross"
                        onClick={() => {
                            setModalActive(false);
                        }}
                    />

                    <div>
                        <span>Error</span>

                        <div>{verificationError}</div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ConfirmPhone;
