import React, {useCallback, useEffect, useState} from 'react';
import './Pay.css';

import goBackArrow from '../../assets/arrows/blue-left-arrow.png';

import QRCode from 'qrcode';

import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";

const Rewards = () => {
    // const customerToken = useSelector(({customerReducer: {customerToken}}) => customerToken);
    const customer = useSelector(({customerReducer : {currentCustomer}}) => currentCustomer);

    const history = useHistory();

    const [QrCode, setQrCode] = useState('');

    const generateQrCode = useCallback(async () => {
        const data = `payedWithRewards,${Date.now()},${customer.id},${customer.pc}`;

        const response = await QRCode.toDataURL(data, {
            errorCorrectionLevel: 'M',
            maskPattern: 1,
            type: 'image/jpeg',
            quality: 0.1,
            margin: 0,
            color: {
                dark:"#000",
                light:"#F1F0F0"
            }
        });
        setQrCode(response);
    }, [customer?.id, customer?.pc]);

    useEffect(() => {
        generateQrCode();
    }, [generateQrCode]);

    setTimeout(generateQrCode, 180000);

    return (
        <div className={'wrapper'}>
            <HeaderMobile />

            <div className={'inner'}>
                <div className={'inner-title'}>
                    <img
                        src={goBackArrow}
                        alt="go back arrow"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    <div>Pay with Rewards</div>
                    <div />
                </div>

                <div className={'inner__subtitle'}>
                    <div>Show this screen to the business</div>
                    <div>where you want to redeem your</div>
                    <div>gift card.</div>
                </div>

                <img className={'inner__qr-code'} src={QrCode} alt="qr-code"/>

                <button
                    className={'inner__button bold'}
                    onClick={() => {
                        history.goBack();
                    }}
                >Back</button>
            </div>
        </div>
    )
};

export default Rewards;
