import React, {useState} from 'react';
import './Invite.css';

import shareIcon from '../../assets/invite/share.png';
import sadIcon from '../../assets/invite/sad.png';
import goBackArrow from '../../assets/arrows/blue-left-arrow.png';

import {useHistory, useParams} from "react-router-dom";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import InviteOptions from "./InviteOptions";

const InviteFriend = () => {
    const history = useHistory();

    const {id} = useParams();
    const inviteCode = localStorage.getItem('inviteCode');

    const [shareActive, setShareActive] = useState(false);

    const isAvailableCodes = () => {
        if (!parseInt(id)) {
            return (
                <>
                    <img className={'invite-sad-img'} src={sadIcon} alt=""/>

                    <div className={'invite-warn'}>
                        <div>No code available.</div>
                        <div>Please, invite business first!</div>
                    </div>

                    <button
                        className={'invite-button bold'}
                        onClick={() => {
                            history.goBack();
                        }}
                    >Back</button>
                </>
            );
        }

        return (
            <>
                <div className={'invite-subtitle'}>
                    <div>Want a quick way to add to</div>
                    <div>your rewards balance?</div>
                </div>

                <div className={'invite-text'}>
                    <div>Share your invite code with 10 friends!</div>
                    <div>For every friend that registers you will receive $30</div>
                    <div>into your rewards balance and so will your friend.</div>
                </div>

                <div className={'invite-message'}>Share your invite code:</div>

                <div className={'invite-code'}>
                    <div />
                    <div>{inviteCode}</div>
                    <img
                        src={shareIcon}
                        alt="share"
                        onClick={() => {
                            setShareActive(true);
                        }}
                    />
                </div>

                <div className={'invite-fiends'}>{id} friends left</div>
            </>
        );
    }

    return (
        <div className={'invite-wrapper'}>
            <HeaderMobile />

            <div className={'invite-inner'}>
                <div className={'invite-title'}>
                    <img
                        src={goBackArrow}
                        alt="go back arrow"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    <div>Invite a friend</div>
                    <div />
                </div>

                {isAvailableCodes()}
            </div>

            {shareActive && <InviteOptions setShareActive={setShareActive} />}
        </div>
    );
};

export default InviteFriend;
