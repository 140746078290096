import React, { useEffect, useState } from "react";
import s from "./CystomersStats.module.css";

import profilePicture from "../../assets/customer-stats/profile-photo.svg";
import placesVisited from "../../assets/customer-stats/places-visited.svg";
import transactionCompletedIcon from "../../assets/customer-stats/transactions-completed.svg";
import transactionReturnedIcon from "../../assets/customer-stats/transactions-returned.svg";
import reviewsWrittenIcon from "../../assets/customer-stats/reviews-written.svg";
import friendsInvitedIcon from "../../assets/customer-stats/friends-invited.svg";

import { customerActions } from "../../action";

// import MorePlaces from "../MorePlaces/MorePlaces";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentCustomer,
  setFriendInvitesLeft,
} from "../../redux/action-creators";

const CustomerStats = ({
  inviteActive,
  setInviteActive,
  mapActive,
  setMapActive,
}) => {
  const dispatch = useDispatch();

  // const currentCustomer = useSelector(({customerReducer: {currentCustomer}}) => currentCustomer);
  // const customerTokenLocal = localStorage.getItem('customerToken');
  const customerToken = useSelector(
    ({ customerReducer: { customerToken } }) => customerToken
  );

  const [balanceActive, setBalanceActive] = useState(true);

  const [user, setUser] = useState({});
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    if (!inviteActive) setBalanceActive(true);
  }, [inviteActive]);

  useEffect(() => {
    if (!customerToken) return;

    customerActions.getCustomer(customerToken).then(({ data }) => {
      console.log(data, "datatatattt");
      setUser(data.data.user);
      //   console.log(data.data.user, "datatataatatat");
      setBalance(data?.data.balance?.mrp_balance);

      const user = data.data.user;
      user.web_balance = data?.data.balance?.mrp_balance;

      dispatch(setCurrentCustomer(user));
    });
  }, [customerToken, dispatch]);

  useEffect(() => {
    customerActions.getCustomer().then(({ data }) => {
      dispatch(setFriendInvitesLeft(data.data.invitesLeft));
    });
  }, [inviteActive, dispatch]);

  return (
    <div className={s.customer_stats}>
      <div
        style={{ cursor: "pointer" }}
        className={
          balanceActive ? `${s.customer_stat} ${s.active}` : s.customer_stat
        }
        onClick={() => {
          setInviteActive(false);
          setBalanceActive(true);
        }}
      >
        <img src={profilePicture} alt="profile" />
        <div>{user.first_name}’s reward</div>
        <div>balance</div>
        <span className={s.balance}>$ {balance}</span>
      </div>

      <div className={s.customer_stat}>
        <img src={placesVisited} alt="places visited" />
        <div>Places</div>
        <div>visited</div>
        <span className={s.count}>{user.countPlacesVisited}</span>
      </div>

      <div className={s.customer_stat}>
        <img src={transactionCompletedIcon} alt="profile" />
        <div>Transactions</div>
        <div>completed</div>
        <span className={s.count}>{user.countTransactionCompleted}</span>
      </div>

      <div className={s.customer_stat}>
        <img src={transactionReturnedIcon} alt="profile" />
        <div>Transactions</div>
        <div>returned</div>
        <span className={s.count}>{user.countTransactionReturned}</span>
      </div>

      <div className={s.customer_stat}>
        <img src={reviewsWrittenIcon} alt="profile" />
        <div>Reviews</div>
        <div>written</div>
        <span className={s.count}>{user.countReviewsWritten}</span>
      </div>

      <div
        style={{ cursor: "pointer", backgroundColor: "#96c13e" }}
        className={
          inviteActive ? `${s.customer_stat} ${s.active}` : s.customer_stat
        }
        onClick={() => {
          setBalanceActive(false);
          setInviteActive(true);
        }}
      >
        <img className={s.invite} src={friendsInvitedIcon} alt="profile" />
        <div>Friends</div>
        <div>invited</div>
        <span className={s.count}>{user.countInviteFriends}</span>
      </div>

      {/*<MorePlaces inviteActive={inviteActive} mapActive={mapActive} setMapActive={setMapActive} />*/}
    </div>
  );
};

export default CustomerStats;
