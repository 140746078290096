import React, {useEffect, useState} from 'react';
import './Header.css';

import logo from '../../../assets/logos/new-logo.svg';

import homeIcon from '../../../assets/nav-icons/home.svg';
import activitiesIcon from '../../../assets/nav-icons/activities.svg';
import directoryIcons from '../../../assets/nav-icons/directory.svg';

import {Link, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

const Header = () => {
    const history = useHistory();
    const currentCustomer = useSelector(({customerReducer: {currentCustomer}}) => currentCustomer);


    const [isHomePage, setIsHomePage] = useState(true);
    const [isActivitiesPage, setIsActivitiesPage] = useState(false);
    const [isDirectoryPage, setIsDirectoryPage] = useState(false);
    const [isProfilePage, setIsProfilePage] = useState(false);

    useEffect(() => {
        if (window.location.href === 'http://localhost:3000/') {
            setIsHomePage(true);
            setIsActivitiesPage(false);
            setIsDirectoryPage(false);
            setIsProfilePage(false);
        }
        if (window.location.href.includes('activities')) {
            setIsHomePage(false)
            setIsActivitiesPage(true);
            setIsDirectoryPage(false);
            setIsProfilePage(false);
        }
        if (window.location.href.includes('directory')) {
            setIsDirectoryPage(true);
            setIsHomePage(false)
            setIsActivitiesPage(false);
            setIsProfilePage(false);
        }
        if (window.location.href.includes('profile')) {
            setIsDirectoryPage(false);
            setIsHomePage(false)
            setIsActivitiesPage(false);
            setIsProfilePage(true);
        }
    }, [window.location.href]);

    return (
        <div className={'web-header__wrapper'}>
            <div className="web-container">
                <div className="web-header__inner">
                    <div className={'web-header__logo'}>
                        <img
                            src={logo}
                            alt="Morewards logo"
                            onClick={() => {
                                setIsHomePage(true);
                                setIsActivitiesPage(false);
                                setIsDirectoryPage(false);
                                setIsProfilePage(false);

                                history.push('/');
                            }}
                        />
                        <a href={"morewards.com"}>Download the app</a>
                    </div>

                    <nav className={'web-header__nav'}>
                        <Link
                            to={'/'}
                            className={isHomePage ? "web-naw__link active" : 'web-naw__link'}
                            onClick={() => {
                                setIsHomePage(true);
                                setIsActivitiesPage(false);
                                setIsDirectoryPage(false);
                                setIsProfilePage(false);
                            }}
                        >
                            <img src={homeIcon} alt=""/>
                            Home
                        </Link>
                        <Link
                            to={'/activities'}
                            className={isActivitiesPage ? "web-naw__link active" : 'web-naw__link'}
                            onClick={() => {
                                setIsHomePage(false);
                                setIsActivitiesPage(true);
                                setIsDirectoryPage(false);
                                setIsProfilePage(false);
                            }}
                        >
                            <img src={activitiesIcon} alt=""/>
                            Activities
                        </Link>
                        <Link
                            to={'/directory'}
                            className={isDirectoryPage ? "web-naw__link active" : 'web-naw__link'}
                            onClick={() => {
                                setIsHomePage(false);
                                setIsActivitiesPage(false);
                                setIsDirectoryPage(true);
                                setIsProfilePage(false);
                            }}
                        >
                            <img src={directoryIcons} alt=""/>
                            Directory
                        </Link>

                        <Link
                            to={'/profile'}
                            className={isProfilePage ? "web-naw__link active" : 'web-naw__link'}
                            onClick={() => {
                                setIsHomePage(false);
                                setIsActivitiesPage(false);
                                setIsDirectoryPage(false);
                                setIsProfilePage(true);
                            }}
                        >
                            {currentCustomer?.first_name} {currentCustomer?.last_name}
                        </Link>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Header;
