import React, {useState} from 'react';
import './ForgottenPassword.css';

import crossIcon from "../../../assets/close.png";

import {useHistory} from 'react-router-dom';

import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {ResetPasswordSchema} from '../../../validators';

import {passwordActions} from "../../../action";

import AuthMobileHeader from '../../headers/AuthMobileHeader/AuthMobileHeader';
import Modal from "../../Modal/Modal";

const ForgottenPassword = ({setIsRestore}) => {
    const history = useHistory();

    const [modalActive, setModalActive] = useState(false);

    const { register, handleSubmit, formState: {errors} } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(ResetPasswordSchema)
    });

    const [value, setValue] = useState('');

    const resetPassword = (data) => {
        setModalActive(false);

        passwordActions.sendRecoveryEmail(data)
            .then(() => {
                history.push('/password-email');
            })
            .catch(e => {
            });
    }

    return (
        <>
            <div className={'forgotten-password__wrapper'}>
                <div className={'forgotten-password__inner'}>
                    {!setIsRestore && <AuthMobileHeader />}

                    <div className={'forgotten-password__title'}>Forgotten Password</div>
                    <div className={'forgotten-password__subtitle'}>Reset link will be sent to your email.</div>

                    <form noValidate={true} onSubmit={handleSubmit(resetPassword)}>
                        <input
                            {...register('email')}
                            className={'forgotten-password__input'}
                            type="text"
                            placeholder={'Your Email'}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />

                        <button
                            style={{backgroundColor: value ? '#1893D2' : '#8E8E93'}}
                            className={'forgotten-password__button active'}
                            type={'submit'}
                            onClick={() => {
                                if (Object.keys(errors).length) {
                                    setModalActive(true);
                                }
                            }}
                        >Reset Password</button>
                    </form>

                    <button
                        className={'forgotten-password__button bold'}
                        onClick={() => {
                            history.goBack();
                        }}
                    >Back to log in</button>
                </div>

                <div
                    className={'forgotten-password__web-back'}
                    onClick={() => setIsRestore(false)}
                >Back to log in</div>
            </div>

            {modalActive &&
                <Modal active={modalActive} setActive={setModalActive}>
                    <div className={'vendor-errors'}>
                        <img
                            src={crossIcon}
                            alt="cross"
                            onClick={() => {
                                setModalActive(false);
                            }}
                        />

                        <div>
                            <span>Error</span>

                            {errors?.email && <div>{errors.email.message}</div>}
                        </div>
                    </div>
                </Modal>
            }
        </>
    );
};

export default ForgottenPassword;
