import React, {useEffect, useState} from 'react';

import goBackArrow from "../../assets/arrows/blue-left-arrow.png";
import StarRatings from "react-star-ratings";

import {useParams} from "react-router-dom";
import {useHistory} from "react-router-dom";
import {reviewActions} from '../../action';

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import Loader from "../Loader/Loader";

const Review = () => {
    const {id} = useParams();
    const history = useHistory();

    const vendorTokenLocal = localStorage.getItem('vendorToken');
    // const vendorTokenLocal = '384|eeu6jLfXXmZiI0MmU3iA79xmnM911zScjQ43Nx4c';

    const [isLoading, setIsLoading] = useState(false);
    const [review, setReview] = useState({});

    useEffect(() => {
        setIsLoading(true);

        reviewActions.getReview(vendorTokenLocal, id)
            .then(response => {
                setReview(response.data[0]);
                setIsLoading(false);
            });
    }, [vendorTokenLocal, id]);

    const isReviewLoaded = () => {
        if (isLoading) return <div className={'r-l'}><Loader/></div>;

        return (
            <>
                <div className="vendor-review-title">{review?.job?.title}</div>

                <div className={'vendor-review-form'}>
                    {review.review}

                    <div>
                        <StarRatings
                            rating={review.rating}
                            starRatedColor='rgba(255, 188, 58, 1)'
                            numberOfStars={5}
                            starSpacing={'4px'}
                            isSelectable={false}
                            name='rating'
                            svgIconViewBox={'0 0 15 14'}
                            svgIconPath={'M6.54894 0.927048C6.8483 0.00573778 8.1517 0.0057404 8.45106 0.927051L9.40837 3.87336C9.54224 4.28538 9.9262 4.56434 10.3594 4.56434H13.4574C14.4261 4.56434 14.8289 5.80395 14.0451 6.37336L11.5389 8.19427C11.1884 8.44892 11.0417 8.90028 11.1756 9.31231L12.1329 12.2586C12.4323 13.1799 11.3778 13.946 10.5941 13.3766L8.08778 11.5557C7.7373 11.3011 7.2627 11.3011 6.91221 11.5557L4.40594 13.3766C3.62222 13.946 2.56774 13.1799 2.8671 12.2586L3.82441 9.3123C3.95828 8.90028 3.81162 8.44892 3.46114 8.19427L0.954859 6.37335C0.171145 5.80395 0.573923 4.56434 1.54265 4.56434H4.64057C5.0738 4.56434 5.45776 4.28538 5.59163 3.87336L6.54894 0.927048Z'}
                        />
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className={'reviews-wrapper'}>
            <HeaderMobile vendor={true}/>

            <div className="reviews__inner">
                <div className="reviews__title">
                    <img
                        src={goBackArrow}
                        alt="go back"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    Reviews
                    <div/>
                </div>

                {isReviewLoaded()}

            </div>
        </div>
    );
};

export default Review;
