import React from "react";
import "./Activities.css";

import moment from "moment";
import { useHistory } from "react-router-dom";

import StarRatings from "react-star-ratings";

const Activity = ({ activity, returns, vendor, bonus }) => {
  const history = useHistory();
  const date = moment(activity.created_at).format("MMM Do");

  const titleClass = () => {
    if (activity.status === "refund") return "activity__title red";
    if (bonus) return "activity__title green";
    if (
      activity.status === "reward" ||
      activity.status === "invite" ||
      activity.status === "residual"
    )
      return "activity__title green";

    return "activity__title";
  };

  const isActivitiesReturns = () => {
    if (vendor) {
      return (
        <div className={"stars"}>
          <StarRatings
            rating={activity.rating ? activity.rating : 0}
            starRatedColor="rgba(255, 188, 58, 1)"
            numberOfStars={5}
            starSpacing={"2px"}
            isSelectable={false}
            name="rating"
            svgIconViewBox={"0 0 15 14"}
            svgIconPath={
              "M6.54894 0.927048C6.8483 0.00573778 8.1517 0.0057404 8.45106 0.927051L9.40837 3.87336C9.54224 4.28538 9.9262 4.56434 10.3594 4.56434H13.4574C14.4261 4.56434 14.8289 5.80395 14.0451 6.37336L11.5389 8.19427C11.1884 8.44892 11.0417 8.90028 11.1756 9.31231L12.1329 12.2586C12.4323 13.1799 11.3778 13.946 10.5941 13.3766L8.08778 11.5557C7.7373 11.3011 7.2627 11.3011 6.91221 11.5557L4.40594 13.3766C3.62222 13.946 2.56774 13.1799 2.8671 12.2586L3.82441 9.3123C3.95828 8.90028 3.81162 8.44892 3.46114 8.19427L0.954859 6.37335C0.171145 5.80395 0.573923 4.56434 1.54265 4.56434H4.64057C5.0738 4.56434 5.45776 4.28538 5.59163 3.87336L6.54894 0.927048Z"
            }
          />
        </div>
      );
    }

    if (!bonus) {
      if (activity.status === "complete" && !bonus) {
        return (
          <button
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/leave-review/${activity.id}`);
            }}
          >
            Review to enable return
          </button>
        );
      }

      if (activity.status === "reviewed")
        return (
          <button
            onClick={(e) => {
              e.stopPropagation();
              history.push({
                pathname: `/return-purchase/${activity.id}`,
                state: { activity: activity },
              });
            }}
          >
            Return
          </button>
        );
    }

    if (activity.status === "complete" || activity.status === "pending") {
      return (
        <button
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/leave-review/${activity.id}`);
          }}
        >
          Review
        </button>
      );
    }
  };

  const status = () => {
    if (activity.status === "pending") return "Waiting for review";
    return activity.status;
  };

  return (
    <div
      className={"activity"}
      onClick={() => {
        console.log(activity.status, "statusssss");
        if (
          activity.status === "pending" ||
          activity.status === "complete" ||
          activity.status === "reviewed"
        ) {
          history.push(`/activity-details/${activity.id}`);
        }
      }}
    >
      <div className={"activity-info"}>
        <div
          className={titleClass()}
          //activity.title
        >
          {activity.status === "pending" || activity.status === "reviewed"
            ? activity?.user_provider?.name
            : activity.title}
        </div>
        <div className={"activity__data"}>{date}</div>
      </div>

      {!bonus && (
        <div className={"activity__status"}>
          {activity.id}: {status()}
        </div>
      )}

      <div className={"activity__button"}>
        <div>{activity.amount_paid} $</div>
        {isActivitiesReturns()}
      </div>
    </div>
  );
};

export default Activity;
