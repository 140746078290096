import React, {useEffect, useRef, useState} from 'react';

import locationMarker from "../../assets/location-marker-red.svg";

import {GoogleMap, Marker} from "@react-google-maps/api";
import {getBrowserLocation} from "../../helpers/currentGeolocation";
import {getDistance} from "geolib";

const containerStyle = {
	width: '83.22%',
	height: '680px'
};

const defaultOptions = {
	panControl: true,
	zoomControl: false,
	mapTypeControl: false,
	scaleControl: false,
	streetViewControl: false,
	rotateControl: false,
	clickableIcons: false,
	keyboardShortcuts: false,
	scrollwheel: true,
	disableDoubleClickZoom: false,
	fullscreenControl: false,
	terms: false
}

const PlacesMap = ({places}) => {
	const mapRef = useRef(undefined);
	const [center, setCenter] = useState({});

	const [allLocations, setAllLocations] = useState([]);

	const onLoad = React.useCallback(function callback(map) {
		mapRef.current = map;
	}, [])

	const onUnmount = React.useCallback(function callback() {
		mapRef.current = undefined;
	}, []);

	useEffect(() => {
		getBrowserLocation()
			.then((currentLocation) => {
				setCenter(currentLocation);
			})
			.catch((defaultLocation) => {
				setCenter(defaultLocation);
			});

		const locations = places.reduce((loc, provider) => {
			return [...loc, ...provider.locations]
		}, []);

		locations.filter(location => {
			const distance = Math.floor(getDistance(center, {
				lat: parseFloat(location.lat),
				lng: parseFloat(location.lon)
			}) * 0.000621371192);

			// if (4500 > distance) return true;
			return 4500 > distance;
		});

		setAllLocations(locations);
	}, [places, center]);

	return (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={center}
			zoom={15}
			onLoad={onLoad}
			onUnmount={onUnmount}
			options={defaultOptions}
		>
			{allLocations.map(location => {
				return <Marker position={{
					lat: parseFloat(location.lat),
					lng: parseFloat(location.lon)
				}} icon={locationMarker}/>
			})}
			{/*<Marker position={center} icon={locationMarker}/>*/}
		</GoogleMap>
	);
};

export default PlacesMap;
