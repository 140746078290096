import React, {useEffect, useState} from 'react';

import searchIcon from "../../assets/directory/search.svg";
import filterIcon from "../../assets/directory/categories-filter.svg";
import goBackArrow from "../../assets/arrows/blue-left-arrow.png";

import {useHistory, useParams} from "react-router-dom";

import {categoriesActions} from '../../action';

import Category from "./Category";
import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import Loader from "../Loader/Loader";
import {useSelector} from "react-redux";

const SubCategoriesList = () => {
    const history = useHistory();

    const {name, id} = useParams();

    // const customerTokenLocal = localStorage.getItem('customerToken');
    const customerTokenLocal = useSelector(({customerReducer: {customerToken}}) => customerToken);


    const [isLoading, setIsLoading] = useState(false);

    const [categories, setCategories] = useState([]);
    const [searchCategory, setSearchCategory] = useState('');

    useEffect(() => {
        setIsLoading(true);

        categoriesActions.getCategoriesListSub(id, customerTokenLocal)
            .then(response => {
                if (response.ok) {
                    setCategories(response.data.subCategories);
                    setIsLoading(false);
                }
            })
    }, [customerTokenLocal, id]);

    const filterCategories = () => {
        return categories.filter(category => {
            if (searchCategory === '') return category;
            return category.name.toLowerCase().includes(searchCategory.toLowerCase());
        });
    }

    return (
        <div className={'directory__wrapper'}>
            <HeaderMobile />

            <div className={'directory__inner'}>
                <div className={'directory__title sub'}>
                    <img
                        src={goBackArrow}
                        alt="go back arrow"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    {name}
                    <div />
                </div>

                <div className={'directory__search'}>
                    <input
                        type="text"
                        placeholder={'Search categories'}
                        onChange={e => setSearchCategory(e.target.value)}
                    />

                    <img className={'img-1'} src={searchIcon} alt="search"/>
                    <img className={'img-2'} src={filterIcon} alt="categories filter"/>
                </div>

                {isLoading && <Loader />}

                {filterCategories().map(category =>
                    <Category key={category.id} category={category} isSubCategory={true}/>
                )}
            </div>
        </div>
    );
};

export default SubCategoriesList;
