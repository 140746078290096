import React, {useState} from 'react';
import s from './WebProfilePage.module.css';

import emailIcon from '../assets/web-profile-icons/user-email.svg';
import phoneIcon from '../assets/web-profile-icons/user-phone.svg';

import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {yupResolver} from "@hookform/resolvers/yup";
import {createNewPasswordSchema, EditCustomerSchema} from "../validators";

import {customerActions, passwordActions} from '../action';
import {setCurrentCustomer, setCustomerToken} from "../redux/action-creators";

const WebProfilePage = () => {
	const customer = useSelector(({customerReducer : {currentCustomer}}) => currentCustomer);
	const customerTokenLocal = localStorage.getItem('customerToken');

	const [isEditAccount, setIsEditAccount] = useState(false);

	const history = useHistory();
	const dispatch = useDispatch();

	const defaultValues = {
		firstname: customer.first_name,
		lastname: customer.last_name,
		phone: customer.phone,
		areacode: customer.pc
	}

	const editAccountForm = useForm({
		mode: 'onBlur',
		resolver: yupResolver(EditCustomerSchema),
		defaultValues: defaultValues
	});

	const createNewPassword = useForm({
		mode: 'onBlur',
		resolver: yupResolver(createNewPasswordSchema)
	});

	const editUser = (data) => {
		customerActions.updateCustomer(data, customerTokenLocal)
			.then(({data}) => {
				if (data.ok) setIsEditAccount(false);
			})
	}

	const resetPassword = (data) => {
		data.password_confirmation = data.confirmPassword;
		delete data.confirmPassword;

		passwordActions.changePassword(data, customerTokenLocal)
			.then(({data}) => {
				if (data.ok) createNewPassword.reset();
			});
	}

	const logOut = () => {
		dispatch(setCustomerToken(null));
		dispatch(setCurrentCustomer({}));

		localStorage.removeItem('customerToken');
		localStorage.removeItem('currentCustomer');
		localStorage.removeItem('currentCustomerBalance');

		history.push('/');
	}

	return (
		<div className={'web-container'}>
			<div className={s.profile_page}>
				<div className={s.user_card}>
					<div className={s.user_info}>
						<div className={s.user_name}>{customer?.first_name}</div>

						<div className={s.user_data}>
							<img src={emailIcon} alt="email"/>
							{customer?.email}
						</div>

						<div className={s.user_data}>
							<img src={phoneIcon} alt="phone"/>
							{customer?.phone}
						</div>

						<div className={s.user_balance}>
							Balance: $ {customer?.web_balance}
						</div>
					</div>

					<button
						className={s.edit__button}
						onClick={() => {
							setIsEditAccount(!isEditAccount);
							editAccountForm.reset();
						}}
					>Edit account</button>

					<div
						className={s.logout}
						onClick={() => logOut()}
					>Log out</div>
				</div>

				<div className={s.edit_wrapper}>
					{isEditAccount && <div className={s.edit__account__data}>
						<div className={s.change_password__title}>Edit account</div>

						<form noValidate={true} onSubmit={editAccountForm.handleSubmit(editUser)}>
							<div className={s.error}>{editAccountForm.formState?.errors?.firstname?.message}</div>
							<input
								className={s.input}
								{...editAccountForm.register('firstname', {required: false})}
								type="text"
								id={'firstname'}
								placeholder={'First Name'}
							/>

							<div className={s.error}>{editAccountForm.formState?.errors?.lastname?.message}</div>
							<input
								className={s.input}
								{...editAccountForm.register('lastname', {required: false})}
								type="text"
								id={'lastname'}
								placeholder={'First Name'}
							/>

							<div className={s.error}>{editAccountForm.formState?.errors?.phone?.message}</div>
							<input
								className={s.input}
								{...editAccountForm.register('phone', {required: false})}
								type="phone"
								id={'phone'}
								placeholder={'Phone'}
							/>

							<div className={s.error}>{editAccountForm.formState?.errors?.areacode?.message}</div>
							<input
								className={s.input}
								{...editAccountForm.register('areacode', {required: false})}
								type="text"
								id={'areacode'}
								placeholder={'Zip Code'}
							/>

							<button
								type={'submit'}
								className={s.confirm__button}
							>Save</button>
						</form>
					</div>}

					<div className={s.change_password}>
						<div className={s.change_password__title}>Password</div>

						<form noValidate={true} onSubmit={createNewPassword.handleSubmit(resetPassword)}>
							<div className={s.error}>{createNewPassword.formState?.errors?.password?.message}</div>
							<input
								{...createNewPassword.register('password')}
								className={s.input}
								type="text"
								placeholder={'New password'}
								// value={value}
								// onChange={(e) => setValue(e.target.value)}
							/>

							<div className={s.error}>{createNewPassword.formState?.errors?.confirmPassword?.message}</div>
							<input
								{...createNewPassword.register('confirmPassword')}
								className={s.input}
								type="text"
								placeholder={'Confirm password'}
								// value={value}
								// onChange={(e) => setValue(e.target.value)}
							/>

							<button
								// style={{backgroundColor: value ? '#1893D2' : '#8E8E93'}}
								className={s.confirm__button}
								type={'submit'}
								onClick={() => {
									// if (Object.keys(errors).length) {
									// 	setModalActive(true);
									// }
								}}
							>Change password</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WebProfilePage;
