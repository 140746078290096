import React, {useEffect, useState} from 'react';
import './Transaction.css';

import goBackArrow from "../../../assets/arrows/blue-left-arrow.png";
import crossIcon from "../../../assets/close.png";

import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";

import {qrCodeActions} from "../../../action/";
import {setMessageActive} from "../../../redux/action-creators";

import HeaderMobile from "../../headers/HeaderMobile/HeaderMobile";
import CategorySelector from "../../selectors/CategorySelector";
import Modal from "../../Modal/Modal";

const Transaction = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const customerMobilePhone = localStorage.getItem('customerMobilePhone');
    const paymentTypeByPhone = localStorage.getItem('paymentTypeByPhone');
    const vendorTokenLocal = localStorage.getItem('vendorToken');
    // const vendorTokenLocal = '404|z5SOQQyd90ltrmjoRrMfykU8wycaPI6UuJFXqXGC';

    // localStorage.removeItem('customerMobilePhone');
    // localStorage.removeItem('paymentTypeByPhone');

    // const deviceId = localStorage.getItem('vendorDeviceId');
    // const deviceId = 'a8b76b89-a1bc-4361-818a-50cab764f46d';

    const vendor = useSelector(({vendorReducer : {currentVendor}}) => currentVendor);
    // const vendor = {
    //     id: 172,
    //     tin: "123456",
    //     name: "Front",
    //     url: "www.dummy.com",
    //     main_tel: "+18089242461",
    //     contact_lname: null,
    //     contact_fname: null,
    //     contact_email: "yaroshvolodymyr24@gmail.com",
    //     contact_mob_tel: "+18089242461",
    //     contact_work_tel: "+18089242461",
    //     mrbe_id: 2175,
    //     invite_customer_mrbe_id: null,
    //     mrbe_status: 1,
    //     created_at: "2021-12-09T10:14:55.000000Z",
    //     updated_at: "2021-12-09T10:14:55.000000Z",
    //     auth_code: "MYGzKqgflErg1BB",
    //     vma: {
    //         id: 53,
    //         vma_id: 1037,
    //         vendor_id: 2175,
    //         device_id: "31736352E8734888812C70102999406D",
    //         location_entity_id: 1054,
    //         is_refund: 1,
    //         auth_code: "MYGzKqgflErg1BB",
    //         created_at: "2021-12-09T10:14:56.000000Z",
    //         updated_at: "2021-12-09T10:14:56.000000Z"
    //     }
    // }
    // console.log('V E N D O R : ', vendor);

    // const [transactionData, setTransactionData] = useState({});
    const [modalActive, setModalActive] = useState(false);
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState({});

    const [rewardsError, setRewardsError] = useState('');
    const [rewardsModalActive, setRewardsModalActive] = useState(false);

    const [phoneErrorModalActive, setPhoneErrorModalActive] = useState(false);

    // const vendorToken = useSelector(({vendorReducer: {vendorToken}}) => vendorToken);
    const vendorToken = '185|vwU15Y6rVmuTtLTyw75ljQYM3zTY8teEcVr5cDnY';
    const result = useSelector(({QRReducer: {result}}) => result);

    // const [sendData, setSendData] = useState(false);

    const { register, handleSubmit } = useForm({
        mode: 'onBlur'
    });

    // const [payRewards, setPayRewards] = useState({});
    // const [payCash, setPayCash] = useState({});
    // const [mrbeResponseRewards, setMrbeResponseRewards] = useState('');
    // const [mrbeResponseCash, setMrbeResponseCash] = useState('');
    // const [cashData, setCashData] = useState(null);
    // const [rewardsData, setRewardsData] = useState(null);

    const [qrCodeTransactionType, setQrCodeTransactionType] = useState(null);
    const [auth_code, setAuth_code] = useState(null);
    const [location_entity_id, setLocation_entity_id] = useState(null);
    const [device_id, stDevice_id] = useState(null);
    const [customer_id, setCustomer_id] = useState(null);

    useEffect(() => {
        if (vendor) {
            setAuth_code(vendor.vma.auth_code);
            setLocation_entity_id(vendor.vma.location_entity_id);
            stDevice_id(vendor.vma.device_id);
        }

        if (result) {
            setQrCodeTransactionType(result.transactionType);
            setCustomer_id(result.customerId);

            // const resultNew = [...result];
            // const data = ['', '', '', ''];
            // let i = 0;
            // resultNew.map(item => {
            //     if (item !== ',') {
            //         data[i] += item;
            //         return;
            //     }
            //
            //     i++
            // });
            //
            // const qrTime = Date.now() - data[1];
            //
            // if (qrTime > 180000) {
            //     i = 0;
            //     data[0] = '';
            //     data[1] = '';
            //     data[2] = '';
            //     data[3] = '';
            //
            //     return;
            // }
            //
            // setQrCodeTransactionType(data[0]);
            // setCustomer_id(data[2]);
            //
            // i = 0;
            // data[0] = '';
            // data[1] = '';
            // data[2] = '';
            // data[3] = '';
        }

        if (paymentTypeByPhone) {
            setQrCodeTransactionType(paymentTypeByPhone);
        }

        if (vendorToken) {

            if (customerMobilePhone) {
                const data = {
                    customerMobilePhone: customerMobilePhone
                }

                qrCodeActions.getQRCodeVendor(vendorToken, data)
                    .then(({data}) => {
                        setCategories(data.data.categories);
                        setCustomer_id(data.data.customer.id);
                    })
                    .catch(() => {
                        setPhoneErrorModalActive(true);
                    });

            } else {
                qrCodeActions.getQRCodeVendor(vendorToken, null)
                    .then(({data}) => {
                        setCategories(data.data.categories);
                    })
            }
        }

    }, [customerMobilePhone, paymentTypeByPhone, result,  vendorToken, vendor]);

    const confirmAmount = (data) => {
        if (Object.keys(category).length === 0) {
            setModalActive(true);
            return
        }

        const qrCodeGetPurchaseData = {
            qrCodeTransactionType: qrCodeTransactionType,
            auth_code: auth_code,
            location_entity_id: location_entity_id,
            device_id: device_id,
            customer_id: customer_id
        }

        if (qrCodeTransactionType === 'payedWithCashOrCredit') {
            qrCodeGetPurchaseData.amount_paid = parseInt(data.amount)
            qrCodeGetPurchaseData.title = category.name
            qrCodeGetPurchaseData.categories =  {'1' : category.id}


            qrCodeActions.qrCodeGetPurchase(qrCodeGetPurchaseData, vendorTokenLocal)
                .then(({data}) => {
                    qrCodeActions.mrbeApplyJob({jobId: data.job_id})
                        .then(({data}) => {
                            if (data.ok) {
                                localStorage.removeItem('customerMobilePhone');
                                localStorage.removeItem('paymentTypeByPhone');

                                dispatch(setMessageActive(true));
                                history.push('/');
                            }
                        });
                });
        }

        if (qrCodeTransactionType === 'payedWithRewards') {
            qrCodeGetPurchaseData.bonuses = parseInt(data.amount);
            qrCodeGetPurchaseData.title = category.name;
            qrCodeGetPurchaseData.categories =  {'1' : category.id};

            qrCodeActions.qrCodeGetPurchaseRewards(qrCodeGetPurchaseData, vendorTokenLocal)
                .then(({data}) => {
                    if (data.message) {
                        setRewardsError(data.message);
                        setRewardsModalActive(true);
                        return;
                    }

                    setRewardsError('');

                    qrCodeActions.mrbeApplyJob({jobId: data.job_id})
                        .then(({data}) => {
                            if (data.ok) {
                                localStorage.removeItem('customerMobilePhone');
                                localStorage.removeItem('paymentTypeByPhone');

                                dispatch(setMessageActive(true));
                                history.push('/');
                            }
                        });
                });
        }
    }

    return (
        <div className={'transaction-wrapper'}>
            <HeaderMobile />

            <div className={'transaction-inner'}>
                <div className={'transaction-title'}>
                    <img
                        src={goBackArrow}
                        alt="go back arrow"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    <div>Transaction</div>
                    <div/>
                </div>

                <div className={'transaction-label'}>
                    <div>Please choose a category of the</div>
                    <div>transaction</div>
                </div>

                <div className={'transaction-selector'}>
                    <CategorySelector
                        category={category}
                        categories={categories}
                        setCategory={setCategory}
                    />
                </div>

                <div className={'transaction-label'}>
                    <div>Please enter the amount of the</div>
                    <div>transaction</div>
                </div>

                <form noValidate={true} onSubmit={handleSubmit(confirmAmount)}>
                    <input
                        {...register('amount', {required: true})}
                        className={'transaction-input'}
                        type="number"
                        placeholder={'Enter amount'}
                    />

                    <button type={'submit'} className={'transaction-button active'}>Confirm</button>
                </form>
            </div>

            <Modal active={modalActive} setActive={setModalActive}>
                <div className={'vendor-errors'}>
                    <img
                        src={crossIcon}
                        alt="cross"
                        onClick={() => {
                            setModalActive(false);
                        }}
                    />

                    <div>
                        <span>Error</span>

                        <div>Please choose category</div>
                    </div>
                </div>
            </Modal>

            <Modal active={rewardsModalActive} setActive={setRewardsModalActive}>
                <div className={'vendor-errors'}>
                    <img
                        src={crossIcon}
                        alt="cross"
                        onClick={() => {
                            setRewardsModalActive(false);
                        }}
                    />

                    <div>
                        <span>Error</span>

                        <div>{rewardsError}</div>
                    </div>
                </div>
            </Modal>

            <Modal active={phoneErrorModalActive} setActive={setPhoneErrorModalActive} isPhoneError={true}>
                <div className={'vendor-errors'}>
                    <img
                        src={crossIcon}
                        alt="cross"
                        onClick={() => {
                            setRewardsModalActive(false);
                            history.goBack();
                        }}
                    />

                    <div>
                        <span>Error</span>

                        <div>Sorry, user with this mobile phone does not exist.</div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Transaction;
