import React, {useCallback, useState} from 'react';
import './Activities.css';

import filterIcon from '../../assets/filter.png';

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import FilteredByTypeActivities from "../FiltredActivities/FilteredByTypeActivities";
import NfActivities from "../FiltredActivities/NfActivities";
import FilteredByRewards from "../FiltredActivities/FilteredByRewards";
import FReviewed from "../FiltredActivities/FReviewed";
import FPending from "../FiltredActivities/FPending";
import FRefund from "../FiltredActivities/FRefund";
import FResidual from "../FiltredActivities/FResidual";
import FRewards from "../FiltredActivities/FRewards";
import FilteredByFunds from "../FiltredActivities/FilteredByFunds";
import goBackArrow from '../../assets/arrows/blue-left-arrow.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const ActivitiesList = ({vendor}) => {
	const history = useHistory();
	const [filterActive, setFiltersActive] = useState(false);

	const [showReview, setShowReview] = useState(false);
	const [showPending, setShowPending] = useState(false);
	const [showRefund, setShowRefund] = useState(false);
	const [showInvites, setShowInvites] = useState(false);
	const [showRewards, setShowRewards] = useState(false);
	const [showReviews, setShowReviews] = useState(false);
	const [showFunds, setShowFunds] = useState(false);

	const filtersByUser = () => {
		if (vendor) {
			return (
				<>
					<div
						className={'activities__filter-option'}
						onClick={() => {
							// filterActivities('complete');
						}}
					>Completed</div>

					<div
						className={'activities__filter-option'}
						onClick={() => {
							// filterActivities('pending');
						}}
					>To be reviewed</div>

					<div
						className={'activities__filter-option'}
						onClick={() => {
							// filterByRating();
						}}
					>Rating</div>
				</>
			);
		}

		return (
			<>
				{/*<div className={'activities__filter-option'}>Added Funds</div>*/}
				<div
					className={'activities__filter-option'}
					onClick={() => {
						setShowFunds(true);

						setShowReview(false);
						setShowPending(false);
						setShowRefund(false);
						setFiltersActive(false);
						setShowInvites(false);
						setShowReviews(false);
						setShowRewards(false);
					}}
				>Added Funds</div>
				<div
					className={'activities__filter-option'}
					onClick={() => {
						setShowReview(false);
						setShowPending(false);
						setShowRefund(false);
						setFiltersActive(false);
						setShowInvites(false);
						setShowReviews(false);
						setShowFunds(false);

						setShowRewards(true);
					}}
				>Bonus Rewards</div>
				<div
					className={'activities__filter-option'}
					onClick={() => {
						setShowReview(false);
						setShowPending(false);
						setFiltersActive(false);
						setShowInvites(false);
						setShowRewards(false);
						setShowReviews(false);
						setShowFunds(false);

						setShowRefund(true);
					}}
				>Refunds</div>
				<div
					className={'activities__filter-option'}
					onClick={() => {
						setShowPending(false);
						setShowRefund(false);
						setFiltersActive(false);
						setShowInvites(false);
						setShowRewards(false);
						setShowReviews(false);
						setShowFunds(false);

						setShowReview(true);
					}}
				>Reviewed</div>
				<div
					className={'activities__filter-option'}
					onClick={() => {
						setShowReview(false);
						setShowPending(false);
						setShowRefund(false);
						setFiltersActive(false);
						setShowRewards(false);
						setShowReviews(false);
						setShowFunds(false);

						setShowInvites(true);
					}}
				>Reward for invites</div>
				<div
					className={'activities__filter-option'}
					onClick={() => {
						setShowReview(false);
						setShowPending(false);
						setShowRefund(false);
						setFiltersActive(false);
						setShowInvites(false);
						setShowRewards(false);
						setShowFunds(false);

						setShowReviews(true);
					}}
				>Rewards for reviews </div>
				<div
					className={'activities__filter-option'}
					onClick={() => {
						setShowReview(false);
						setShowRefund(false);
						setFiltersActive(false);
						setShowInvites(false);
						setShowRewards(false);
						setShowReviews(false);
						setShowFunds(false);

						setShowPending(true);
					}}
				>Waiting for reviews</div>
			</>
		);
	}

	const act = () => {
		if (showFunds) return  <FilteredByFunds/>
		if (showReview) return  <FReviewed />
		if (showPending) return <FPending />
		if (showRefund) return <FRefund />
		if (showInvites) return <FResidual />
		if (showReviews) return <FRewards />
		if (showRewards) return <FilteredByRewards />

		return <NfActivities />
	}

	return (
		<div className={'activities-wrapper'}>
			<HeaderMobile />

			<div className={'activities-inner'}>
				<div className={'activities-inner__nav'}>
				<img
				    className='activity_go_back'
					src={goBackArrow}
					alt="go back arrow"
					onClick={() => {
						history.goBack();
					}}
					/>
					<div className={'activities__title'}>My activities</div>
					<div>
						<img
							src={filterIcon}
							alt="filters for activities"
							onClick={() => setFiltersActive(true)}
						/>
					</div>
				</div>

				<div className={filterActive ? 'activities__filter active' : 'activities__filter'}>
					<div className={'activities__inner'}>
						<div className={'activities__filter-title'}>Sort by:</div>

						{filtersByUser()}

						<div
							className={'activities__filter-option clear'}
							onClick={() => {
								setShowReview(false);
								setShowPending(false);
								setShowRefund(false);
								setShowInvites(false);
								setShowRewards(false);
								setShowReviews(false);
								setShowFunds(false);
								setFiltersActive(false);
							}}
						>Clear All</div>

						<div
							className={'activities__filter-cancel'}
							onClick={() => {
								setFiltersActive(false);
							}}
						>Cancel</div>
					</div>
				</div>

				{act()}
			</div>
		</div>
	);
};

export default ActivitiesList;
