import React, {useState} from 'react';
import './Account.css';

import goBackArrow from "../../assets/arrows/blue-left-arrow.png";
import crossIcon from "../../assets/close.png";

import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {EditCustomerSchema} from '../../validators';

import {customerActions} from '../../action';
import {setAccountEdited, setCurrentCustomer} from "../../redux/action-creators";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import Modal from "../Modal/Modal";

const EditAccount = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const customer = useSelector(({customerReducer : {currentCustomer}}) => currentCustomer);

    // const token = localStorage.getItem('customerToken');
    const token = useSelector(({customerReducer: {customerToken}}) => customerToken);

    const [modalActive, setModalActive] = useState(false);

    const defaultValues = {
        firstname: customer.first_name,
        lastname: customer.last_name,
        phone: customer.phone,
        areacode: customer.pc
    }

    const { register, handleSubmit, formState: {errors} } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(EditCustomerSchema),
        defaultValues: defaultValues
    });

    const editUser = (data) => {
        setModalActive(false);

        const phone = data.phone;

        customerActions.updateCustomer(data, token)
            .then(({data}) => {
                if (data.ok) {
                    if (phone === defaultValues.phone) {
                        dispatch(setAccountEdited(true));
                        history.push('/account');
                        customerActions.getCustomer(token)
                            .then(({data}) => {
                                dispatch(setCurrentCustomer(data.data.user));
                            });
                    } else {
                        history.push('/confirm-phone');
                    }
                }
            });
    }

    return (
        <div className={'account__wrapper'}>
            <HeaderMobile />

            <div className={'account__inner'}>
                <div className={'account__title sub'}>
                    <img
                        src={goBackArrow}
                        alt="go back arrow"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    Edit account
                    <div />
                </div>

                <form noValidate={true} onSubmit={handleSubmit(editUser)}>
                    <input
                        className={'account__form-input'}
                        {...register('firstname', {required: false})}
                        type="text"
                        id={'firstname'}
                        placeholder={'First Name'}
                    />

                    <input
                        className={'account__form-input'}
                        {...register('lastname', {required: false})}
                        type="text"
                        id={'lastname'}
                        placeholder={'First Name'}
                    />

                    <input
                        className={'account__form-input'}
                        {...register('phone', {required: false})}
                        type="phone"
                        id={'phone'}
                        placeholder={'Phone'}
                    />

                    <input
                        className={'account__form-input'}
                        {...register('areacode', {required: false})}
                        type="text"
                        id={'areacode'}
                        placeholder={'Zip Code'}
                    />

                    <button
                        type={"submit"}
                        className={'account__button active'}
                        onClick={() => {
                            if (Object.keys(errors).length) {
                                setModalActive(true);
                            }
                        }}
                    >Save</button>
                </form>
            </div>

            <Modal active={modalActive} setActive={setModalActive}>
                <div className={'vendor-errors'}>
                    <img
                        src={crossIcon}
                        alt="cross"
                        onClick={() => {
                            setModalActive(false);
                        }}
                    />

                    <div>
                        <span>Error</span>

                        {errors?.firstname &&
                        <div>{errors.firstname.message}</div>}
                        {errors?.lastname &&
                        <div>{errors.lastname.message}</div>}
                        {errors?.phone &&
                        <div>{errors.phone.message}</div>}
                        {errors?.areacode &&
                        <div>{errors.areacode.message}</div>}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default EditAccount;
