import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";

const Loader = () => {
    return (
        <ClipLoader color={'#1893D2'} loading={true} size={100}/>
    );
};

export default Loader;
