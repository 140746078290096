import React from 'react';
import s from './MainPage.module.scss';

import {Route, Switch} from "react-router-dom";
import {useSelector} from "react-redux";

import useWindowDimensions from "../hooks/windowDimensions.hook";

import RewardsBalance from "../components/rewards/RewardsBalance";

import Invite from "../components/invites/Invite";
import InviteBusiness from "../components/invites/InviteBusiness";
import InviteFriend from "../components/invites/InviteFriend";

import Receiving from '../components/rewards/Receiving';
import Rewards from "../components/rewards/Rewards";
import ReturnPurchase from "../components/rewards/ReturnPurchase";

import SalesIntro from "../components/vendor-sales/SalesIntro/SalesIntro";
import ReturnScan from "../components/vendor-sales/Return/ReturnScan";
import ConfirmReturn from "../components/vendor-sales/Return/ConfirmReturn";

import Transaction from "../components/vendor-sales/Transaction/Transaction";
import ActivitiesList from "../components/Activities/ActivitiesList";

import FindCustomer from "../components/vendor-sales/FindCustomer/FindCustomer";
import Account from "../components/Account/Account";
import EditAccount from "../components/Account/EditAccount";
import AddFunds from "../components/Account/AddFunds";
import PaymentConfirmation from "../components/Account/PaymentConfirmation";
import ChangePassword from "../components/Account/ChangePassword";
import ConfirmPhone from "../components/Account/ConfirmPhone";
import ChangePasswordNew from "../components/Account/ChangePasswordNew";
import ActivityDetails from "../components/Activities/ActivityDetails";
import ReturnActivities from "../components/Account/ReturnActivities";
import LeaveReview from "../components/Reviews/LeaveReview";
import Reviewed from "../components/Reviews/Reviewed";

import Directory from "../components/Directory/Directory";
import Providers from "../components/Providers/Providers";
import ProviderDetails from "../components/Providers/ProviderDetails";
import FavoriteProviders from "../components/Providers/FavoriteProviders";
import MyReviews from "../components/Reviews/MyReviews";
import CustomersList from "../components/Customers/CustomersList";
import SubCategoriesList from "../components/Directory/SubCategoriesList";
import VendorAccount from "../components/Account/VendorAccount";
import VendorReviews from "../components/Reviews/VendorReviews";
import Review from '../components/Reviews/Review';
import SeeReviews from "../components/Providers/SeeReviews";
import Header from "../components/headers/Header/Header";
import WebHomePage from "../WEB-pages/WebHomePage";
import WebFooter from "../WEB-components/WebFooter/WebFooter";
import WebActivitiesPage from "../WEB-pages/WebActivitiesPage";
import WebActivityDetails from '../WEB-components/Activity/WebActivityDetails';
import WebDirectoryPage from '../WEB-pages/WebDirectoryPage';
import WebProfilePage from "../WEB-pages/WebProfilePage";
import WebCustomers from "../WEB-components/WebCustomers/WebCustomers";

const MainPage = () => {
	const customerToken = useSelector(({customerReducer: {customerToken}}) => customerToken);
	const vendorToken = useSelector(({vendorReducer: {vendorToken}}) => vendorToken);
	const { width } = useWindowDimensions();
	const isMobileScreen = width < 600;

	if (isMobileScreen) {
		if (customerToken) {
			return (
				<Switch>
					<Route path={'/reviews'} exact component={SeeReviews}/>
					<Route path={'/customers/:id'} exact component={CustomersList}/>

					<Route path={'/provider/:id'} exact component={ProviderDetails}/>
					<Route path={'/providers/:id'} exact component={Providers}/>
					<Route path={'/favorite-providers'} exact component={FavoriteProviders}/>

					<Route path={'/categories-sub/:name/:id'} exact component={SubCategoriesList}/>
					<Route path={'/directory'} exact component={Directory}/>

					<Route path={'/my-reviews'} exact component={MyReviews}/>
					<Route path={'/reviewed'} exact component={Reviewed}/>
					<Route path={'/leave-review/:id'} exact component={LeaveReview}/>

					<Route path={'/return-activities'} exact component={ReturnActivities}/>
					<Route path={'/confirm-phone'} exact component={ConfirmPhone}/>
					<Route path={'/set-new-password'} exact component={ChangePasswordNew}/>
					<Route path={'/change-password'} exact component={ChangePassword}/>
					<Route path={'/payment-confirmation'} exact component={PaymentConfirmation}/>
					<Route path={'/add-funds'} exact component={AddFunds}/>
					<Route path={'/account-edit'} exact component={EditAccount}/>
					<Route path={'/account'} exact component={Account}/>

					<Route path={'/activity-details/:id'} exact component={ActivityDetails}/>
					<Route path={'/activities'} exact component={ActivitiesList}/>
					<Route path={'/return-purchase/:id'} exact component={ReturnPurchase}/>
					<Route path={'/rewards'} exact component={Rewards}/>
					<Route path={'/receiving'} exact component={Receiving}/>

					<Route path={'/invite/friend/:id'} exact component={InviteFriend} />
					<Route path={'/invite/business'} exact component={InviteBusiness} />
					<Route path={'/invite'} exact component={Invite}/>

					<Route path={'/'} exact component={RewardsBalance}/>
					<Route path={'*'} component={RewardsBalance}/>
				</Switch>
			);
		}

		if (vendorToken) {
			return (
				<Switch>
					<Route path={'/provider/:id'} exact component={ProviderDetails}/>
					<Route path={'/providers/:id'} exact component={Providers}/>

					<Route path={'/categories-sub/:name/:id'} exact component={SubCategoriesList}/>
					<Route path={'/directory'} exact>
						<Directory vendor={true} />
					</Route>

					<Route path={'/review/:id'} component={Review}/>
					<Route path={'/my-reviews'} component={VendorReviews}/>

					<Route path={'/account'} component={VendorAccount}/>

					<Route path={'/find-customer'} component={FindCustomer}/>
					<Route path={'/transaction'} component={Transaction}/>

					<Route path={'/activity-details/:id'}>
						<ActivityDetails vendor={true} />
					</Route>
					<Route path={'/activities'}>
						<ActivitiesList vendor={true} />
					</Route>

					<Route path={'/confirm-return'} exact component={ConfirmReturn}/>
					<Route path={'/scan-return'}>
						<ReturnScan isReturn={true}/>
					</Route>
					<Route path={'/scan-receiving'} component={ReturnScan}/>

					<Route path={'/'} component={SalesIntro}/>
					<Route path={'*'} component={SalesIntro}/>
				</Switch>
			);
		}
	}

	return (
		<>
			<div className={s.main_page}>
				<Header />

				<Switch>
					<Route path={'/customers/:id'} component={WebCustomers} />

					<Route path={'/activities/:id/:jobID'} component={WebActivityDetails}/>

					<Route path={'/profile'} component={WebProfilePage} />
					<Route path={'/directory'} component={WebDirectoryPage} />

					<Route path={'/activities'} component={WebActivitiesPage}/>
					<Route path={'/'} component={WebHomePage}/>
					{/*<Route path={'*'} component={WebHomePage}/>*/}
				</Switch>
			</div>

			<WebFooter />
		</>
	);
};

export default MainPage;
