import * as yup from "yup";

const PhoneRegisterSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .required("Phone number is a required field")
    .matches(
      /^((8|\+7)[ ]?)?(\(?\d{3}\)?[ ]?)?[\d\- ]{7,10}$/,
      "Enter correct phone number"
    ),
});

export { PhoneRegisterSchema };
