import React, { useState } from 'react';
import './Auth.css';

import backArrow from '../../../assets/arrows/white-left-arrow.png';

import {useHistory} from "react-router-dom";

import Login from "../LogIn/Login";
import SignUp from "../SignUp/SignUp";
import AuthMobileHeader from "../../headers/AuthMobileHeader/AuthMobileHeader";
import EmailVerify from "../EmailVerify/EmailVerify";
import Verification from "../Verification/Verification";
import ForgottenPassword from "../ForgottenPassword/ForgottenPassword";
import CreateNewPassword from "../CreateNewPassword/CreateNewPassword";

const Auth = ({isMobile, verification, passwordEmail, newPassword}) => {
    const history = useHistory();
    const [logIn, setLogIn] = useState(true);
    const [isCreated, setIsCreated] = useState(false);
    const [isRestore, setIsRestore] = useState(false);

    const selectForm = () => {
        if (newPassword) return <CreateNewPassword />;

        if (passwordEmail) return <EmailVerify resetPassword={true} isWeb={true} />;

        if (isRestore) return <ForgottenPassword setIsRestore={setIsRestore} />;

        if (isCreated) return <EmailVerify />;

        if (verification) return <Verification />;

        if (logIn) return <Login setIsRestore={setIsRestore} />;

        return <SignUp setIsCreated={setIsCreated} />;
    }

    return (
        <>
            {isMobile && <AuthMobileHeader />}

            <div className={'auth'}>
                <div className="auth__inner">
                    <div className="auth__buttons">
                        <img
                            className={'auth__buttons__arrow'}
                            src={backArrow}
                            alt="back arrow"
                            onClick={() => {
                                history.goBack();
                            }}
                        />

                        <button
                            className={logIn ? 'auth__buttons-login active' : 'auth__buttons-login'}
                            onClick={() => setLogIn(true)}
                        >Log in</button>

                        <button
                            className={logIn ? 'auth_buttons-signup' : 'auth_buttons-signup active'}
                            onClick={() => setLogIn(false)}
                        >Sign Up</button>
                    </div>

                    {selectForm()}
                </div>
            </div>
        </>
    );
};

export default Auth;
