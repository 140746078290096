import React from 'react';
import './ContactMessage.css';

import goBackArrow from '../../../assets/arrows/blue-left-arrow.png';

import {useHistory} from "react-router-dom";

const ContactMessage = () => {
    const history = useHistory();

    return (
        <div className={'cm-wrapper'}>
            <div className={'cm-inner'}>
                <img
                    src={goBackArrow}
                    alt="go back arrow"
                    onClick={() => {
                        history.push('/vendor');
                    }}
                />

                <div className={'cm-message'}>
                    Thank you! Our team will contact you shortly
                </div>
            </div>
        </div>
    );
};

export default ContactMessage;
