import React, {useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

import useRoutes from './routes';
import useAuth from "./hooks/auth.hook";

import OneSignal from "react-onesignal";
import ScrollTop from "./components/ScrollTop/ScrollTop";

function App() {
    const isAuthenticated = useAuth();
    const routes = useRoutes(isAuthenticated);

    // console.log('window.location.host',window.location.host)

    useEffect(() => {
        OneSignal.init({
            appId: "f8f461bf-9f8f-46e6-9745-83abf5bcb3c0",
            safari_web_id: "",
            notifyButton: {
                enable: true,
            },
            subdomainName: "morewards",
        });
    }, []);

    return (
        <Router>
            <ScrollTop />
            {routes}
        </Router>
    );
}

export default App;
