import * as yup from 'yup';

const EditCustomerSchema = yup.object().shape({
    firstname: yup
        .string()
        .required()
        // .when('firstname', {
        //     is: true,
        //     then: yup.string()
                .max(50, 'First name must be at most 50 characters')
                .matches(/^([A-Z]{1}[a-z]{1,23})$/,
                    'First name should contain only 1 uppercase and no characters other than letters'),
        // }),

    lastname: yup
        .string()
        .required()
        // .when('lastname', {
        //     is: true,
        //     then: yup.string()
                .max(50, 'Last name must be at most 50 characters')
                .matches(/^([A-Z]{1}[a-z]{1,23})$/,
                    'Last name should contain only 1 uppercase and no characters other than letters'),
        // }),

    phone: yup
        .string()
        .required()
        // .when('phone', {
        //     is: true,
        //     then: yup.string()
                .matches(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/, 'Enter correct phone number'),
        // }),

    areacode: yup
        .string()
        .required()
        // .when('phone', {
        //     is: true,
        //     then: yup.string()
                .matches(/^[a-zA-Z0-9]*$/),
        // }),
}, [
    ['phone', 'phone'],
    ['lastname', 'lastname'],
    ['firstname', 'firstname'],
]);

export {
    EditCustomerSchema
};
