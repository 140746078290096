import React, {useState} from 'react';
import './Account.css';

import goBackArrow from "../../assets/arrows/blue-left-arrow.png";
import crossIcon from "../../assets/close.png";

import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";

import {passwordActions} from '../../action';

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import Modal from "../Modal/Modal";
import {useSelector} from "react-redux";

const ChangePassword = () => {
    const history = useHistory();

    // const token = localStorage.getItem('customerToken');
    const token = useSelector(({customerReducer: {customerToken}}) => customerToken);

    const [modalActive, setModalActive] = useState(false);

    const { register, handleSubmit } = useForm({
        mode: 'onBlur'
    });

    const changePassword = (data) => {
        passwordActions.passwordIsVerify(data, token)
            .then(({data}) => {

                if (data.data.success) {
                    history.push('/set-new-password');
                }

                if (!data.ok) {
                    setModalActive(true);
                }
            });
    }

    return (
        <div className={'account__wrapper'}>
            <HeaderMobile />

            <div className={'account__inner'}>
                <div className={'account__title sub'}>
                    <img
                        src={goBackArrow}
                        alt="go back arrow"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    Change password
                    <div />
                </div>

                <div className={'account__inner-subtitle'}>
                    Please enter your current password:
                </div>

                <form noValidate onSubmit={handleSubmit(changePassword)}>
                    <input
                        className={'account__form-input'}
                        {...register('password', {required: true})}
                        type="password"
                        id={'password'}
                        placeholder={'Current password'}
                    />

                    <button type={"submit"} className={'account__button active'}>Continue</button>
                </form>
            </div>

            <Modal active={modalActive} setActive={setModalActive}>
                <div className={'vendor-errors'}>
                    <img
                        src={crossIcon}
                        alt="cross"
                        onClick={() => {
                            setModalActive(false);
                        }}
                    />

                    <div>
                        <span>Error</span>

                        <div>Wrong password</div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ChangePassword;
