import React from 'react';
import './Directory.css';

import arrowIcon from '../../assets/arrows/right-arrow-small.svg';
import {useHistory} from "react-router-dom";

const Category = ({category, isSubCategory}) => {
    const history = useHistory();

    if (isSubCategory) {
        return (
            <div
                className={'directory__category'}
                onClick={() => {
                    history.push(`/providers/${category.id}`);
                }}
            >
                {category.name}
                <img src={arrowIcon} alt="arrow"/>
            </div>
        );
    }

    return (
        <div
            className={'directory__category'}
            onClick={() => {
                if (category.has_subcategories) history.push(`/categories-sub/${category.name}/${category.id}`);
            }}
        >
            {category.name}
            <img src={arrowIcon} alt="arrow"/>
        </div>
    );
};

export default Category;
