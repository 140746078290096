import React, { useEffect, useRef, useState } from "react";
import s from "./WebDirectoryPage.module.css";

import searchIcon from "../assets/search.svg";
import radiusSelectedIcon from "../assets/search-radius.svg";

import { getBrowserLocation } from "../helpers/currentGeolocation";

import WebCategories from "../WEB-components/WebCategories/WebCategories";
import CategoryProviders from "../WEB-components/WebCategories/CategoryProviders";
import SubCategories from "../WEB-components/WebCategories/SubCategories";
import Popup from "../components/Popup/Popup";
import RadiusSearch from "../WEB-components/RadiusSearch/RadiusSearch";
import ProvidersByRadius from "../WEB-components/WebCategories/ProvidersByRadius";
import ProvidersByZipCode from "../WEB-components/WebCategories/ProvidersByZipCode";
import backArrow from "../assets/arrows/blue-left-arrow.png";

const WebDirectoryPage = () => {
  const [searchByProviderName, setSearchByProviderName] = useState(false);
  const [searchByCategoryName, setSearchByCategoryName] = useState(true);
  const [searchByZIP, setSearchByZIP] = useState(false);
  const [searchByLocation, setSearchByLocation] = useState(false);

  const [radiusModalActive, setRadiusModalActive] = useState(false);
  const [selectedRadius, setSelectedRadius] = useState(0);

  const [zipCodeSelectedRadius, setZipCodeSelectedRadius] = useState(0);
  const [zipCodeModalActive, setZipcodeModalActive] = useState(false);
  const [zipCode, setZipCode] = useState("");

  // const [howcategories, setShowCategories] = useState(true);

  const [searchBySubCategoryName, setSearchBySubCategoryName] = useState(false);
  const [parentCategoryId, setParentCategoryId] = useState(null);

  const [prevCategoryId, setPrevCategoryId] = useState(null);

  const [searchValue, setSearchValue] = useState("");

  // const [showCategoryProviders, setShowCategoryProviders] = useState(false);
  const [showCategoryProvidersID, setShowCategoryProvidersID] = useState(null);

  const sInput = useRef();

  const [userLocation, setUserLocation] = useState({});
  useEffect(() => {
    getBrowserLocation()
      .then((currentLocation) => {
        setUserLocation(currentLocation);
      })
      .catch((defaultLocation) => {
        setUserLocation(defaultLocation);
      });
  }, []);

  useEffect(() => {
    console.log(prevCategoryId);
    setPrevCategoryId(parentCategoryId);
  }, [parentCategoryId]);

  return (
    <div className={"web-container"}>
      <div className={s.directory__title}>Directory</div>

      <div className={s.directory__search}>
        <input
          ref={sInput}
          type="text"
          placeholder={"Search"}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />

        <img
          src={searchIcon}
          alt="search category"
          onClick={() => {
            sInput.current.focus();
          }}
        />
      </div>

      <div className={s.search_options}>
        <button
          className={
            searchByProviderName
              ? `${s.search__option} ${s.active}`
              : s.search__option
          }
          onClick={() => {
            setSearchValue("");
            setSearchByProviderName(true);
            setShowCategoryProvidersID(null);

            setSearchByCategoryName(false);
            setSearchByZIP(false);
            setSearchByLocation(false);
            setSearchBySubCategoryName(false);
            setParentCategoryId(null);
          }}
        >
          Search by provider name
        </button>

        <button
          className={
            searchByCategoryName || searchBySubCategoryName
              ? `${s.search__option} ${s.active}`
              : s.search__option
          }
          onClick={() => {
            setSearchValue("");
            setSearchByCategoryName((prev) => !prev);

            setSearchByProviderName(false);
            setSearchByZIP(false);
            setSearchByLocation(false);
          }}
        >
          Search by category name
        </button>

        <button
          className={
            searchByZIP ? `${s.search__option} ${s.active}` : s.search__option
          }
          onClick={() => {
            setSearchByZIP(true);

            setZipcodeModalActive(true);

            setSearchByCategoryName(false);
            setSearchByProviderName(false);
            setSearchByLocation(false);
            setSearchBySubCategoryName(false);
          }}
        >
          Search by ZIP
        </button>

        <button
          className={
            searchByLocation
              ? `${s.search__option} ${s.active}`
              : s.search__option
          }
          onClick={() => {
            setSearchByLocation(true);

            // show popup with search radius select
            setRadiusModalActive(true);

            setSearchByZIP(false);
            setSearchByCategoryName(false);
            setSearchByProviderName(false);
          }}
        >
          {selectedRadius ? (
            <>
              Search by current location: {selectedRadius}{" "}
              <img src={radiusSelectedIcon} alt="" />
            </>
          ) : (
            "Search by current location"
          )}
        </button>
      </div>

      {searchByCategoryName && (
        <WebCategories
          searchValue={searchValue}
          setSearchByCategoryName={setSearchByCategoryName}
          setSearchBySubCategoryName={setSearchBySubCategoryName} ///////////////////////////
          setParentCategoryId={setParentCategoryId}
          // setSearchByProviderName={setSearchByProviderName}
          // setShowCategoryProvidersID={setShowCategoryProvidersID}
        />
      )}

      {!searchByZIP && !searchByCategoryName && searchBySubCategoryName && (
        <SubCategories
          setSearchBySubCategoryName={setSearchBySubCategoryName} ////////////////////////
          parentCategoryId={parentCategoryId}
          searchValue={searchValue}
          setSearchByProviderName={setSearchByProviderName}
          setShowCategoryProvidersID={setShowCategoryProvidersID}
          setSearchByCategoryName={setSearchByCategoryName}
        />
      )}

      {!searchBySubCategoryName && searchByProviderName && (
        <CategoryProviders
          searchValue={searchValue}
          setShowCategoryProvidersID={showCategoryProvidersID}
          setSearchBySubCategoryName={setSearchBySubCategoryName}
          setParentCategoryId={setParentCategoryId}
          prevCategoryId={prevCategoryId}
        />
      )}

      {searchByLocation && (
        <ProvidersByRadius
          searchRadius={selectedRadius}
          userLocation={userLocation}
        />
      )}

      {searchByZIP && (
        <ProvidersByZipCode
          zipCode={zipCode}
          userLocation={userLocation}
          zipCodeSelectedRadius={zipCodeSelectedRadius}
        />
      )}

      <Popup
        popupActive={radiusModalActive}
        setPopupActive={setRadiusModalActive}
      >
        <RadiusSearch
          selectedRadius={selectedRadius}
          setSelectedRadius={setSelectedRadius}
        />
      </Popup>

      <Popup
        popupActive={zipCodeModalActive}
        setPopupActive={setZipcodeModalActive}
      >
        <RadiusSearch
          selectedRadius={zipCodeSelectedRadius}
          setSelectedRadius={setZipCodeSelectedRadius}
          isZip={true}
          zipCode={zipCode}
          setZipCode={setZipCode}
          setZipcodeModalActive={setZipcodeModalActive}
        />
      </Popup>
    </div>
  );
};

export default WebDirectoryPage;
