import React from 'react';

import backArrow from "../../assets/arrows/blue-left-arrow.png";

import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import ReviewCard from "./ReviewCard";

const SeeReviews = () => {
	const history = useHistory();
	const reviews = useSelector(({reviewsReducer: {reviews}}) => reviews);

	return (
		<div className={'providers__wrapper'}>
			<HeaderMobile />

			<div className={'providers__inner'}>
				<div className={'providers__title-wrapper'}>
					<img
						src={backArrow}
						alt="go back arrow"
						onClick={() => {
							history.goBack();
						}}
					/>
					<div className={'providers__title sub'}>Reviews</div>
					<div />
				</div>

				{reviews.map(review => {
					return (
						<ReviewCard review={review} key={review.id} />
					);
				})}
			</div>
		</div>
	);
};

export default SeeReviews;
