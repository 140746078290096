import React from 'react';
import s from './WebFooter.module.scss';

import appleStoreIcon from '../../assets/footer-icons/apple-store.svg';
import googlePlayIcon from '../../assets/footer-icons/google-play.svg';

const WebFooter = () => {
	return (
		<footer className={s.footer}>
			<div className={s.footer__title}>UNLOCK PROFITS EVERYWHERE.</div>
			<div className={s.footer__subtitle}>Join MORewards now!</div>

			<div className={s.footer__buttons}>
				<a className={s.footer__button} href="#">
					<img src={appleStoreIcon} alt="apple store"/>

					<div>
						<div className={s.footer_button__download}>DOWNLOAD FROM</div>
						<div className={s.footer_button__store}>APPLE STORE</div>
					</div>
				</a>

				<a className={s.footer__button} href="#">
					<img src={googlePlayIcon} alt="google store"/>

					<div>
						<div className={s.footer_button__download}>DOWNLOAD FROM</div>
						<div className={s.footer_button__store}>GOOGLE PLAY</div>
					</div>
				</a>
			</div>

			{/*<div className={s.footer__links}>*/}
			{/*	<Link to={'/'}>Home</Link>*/}
			{/*	<Link to={'/activities'}>Activities</Link>*/}
			{/*	<Link to={'/directory'}>Directory</Link>*/}
			{/*</div>*/}

		</footer>
	);
};

export default WebFooter;
