import React, { useEffect, useState } from "react";
import s from "./ProviderWithMap.module.css";

import phoneIcon from "../../assets/providers-icons/provider-phone.svg";
import emailIcon from "../../assets/providers-icons/provider-email.svg";
import globeIcon from "../../assets/providers-icons/provider-globe.svg";
import shareIcon from "../../assets/providers-icons/provider-share.svg";
import heartIcon from "../../assets/providers-icons/empty-heart.svg";
import heartFavoriteIcon from "../../assets/providers-icons/heart-favorite.svg";

import StarRatings from "react-star-ratings";

import { providerActions } from "../../action";
import { useHistory } from "react-router-dom";

import SmallMap from "../SmallMap/SmallMap";

import moment from "moment";

const ProviderWithMap = ({
  provider,
  isActivity,
  noShare,
  jobID,
  jobStatus,
  date,
  showMap = true,
  setAddFavorite,
  addFavorite,
}) => {
  const activityDate = moment(date).format("MMM Do");

  const history = useHistory();
  const customerTokenLocal = localStorage.getItem("customerToken");

  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    if (provider?.isFavorite) setIsFavorite(true);
  }, [isFavorite, provider?.isFavorite]);

  const addOrRemoveProviderFromFavorites = () => {
    providerActions
      .addProviderToFavorite(customerTokenLocal, provider?.id)
      .then((response) => {
        if (response.ok) {
          setIsFavorite(!isFavorite);
          setAddFavorite(!addFavorite);
        }
      });
  };

  return (
    <div
      className={isActivity ? `${s.pwm_wrapper} ${s.long}` : s.pwm_wrapper}
      onClick={() => {
        if (noShare) return;
        history.push(`/activities/${provider?.id}/${jobID}`);
      }}
    >
      <div className={s.pwm_inner}>
        <div className={s.pwm_title}>
          {provider?.name ? provider?.name : provider?.title}
        </div>

        <div className={s.pwm_rating}>
          <StarRatings
            rating={
              provider?.ratingInStars || provider?.avgRatingInStars
                ? provider?.ratingInStars || provider?.avgRatingInStars
                : 0
            }
            starRatedColor="rgba(255, 188, 58, 1)"
            numberOfStars={5}
            starSpacing={"4px"}
            isSelectable={false}
            name="rating"
            svgIconViewBox={"0 0 15 14"}
            svgIconPath={
              "M6.54894 0.927048C6.8483 0.00573778 8.1517 0.0057404 8.45106 0.927051L9.40837 3.87336C9.54224 4.28538 9.9262 4.56434 10.3594 4.56434H13.4574C14.4261 4.56434 14.8289 5.80395 14.0451 6.37336L11.5389 8.19427C11.1884 8.44892 11.0417 8.90028 11.1756 9.31231L12.1329 12.2586C12.4323 13.1799 11.3778 13.946 10.5941 13.3766L8.08778 11.5557C7.7373 11.3011 7.2627 11.3011 6.91221 11.5557L4.40594 13.3766C3.62222 13.946 2.56774 13.1799 2.8671 12.2586L3.82441 9.3123C3.95828 8.90028 3.81162 8.44892 3.46114 8.19427L0.954859 6.37335C0.171145 5.80395 0.573923 4.56434 1.54265 4.56434H4.64057C5.0738 4.56434 5.45776 4.28538 5.59163 3.87336L6.54894 0.927048Z"
            }
          />

          <span>
            {provider?.reviewsCount || provider?.avgReviewsCount
              ? provider?.reviewsCount || provider?.avgReviewsCount
              : 0}{" "}
            reviews
          </span>
        </div>

        {jobID && (
          <div className={s.id_status}>
            {jobID}: {jobStatus}
          </div>
        )}

        <div className={s.activity_date}>{activityDate}</div>

        <div className={s.pwm_icons}>
          <div>
            <a
              href={`tel:${provider?.contact_work_tel}`}
              onClick={(e) => e.stopPropagation()}
            >
              <img src={phoneIcon} alt="phone" />
            </a>

            <a
              href={`mailto:${provider?.contact_email}`}
              onClick={(e) => e.stopPropagation()}
            >
              <img src={emailIcon} alt="email" />
            </a>

            <a
              href={provider?.url}
              rel="noreferrer"
              target={"_blank"}
              onClick={(e) => e.stopPropagation()}
            >
              <img src={globeIcon} alt="globe" />
            </a>

            <img
              src={isFavorite ? heartFavoriteIcon : heartIcon}
              alt="favorite provider"
              onClick={(e) => {
                e.stopPropagation();
                addOrRemoveProviderFromFavorites();
              }}
            />
          </div>

          <img
            style={{ cursor: "pointer" }}
            src={shareIcon}
            alt="share provider"
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/customers/${provider?.id}`);
            }}
          />
        </div>
      </div>

      {showMap && (
        <div onClick={(e) => e.stopPropagation()}>
          <SmallMap
            isActivity={isActivity}
            center={{
              lat: parseFloat(provider?.locations[0]?.lat),
              lng: parseFloat(provider?.locations[0]?.lon),
            }}
          />
        </div>
      )}

      {/*lat: parseFloat(provider.locations[0]?.lat),*/}
      {/*lng: parseFloat(provider.locations[0]?.lon)*/}
    </div>
  );
};

export default ProviderWithMap;
