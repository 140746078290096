import {SET_VENDOR_TOKEN, SET_CURRENT_VENDOR} from "../action-types";

const initialState = {
    vendorToken: null,
    currentVendor: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VENDOR_TOKEN: {
            return {
                ...state,
                vendorToken: action.payload
            }
        }

        case SET_CURRENT_VENDOR: {
            return {
                ...state,
                currentVendor: action.payload
            }
        }

        default: return state;
    }
};

export default reducer;
