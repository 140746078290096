import React, { useEffect, useRef, useState } from "react";

import { getDistance } from "geolib";
import { getGeocode, getLatLng } from "use-places-autocomplete";

import { useSelector } from "react-redux";
import { useObserver } from "../../hooks/useObserver";

import { providerActions } from "../../action";

import Loader from "../Loader/Loader";
import Provider from "../Providers/Provider";

const ProvidersBySearch = ({
  searchProvider,
  userLocation,
  searchRadius,
  zipSearchRadius,
  zipCodeInputValue,
}) => {
  // console.log('providers search user location', userLocation)
  // console.log('search radius : ', searchRadius)

  // const customerTokenLocal = localStorage.getItem('customerToken');
  const customerTokenLocal = useSelector(
    ({ customerReducer: { customerToken } }) => customerToken
  );

  const [isLoading, setIsLoading] = useState(false);

  const [providers, setProviders] = useState([]);
  // const [radiusProviders, setRadiusProviders] = useState([]);

  const lastElement = useRef();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useObserver(lastElement, page <= totalPages, isLoading, () => {
    setPage(page + 1);
  });

  useEffect(() => {
    setPage(1);
    setProviders([]);
  }, [zipCodeInputValue]);

  useEffect(() => {
    setPage(1);
    setProviders([]);
    // setRadiusProviders([]);
  }, [searchRadius, zipSearchRadius]);

  // useEffect(() => {
  //     if (clearProviders) setProviders([]);
  // }, [clearProviders]);

  useEffect(() => {
    setIsLoading(true);

    providerActions
      .getAllProviders(customerTokenLocal, page)
      .then((response) => {
        // console.log('providers: ', response);

        setTotalPages(response.data.last_page);

        if (searchRadius > 0) {
          const providersByRadius = response.data.data.filter((provider) => {
            let isInRadius = false;

            if (provider.locations.length !== 0) {
              provider.locations.forEach((location) => {
                const distance = Math.floor(
                  getDistance(userLocation, {
                    lat: parseFloat(location.lat),
                    lng: parseFloat(location.lon),
                  }) * 0.000621371192
                );

                if (searchRadius > distance) isInRadius = true;
              });
            }

            return isInRadius;
          });

          setProviders([...providers, ...providersByRadius]);
          // setRadiusProviders([...radiusProviders, ...providersByRadius])
          setIsLoading(false);

          return;
        }

        if (zipSearchRadius > 0 && zipCodeInputValue.length) {
          getGeocode({ address: zipCodeInputValue })
            .then((result) => {
              return getLatLng(result[0]);
            })
            .then(({ lat, lng }) => {
              const providersByRadius = response.data.data.filter(
                (provider) => {
                  let isInRadius = false;

                  if (provider.locations.length !== 0) {
                    provider.locations.forEach((location) => {
                      const distance = Math.floor(
                        getDistance(
                          { lat: lat, lng: lng },
                          {
                            lat: parseFloat(location.lat),
                            lng: parseFloat(location.lon),
                          }
                        ) * 0.000621371192
                      );

                      if (zipSearchRadius > distance) isInRadius = true;
                    });
                  }

                  return isInRadius;
                }
              );

              setProviders([...providers, ...providersByRadius]);
              setIsLoading(false);
            })
            .catch(() => {
              setProviders([]);
              setIsLoading(false);
            });

          return;
        }

        setProviders([...providers, ...response.data.data]);
        setIsLoading(false);

        // google.maps.geometry.spherical.computeDistanceBetween(userLocation, userLocation, 15000)
      });
  }, [
    customerTokenLocal,
    page,
    searchRadius,
    zipCodeInputValue,
    zipSearchRadius,
  ]);

  const filterProviders = () => {
    return providers.filter((category) => {
      if (searchProvider === "") return category;
      return category.name
        .toLowerCase()
        .includes(searchProvider.trim().toLowerCase());
    });
  };

  return (
    <div style={{ width: "100%" }}>
      {filterProviders().map((provider) => (
        <Provider
          key={provider.id}
          id={provider.id}
          rating={provider.ratingInStars ? provider.ratingInStars : 0}
          categories={[]}
          name={provider.name}
          reviews={provider.reviewsCount}
          address={provider?.locations[0]?.add_1}
          isFavorite={true}
          // removed={removed}
          // setRemoved={setRemoved}
        />
      ))}

      {isLoading && (
        <div className={"center-loader"}>
          <Loader />
        </div>
      )}
      {!isLoading && <div ref={lastElement} />}
    </div>
  );
};

export default ProvidersBySearch;
