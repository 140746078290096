import React from "react";
import "./Activities.css";

import moment from "moment";
import StarRatings from "react-star-ratings";

const Details = ({ activity, vendor }) => {
  const date = moment(activity.job?.created_at).format("MMM Do");

  if (vendor) {
    return (
      <div className={"details__wrapper"}>
        <div className={"details__title"}>Transaction ID</div>
        <div className={"details__text"}>{activity.job?.id}</div>

        <div className={"details__title"}>Date</div>
        <div className={"details__text"}>{date}</div>

        <div className={"details__title"}>Status</div>
        <div className={"details__text"}>{activity.job?.status}</div>

        <div className={"details__title"}>Category</div>
        <div className={"details__text"}>{activity.job?.title}</div>

        <div className={"details__title"}>Amount of transaction</div>
        <div className={"details__text"}>{activity.job?.amount_paid}</div>

        <div className={"details__title"}>Payment</div>
        <div className={"details__text"}>{activity.job?.payment}</div>

        <div className={"details__title"}>Rating</div>
        <StarRatings
          rating={activity.job?.rating ? activity.job?.rating : 0}
          starRatedColor="rgba(255, 188, 58, 1)"
          numberOfStars={5}
          starSpacing={"2px"}
          isSelectable={false}
          name="rating"
          svgIconViewBox={"0 0 15 14"}
          svgIconPath={
            "M6.54894 0.927048C6.8483 0.00573778 8.1517 0.0057404 8.45106 0.927051L9.40837 3.87336C9.54224 4.28538 9.9262 4.56434 10.3594 4.56434H13.4574C14.4261 4.56434 14.8289 5.80395 14.0451 6.37336L11.5389 8.19427C11.1884 8.44892 11.0417 8.90028 11.1756 9.31231L12.1329 12.2586C12.4323 13.1799 11.3778 13.946 10.5941 13.3766L8.08778 11.5557C7.7373 11.3011 7.2627 11.3011 6.91221 11.5557L4.40594 13.3766C3.62222 13.946 2.56774 13.1799 2.8671 12.2586L3.82441 9.3123C3.95828 8.90028 3.81162 8.44892 3.46114 8.19427L0.954859 6.37335C0.171145 5.80395 0.573923 4.56434 1.54265 4.56434H4.64057C5.0738 4.56434 5.45776 4.28538 5.59163 3.87336L6.54894 0.927048Z"
          }
        />
      </div>
    );
  }

  return (
    <div className={"details__wrapper"}>
      <div className={"details__title"}>Job ID</div>
      <div className={"details__text"}>{activity.job?.id}</div>

      <div className={"details__title"}>Date</div>
      <div className={"details__text"}>{date}</div>

      <div className={"details__title"}>Status</div>
      <div className={"details__text"}>{activity.job?.status}</div>

      <div className={"details__title"}>Vendor</div>
      <div className={"details__text"}>{activity.vendor.name}</div>

      <div className={"details__title"}>Category</div>
      <div className={"details__text"}>{activity.job?.title}</div>

      <div className={"details__title"}>Cost</div>
      <div className={"details__text"}>{activity.job?.amount_paid}</div>

      <div className={"details__title"}>Transaction Type</div>
      <div className={"details__text"}>
        {activity.job?.payment === "Reward"
          ? "Paid with reward"
          : activity.job?.payment === "Cash/credit" && "Paid with cash/credit"}
      </div>
    </div>
  );
};

export default Details;
