import React, {useEffect, useState} from 'react';
import s from './InviteNewUser.module.css';

import closeIcon from '../../assets/close-icon.svg';
import inviteIcon from '../../assets/invite-new-user-web.svg';
import shareCodeIcon from '../../assets/invite/share.png';
import sadSmileIcon from '../../assets/invite/sad-smile.svg';

import {useSelector} from "react-redux";
import {inviteActions} from '../../action';

import Popup from "../../components/Popup/Popup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {emailInvite, phoneInvite} from "../../validators";
// import {setProviderInviteSent} from "../../redux/action-creators";

const InviteNewUser = ({setInviteActive}) => {
	const customerTokenLocal = localStorage.getItem('customerToken');

	const [inviteCode, setInviteCode] = useState('');

	const [inviteBusinessActive, setInviteBusinessActive] = useState(false);
	const [inviteFriendActive, setInviteFriendActive] = useState(false);

	const [popupActive, setPopupActive] = useState(false);

	const friendInvitesLeft = useSelector(({customerReducer: {friendInvitesLeft}}) => friendInvitesLeft);

	useEffect(() => {
		inviteActions.getInviteCode(customerTokenLocal)
			.then(response => {
				setInviteCode(response.data.code[0]);
			})

	}, [customerTokenLocal]);

	const emailForm = useForm({
		mode: 'onBlur',
		resolver: yupResolver(emailInvite),
	});

	const phoneForm = useForm({
		mode: 'onBlur',
		resolver: yupResolver(phoneInvite),
	});

	const sendInvite = (data) => {
		const inviteData = {
			identifier: data.email ? data.email : data.phone,
			type: data.email ? 1 : 2
		}

		inviteActions.sendInvite(inviteData, customerTokenLocal)
			.then(() => {
				setPopupActive(false);

				// if (response.ok) {
				// 	dispatch(setProviderInviteSent(true));
				// 	setShareActive(false);
				//
				// 	return;
				// }
				//
				// if (!response.ok) {
				// 	setInviteErrorMessage(response.message);
				// 	setInviteErrorModalActive(true);
				// }
			})
			.catch(e => {
			})
	}

	const isInvites = () => {
		if (friendInvitesLeft) return <>
			<div className={s.inu__invite_title}>Your friend invitation code:</div>
			<div className={s.inu__invite_code}>
				{inviteCode}
				<img
					src={shareCodeIcon}
					alt="share invite code"
					onClick={() => setPopupActive(true)}
				/>
			</div>
		</>

		return <>
			<div className={s.inu__no_invites}>
				<img src={sadSmileIcon} alt="no invites"/>
				No code available
			</div>
			<div className={s.inu__first}>Please, invite a business first!</div>
		</>
	}

	const inviteType = () => {
		if (inviteBusinessActive) return <>
			<div className={s.inu__invite_title}>Your business invitation code:</div>
			<div className={s.inu__invite_code}>
				{inviteCode}
				<img
					src={shareCodeIcon}
					alt="share invite code"
					onClick={() => setPopupActive(true)}
				/>
			</div>
		</>

		if (inviteFriendActive) return <>
			{isInvites()}
		</>
	}

	return (
		<>
			<div className={s.inu__title}>
				Invite new user
				<img
					src={closeIcon}
					alt="close"
					onClick={() => {
						setInviteActive(false);
					}}
				/>
			</div>

			<div className={s.inu__options}>
				<div>
					<img src={inviteIcon} alt="invite new user" className={s.inu__icon}/>

					<div>
						<div>Invite a business and friends to join</div>
						<div>Morewards and earn rewards credits!</div>
					</div>
				</div>

				<div>
					<button
						className={inviteBusinessActive ? `${s.inu__button} ${s.active}` : s.inu__button}
						onClick={() => {
							setInviteFriendActive(false);
							setInviteBusinessActive(true);
						}}
					>Invite a business</button>
					<button
						className={inviteFriendActive ? `${s.inu__button} ${s.active}` : s.inu__button}
						onClick={() => {
							setInviteBusinessActive(false);
							setInviteFriendActive(true);
						}}
					>Invite a business</button>
				</div>
			</div>

			{(inviteBusinessActive || inviteFriendActive) &&
				<div className={s.inu_code__wrapper}>
					{inviteType()}
				</div>
			}

			<Popup popupActive={popupActive} setPopupActive={setPopupActive} >
				<div className={s.inu__forms_wrapper}>
					<form noValidate={true} onSubmit={emailForm.handleSubmit(sendInvite)}>
						{emailForm.formState.errors?.email &&<div className={'invite__form-error'}>
							{emailForm.formState.errors.email.message}
						</div>}
						<input
							className={s.inu__input}
							type="email"
							placeholder={'Email'}
							{...emailForm.register('email', {required: false})}
						/>
						<button
							className={s.inu__share_button}
						>Invite by email</button>
					</form>

					<div className={s.inu__or}>OR</div>

					<form noValidate={true} onSubmit={phoneForm.handleSubmit(sendInvite)}>
						{phoneForm.formState.errors?.phone &&<div className={'invite__form-error'}>
							{phoneForm.formState.errors.phone.message}
						</div>}
						<input
							className={s.inu__input}
							type="phone"
							placeholder={'Phone number'}
							{...phoneForm.register('phone', {required: false})}
						/>
						<button
							className={s.inu__share_button}
						>Invite by phone number</button>
					</form>
				</div>
			</Popup>
		</>
	);
};

export default InviteNewUser;
