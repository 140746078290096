import React, {useEffect, useRef, useState} from 'react';
import './Customers.css';

import searchIcon from '../../assets/search.svg';
import infoIcon from "../../assets/info.png";
import crossIcon from "../../assets/close.png";
import goBackArrow from "../../assets/arrows/blue-left-arrow.png";

import {useHistory, useParams} from "react-router-dom";

import {useObserver} from "../../hooks/useObserver";
import {customerActions} from "../../action";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import Customer from "./Customer";
import Loader from "../Loader/Loader";
import Modal from "../Modal/Modal";
import {useSelector} from "react-redux";

const CustomersList = () => {
    const history = useHistory();

    const {id} = useParams();
    const [value, setValue] = useState('');

    // const customerTokenLocal = localStorage.getItem('customerToken');
    const customerTokenLocal = useSelector(({customerReducer: {customerToken}}) => customerToken);

    const [modalActive, setModalActive] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [providerSharedToUser, setProviderSharedToUser] = useState(false);

    const lastElement = useRef();
    const inputRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [customers, setCustomers] = useState([]);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useObserver(lastElement, page <= totalPages, isLoading,  () => {
        setPage(page + 1);
    });

    useEffect(() => {
        setIsLoading(true);

        customerActions.getAllCustomers(customerTokenLocal,page)
            .then(({data}) => {
                setTotalPages(data.last_page);

                setCustomers([...customers, ...data.data]);
                setIsLoading(false);
            });
    }, [customerTokenLocal, page]);

    useEffect(() => {
        if (providerSharedToUser) {
            setTimeout(() => {
                setProviderSharedToUser(false);
            }, 2000);
        }
    }, [providerSharedToUser]);

    const customerSearch = () => {
        return customers.filter((customer) => {
            if (!value) return customer;

            if (customer.first_name) {
                return customer.first_name.toLowerCase().includes(value.toLowerCase())
                    || customer.last_name.toLowerCase().includes(value.toLowerCase());
            }
        });
    }

    return (
        <>
            <div className={'customers__wrapper'}>
                <HeaderMobile/>

                <div className={'customers__inner'}>


                    <div className={'customers__title'}>
                        <img
                            src={goBackArrow}
                            alt="go back arrow"
                            onClick={() => {
                                history.goBack();
                            }}
                        />
                        <div>Share</div>
                        <div/>
                    </div>

                    <div className={'search__customer'}>
                        <input
                            ref={inputRef}
                            type="text"
                            value={value}
                            onChange={e => setValue(e.target.value)}
                        />
                        <img
                            src={searchIcon}
                            alt="search customer"
                            onClick={() => inputRef.current.focus()}
                        />
                        <span
                            onClick={() => setValue('')}
                        >Cancel</span>
                    </div>

                    {customerSearch().map(customer => <Customer
                        key={customer.id}
                        customer={customer}
                        vendorId={id}
                        setModalActive={setModalActive}
                        setErrorMessage={setErrorMessage}
                        setProviderSharedToUser={setProviderSharedToUser}
                    />)}

                    {isLoading && <div className={'load'}><Loader /></div>}
                    {!isLoading && <div ref={lastElement} />}

                </div>

                <Modal active={modalActive} setActive={setModalActive}>
                    <div className={'vendor-errors'}>
                        <img
                            src={crossIcon}
                            alt="cross"
                            onClick={() => {
                                setModalActive(false);
                            }}
                        />

                        <div>
                            <span>Error</span>

                            <div>{errorMessage}</div>
                        </div>
                    </div>
                </Modal>
            </div>

            <div className={providerSharedToUser
                ? 'sales-inner__message active share'
                : 'sales-inner__message'
            }>
                <img src={infoIcon} alt="info about transaction"/>

                <div className={'sales-inner__message-text'}>
                    <div>Success!</div>
                    <div>Vendor details were shared successfully</div>
                </div>
            </div>
        </>
    );
};

export default CustomersList;
