import * as yup from 'yup';

const VendorSignUpSchema = yup.object().shape({
    company_name: yup
        .string()
        .required('First name is a required field')
        .matches(/^[a-zA-Z0-9&]*$/),

    firstname: yup
        .string()
        .required('First name is a required field')
        .max(50, 'First name must be at most 50 characters')
        .matches(/^([A-Z]{1}[a-z]{1,23})$/,
            'First name should contain only 1 uppercase and no characters other than letters'),

    lastname: yup
        .string()
        .required('Last name is a required field')
        .max(50, 'Last name must be at most 50 characters')
        .matches(/^([A-Z]{1}[a-z]{1,23})$/,
            'Last name should contain only 1 uppercase and no characters other than letters'),

    phoneNumber: yup
        .string()
        .required('Phone number is a required field')
        .matches(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/, 'Enter correct phone number'),

    email: yup
        .string()
        .required('Email is a required field')
        .email(),

    referral_code: yup
        .string()
        .matches(/^[A-Za-z0-9]*$/, 'Referral code can only contain letters and digits')
});

export {
    VendorSignUpSchema
};
