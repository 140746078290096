import React, {useEffect, useState} from 'react';
import './Invite.css';

import inviteIcon from '../../assets/invite/invite.png';

import {useHistory} from "react-router-dom";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import {customerActions, inviteActions} from "../../action";
import Loader from "../Loader/Loader";
import {useSelector} from "react-redux";
import goBackArrow from '../../assets/arrows/blue-left-arrow.png';

const Invite = () => {
    const history = useHistory();

    const [invitesLeft, setInvitesLeft] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // const customerTokenLocal = localStorage.getItem('customerToken');
    const customerTokenLocal = useSelector(({customerReducer: {customerToken}}) => customerToken);


    useEffect(() => {
        setIsLoading(true);
        customerActions.getCustomer(customerTokenLocal)
            .then(({data}) => {

                setInvitesLeft(data.data.invitesLeft);
                setIsLoading(false);
            });
    }, [customerTokenLocal]);

    useEffect(() => {
        inviteActions.getInviteCode(customerTokenLocal)
            .then(response => {
                if (response.ok) {
                    const code = response.data.code[0]
                    localStorage.setItem('inviteCode', code);
                }
            });
    }, [customerTokenLocal]);

    return (
        <div className={'invite-wrapper'}>
            <HeaderMobile/>

            <div className={'invite-inner'}>
                <div className={'invite-title'}>
                <img
                    
                    src={goBackArrow}
                    alt="go back arrow"
                    onClick={() => {
                        history.goBack();
                    }}
                    />
                    <div>Invite</div>
                    <div />
                </div>

                <div className={'invite-subtitle'}>
                    <div>Invite a business and</div>
                    <div>friends to join Morewards</div>
                    <div>and earn rewards credits!</div>
                </div>

                <img className={'invite-img'} src={inviteIcon} alt="invite"/>

                {isLoading
                    ? <Loader />
                    : <>
                        <button
                            className={'invite-icon active business'}
                            onClick={() => {
                                history.push('/invite/business');
                            }}
                        >Invite a business</button>

                        <button
                            className={'invite-icon green'}
                            onClick={() => {
                                history.push(`/invite/friend/${invitesLeft}`);
                            }}
                        >Invite a friend</button>
                    </>
                }
            </div>
        </div>
    );
};

export default Invite;
