import React, { useEffect, useState } from "react";
import s from "./CategoryProviders.module.css";

import StarRatings from "react-star-ratings";

import phoneIcon from "../../assets/providers-icons/provider-phone.svg";
import emailIcon from "../../assets/providers-icons/provider-email.svg";
import globeIcon from "../../assets/providers-icons/provider-globe.svg";
import heartFavoriteIcon from "../../assets/providers-icons/heart-favorite.svg";
import heartIcon from "../../assets/providers-icons/empty-heart.svg";
import shareIcon from "../../assets/providers-icons/provider-share.svg";
import arrowIcon from "../../assets/category-provider-icon.svg";

import { providerActions } from "../../action";
import { useHistory } from "react-router-dom";

// auth_code: "AocNbdj1bvzJdkX"
// categories: (2) [{…}, {…}]
// contact_email: "nazar785@yopmail.com"
// contact_fname: null
// contact_lname: null
// contact_mob_tel: "+13460221091"
// contact_work_tel: "+13460221091"
// countryName: []
// created_at: "2021-11-16T18:59:19.000000Z"
// id: 165
// invite_customer_mrbe_id: null
// isFavorite: false
// locations: [{…}]
// main_tel: "+13460221091"
// mrbe_id: 2163
// mrbe_status: 1
// name: "QA"
// ratingInStars: 4.5
// reviews: (4) [{…}, {…}, {…}, {…}]
// reviewsCount: 4
// tin: "123456"
// updated_at: "2021-11-16T18:59:19.000000Z"
// url: "www.dummy.com"

const CategoryProvider = ({ provider, setProviders }) => {
  const history = useHistory();
  const customerTokenLocal = localStorage.getItem("customerToken");

  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    if (provider.isFavorite) setIsFavorite(true);
  }, [isFavorite, provider.isFavorite]);

  const addOrRemoveProviderFromFavorites = () => {
    providerActions
      .addProviderToFavorite(customerTokenLocal, provider.id)
      .then((response) => {
        if (response.ok) setIsFavorite(!isFavorite);
      });
    setProviders &&
      providerActions
        .getFavoriteProviders(customerTokenLocal)
        .then(({ data }) => {
          setProviders(data);
        });
  };

  const getAddress = () => {
    if (Array.isArray(provider?.locations)) {
      console.log();
      return provider?.locations?.add_1;
    }

    return provider?.location?.add_1;
  };
  return (
    <div
      className={s.category_provider}
      onClick={() => {
        history.push(`/activities/${provider.id}/0`);
      }}
    >
      <div className={s.category_provider__info}>
        <div>
          <div className={s.category_provider__name}>{provider.name}</div>
          <div className={s.category_provider__address}>{getAddress()}</div>
        </div>

        <div>
          <StarRatings
            rating={provider.ratingInStars ? provider.ratingInStars : 0}
            starRatedColor="rgba(255, 188, 58, 1)"
            numberOfStars={5}
            starSpacing={"4px"}
            isSelectable={false}
            name="rating"
            svgIconViewBox={"0 0 15 14"}
            svgIconPath={
              "M6.54894 0.927048C6.8483 0.00573778 8.1517 0.0057404 8.45106 0.927051L9.40837 3.87336C9.54224 4.28538 9.9262 4.56434 10.3594 4.56434H13.4574C14.4261 4.56434 14.8289 5.80395 14.0451 6.37336L11.5389 8.19427C11.1884 8.44892 11.0417 8.90028 11.1756 9.31231L12.1329 12.2586C12.4323 13.1799 11.3778 13.946 10.5941 13.3766L8.08778 11.5557C7.7373 11.3011 7.2627 11.3011 6.91221 11.5557L4.40594 13.3766C3.62222 13.946 2.56774 13.1799 2.8671 12.2586L3.82441 9.3123C3.95828 8.90028 3.81162 8.44892 3.46114 8.19427L0.954859 6.37335C0.171145 5.80395 0.573923 4.56434 1.54265 4.56434H4.64057C5.0738 4.56434 5.45776 4.28538 5.59163 3.87336L6.54894 0.927048Z"
            }
          />
          <div className={s.category_provider__rating}>
            {provider.reviewsCount} reviews
          </div>
        </div>
      </div>

      <div className={s.category_provider__img}>
        <div>
          <img src={phoneIcon} alt="phone" />
          <img src={emailIcon} alt="email" />
          <img src={globeIcon} alt="globe" />
          <img
            src={isFavorite ? heartFavoriteIcon : heartIcon}
            alt="favorite provider"
            onClick={(e) => {
              e.stopPropagation();
              addOrRemoveProviderFromFavorites();
            }}
          />
          <img
            src={shareIcon}
            alt="share provider"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              history.push(`/customers/${provider.id}`);
            }}
          />
        </div>

        <img src={arrowIcon} alt="" />
      </div>
    </div>
  );
};

export default CategoryProvider;
