import React, {useEffect, useState} from 'react';
import s from './InterestsProviders.module.css';

import {providerActions} from "../../action";
// import {getAllProviders} from "../../action/provider.action";
import ProviderWithMap from "../ProviderWithMap/ProviderWithMap";
import {useSelector} from "react-redux";

const InterestsProviders = ({mapActive, setMapActive, searchValue, showMap= true}) => {
	const customerToken = useSelector(({customerReducer: {customerToken}}) => customerToken);

	// const addOrRemoveProviderFromFavorites = () => {
	// 	providerActions.addProviderToFavorite(customerTokenLocal, id)
	// 		.then(response => {
	// 			if (response.ok) setIsFavorite(!isFavorite);
	// 		});
	// }

	const [providers, setProviders] = useState([]);
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (!customerToken) return;

		providerActions.getAllProviders(customerToken, page)
			.then(({data}) => {
				if (page > data.last_page) return;

				setProviders([...providers, ...data.data]);
				setPage(page + 1);
			});
	}, [customerToken,  page]);

	const filterProviders = () => {
		return providers.filter(provider => {
			if (!searchValue) return provider;
			return provider?.name?.toLowerCase().includes(searchValue.toLowerCase());
		});
	}

	return (
		<div className={s.interests_providers}>
			{filterProviders().map(provider => <ProviderWithMap key={provider.id} showMap={showMap} provider={provider} />)}

			{/*{!mapActive && <button*/}
			{/*	className={s.interests_providers__button}*/}
			{/*	onClick={() => {*/}
			{/*		setMapActive(true);*/}
			{/*	}}*/}
			{/*>View more on map</button>}*/}
		</div>
	);
};

export default InterestsProviders;
