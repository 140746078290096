import * as yup from 'yup';

const CustomerSignInSchema = yup.object().shape({
    username: yup
        .string()
        .required('Email is a required field')
        .email('Email should have correct format'),
    password: yup
        .string()
        .required('Password is a required field'),
});

export {
    CustomerSignInSchema
};
