import React, { useEffect, useRef, useState } from "react";
import { useObserver } from "../../hooks/useObserver";
import { jobActions } from "../../action";
import Activity from "../Activity/Activity";
import Loader from "../../components/Loader/Loader";

const WaitingForReviews = () => {
  const customerTokenLocal = localStorage.getItem("customerToken");

  const [isLoading, setIsLoading] = useState(false);

  const lastElement = useRef();
  const [page, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [jobs, setJobs] = useState([]);

  useObserver(lastElement, page <= totalPages, isLoading, () => {
    setCurrentPage(page + 1);
  });

  useEffect(() => {
    setIsLoading(true);

    jobActions.getJobs(page, customerTokenLocal).then(({ data }) => {
      setTotalPages(data.jobs.last_page);

      if (page >= data.jobs.last_page) {
        setIsLoading(false);
        return;
      }

      const reviewedActivitiesList = data.jobs.data.filter((job) => {
        return job.status === "pending";
      });

      if (!reviewedActivitiesList.length) {
        setCurrentPage(page + 1);
        return;
      }

      setJobs([...jobs, ...reviewedActivitiesList]);
      setIsLoading(false);
    });
  }, [customerTokenLocal, page]);

  return (
    <>
      {jobs.length > 0 ? (
        jobs.map((job) => <Activity key={job.id} job={job} />)
      ) : (
        <span className="nothing-text">Nothing has been found</span>
      )}
      {isLoading && (
        <div className={"lc"}>
          <Loader />
        </div>
      )}
      {!isLoading && <div ref={lastElement} />}
    </>
  );
};

export default WaitingForReviews;
