import React from 'react';
import SignUp from "../SignUp/SignUp";

const AuthMobile = () => {
    return (
        <div>
            <SignUp isMobile={true} />
        </div>
    );
};

export default AuthMobile;
