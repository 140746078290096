import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import AuthPage from './pages/AuthPage/AuthPage';
import MainPage from "./pages/MainPage";
import VendorAuth from "./pages/VendorAuth/VendorAuth";

const useRoutes = (isAuth) => {
    if (isAuth) {
        return (
            <Switch>
                <Route path={'/'}>
                    <MainPage />
                </Route>
                <Redirect to={'/'} />
            </Switch>
        );
    }

    if (window.location.host === 'morewards.com') return (
        <Switch>
            <Route path={'/'} component={<AuthPage />} />
            <Redirect to={'/'} />
        </Switch>
    );

    if (window.location.host === 'morewards.vendor.com') return (
        <Switch>
            <Route path={'/vendor'} component={<VendorAuth />} />
            <Redirect to={'/vendor'} />
        </Switch>
    );

    return (
        <Switch>
            <Route path={'/vendor'}>
                <VendorAuth />
            </Route>
            <Route path={'/'}>
                <AuthPage />
            </Route>
            <Redirect to={'/'} />
        </Switch>
    );
}

export default useRoutes;
