import {SET_CUSTOMER_TOKEN, SET_CURRENT_CUSTOMER, SET_FRIEND_INVITES_LEFT} from "../action-types";

const initialState = {
    customerToken: null,
    currentCustomer: null,
    friendInvitesLeft: 0
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CUSTOMER_TOKEN: {
            return {
                ...state,
                customerToken: action.payload
            }
        }

        case SET_CURRENT_CUSTOMER: {
           return {
                ...state,
                currentCustomer: action.payload
            }
        }

        case SET_FRIEND_INVITES_LEFT: {
            return {
                ...state,
                friendInvitesLeft: action.payload
            }
        }

        default: return state;
    }
};

export default reducer;
