import React, {useState} from 'react';

import goBackArrow from "../../../assets/arrows/blue-left-arrow.png";
import crossIcon from "../../../assets/close.png";

import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";

import {jobActions} from "../../../action";
import {setPurchaseReturned} from "../../../redux/action-creators";

import HeaderMobile from "../../headers/HeaderMobile/HeaderMobile";
import Modal from "../../Modal/Modal";

const ConfirmReturn = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const data = JSON.parse(localStorage.getItem('confirmReturn'))
    const vendorTokenLocal = localStorage.getItem('vendorToken');

    const [modalActive, setModalActive] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const confirmReturn = () => {
        jobActions.qrCodePurchaseReturn(data, vendorTokenLocal)
            .then(({data}) => {
                if (data.ok) {
                    localStorage.removeItem('confirmReturn');
                    dispatch(setPurchaseReturned(true));
                    history.push('/');
                }

                if (!data.ok) {
                    setErrorMessage(data?.message);
                    setModalActive(true);
                }
            });
    }

    return (
        <div className={'transaction-wrapper'}>
            <HeaderMobile />

            <div className={'transaction-inner'}>
                <div className={'transaction-title'}>
                    <img
                        src={goBackArrow}
                        alt="go back arrow"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    <div>Confirm return</div>
                    <div/>
                </div>

                <div className={'transaction-label'}>
                    <div>Please choose a category of the</div>
                    <div>transaction</div>
                </div>

                <input
                    readOnly
                    defaultValue={data.category}
                    className={'transaction-input'}
                    type="text"
                />

                <div className={'transaction-label'}>
                    <div>Please enter the amount of the</div>
                    <div>transaction</div>
                </div>

                <input
                    readOnly
                    defaultValue={data.amount_paid}
                    className={'transaction-input'}
                    type="text"
                />

                <button onClick={confirmReturn} className={'transaction-button active'}>Confirm</button>
            </div>

            <Modal active={modalActive} setActive={setModalActive}>
                <div className={'vendor-errors'}>
                    <img
                        src={crossIcon}
                        alt="cross"
                        onClick={() => {
                            setModalActive(false);
                        }}
                    />

                    <div>
                        <span>Error</span>

                        <div>{errorMessage}</div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ConfirmReturn;
