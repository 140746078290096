import * as yup from 'yup';

const ResetPasswordSchema = yup.object().shape({
    email: yup
        .string()
        .required('Email is a required field')
        .email('Email should have correct format')
});

const createNewPasswordSchema = yup.object().shape({
    password: yup
        .string()
        .required('Password is a required field')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            'Password should contain at least 1 uppercase, at least 1 digit and 1 special character, namely 1 out of 33 following characters: space ! " # $ % & \' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _` { | } ~);'
        ),

    confirmPassword: yup
        .string()
        .required('Confirm password is a required field')
        .oneOf([yup.ref('password')], 'Password do not match'),
});

export {
    ResetPasswordSchema,
    createNewPasswordSchema
};
