import React, {useEffect, useState} from 'react';
import './DeviceID.css';

import backArrow from '../../../assets/arrows/blue-left-arrow.png';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import {customAlphabet} from "nanoid";
import {useHistory} from "react-router-dom";

import Modal from "../../Modal/Modal";

const DeviceId = () => {
	const history = useHistory();
	const nanoid = customAlphabet('qwertyuiopasdfghjklzxcvbnm1234567890',32);

	const [modalActive, setModalActive] = useState(false);

	const vendorDeviceId = localStorage.getItem('vendorDeviceId');

	useEffect(() => {
		if (!localStorage.getItem('vendorDeviceId')) {
			const id = nanoid()
			const idArr = id.split('');

			idArr.splice(8, 0, '-');
			idArr.splice(13, 0, '-');
			idArr.splice(18, 0, '-');
			idArr.splice(23, 0, '-');

			const idStr = idArr.join('')
			localStorage.setItem('vendorDeviceId', idStr);
		}
	}, [nanoid]);

	return (
		<>
			<div className={'device-id-wrapper'}>
				<div className={'device-id-inner'}>

					<div className={'device-id-back-arrow'}>
						<img
							src={backArrow}
							alt="back arrow"
							onClick={() => {
								history.goBack();
							}}
						/>
					</div>

					<div className={'device-id-window'}>
						<div className={'device-id-window__title'}>Your device ID:</div>

						<div className={'device-id-ID'}>{vendorDeviceId ? vendorDeviceId : 'No device id'}</div>

						<div className={'device-id-window__buttons'}>
							<CopyToClipboard text={vendorDeviceId}>
								<button
									className={'device-id-window__buttons copy'}
									onClick={() => {
										setModalActive(true);
									}}
								>Copy</button>
							</CopyToClipboard>

							<button
								className={'device-id-window__buttons cancel'}
								onClick={() => {
									history.goBack();
								}}
							>Cancel</button>
						</div>
					</div>
				</div>
			</div>

			{modalActive &&
			<Modal active={modalActive} setActive={setModalActive}>
				<div className={'copied-message'}>
					{vendorDeviceId ? 'Your device ID was copied to the clipboard' : 'No device id'}
				</div>
			</Modal>
			}
		</>
	);
};

export default DeviceId;
