import React, { useEffect, useState } from "react";
import "./Directory.css";

import arrowIcon from "../../assets/arrows/blue-right-arrow.svg";
import searchIcon from "../../assets/directory/search.svg";
import filterIcon from "../../assets/directory/categories-filter.svg";
import searchArrowIcon from "../../assets/arrows/right-arrow-small.svg";
import goBackArrow from '../../assets/arrows/blue-left-arrow.png';

import { useHistory } from "react-router-dom";

import { categoriesActions } from "../../action";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import Category from "./Category";
import Loader from "../Loader/Loader";
import ProvidersBySearch from "./ProvidersBySearch";
import { getBrowserLocation } from "../../helpers/currentGeolocation";
import { useSelector } from "react-redux";

const Directory = ({ vendor }) => {
  const history = useHistory();

  const radiusEnums = [10, 20, 30, 40, 50, 60];

  // const customerTokenLocal = localStorage.getItem('customerToken');
  const customerTokenLocal = useSelector(
    ({ customerReducer: { customerToken } }) => customerToken
  );
  const vendorTokenLocal = localStorage.getItem("vendorToken");
  // const vendorTokenLocal = '384|eeu6jLfXXmZiI0MmU3iA79xmnM911zScjQ43Nx4c';

  const [categories, setCategories] = useState([]);
  const [searchCategory, setSearchCategory] = useState("");
  const [searchProvider, setSearchProvider] = useState("");

  // const [cancelVisible, setCancelVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isProvidersActive, setIsProvidersActive] = useState(false);

  const [filtersActive, setFiltersActive] = useState(false);
  const [searchByLocationActive, setSearchByLocationActive] = useState(false);
  const [searchByZipCodeActive, setSearchByZipCodeActive] = useState(false);
  const [radiusActive, setRadiusActive] = useState(false);
  const [miles, setMiles] = useState(0);
  const [radiusUnselectedError, setRadiusUnselectedError] = useState("");

  const [radioValue, setRadioValue] = useState(0);

  // const [searchRadius, setSearchRadius] = useState(0);
  const [userLocation, setUserLocation] = useState({});
  const [clearProviders, setClearProviders] = useState(false);

  const [zipSearch, setZipSearch] = useState(false);
  const [zipSearchRadius, setZipSearchRadius] = useState(0);
  const [zipCodeInputValue, setZipcodeInputValue] = useState("");

  const [isApply, setIsApply] = useState(false);

  useEffect(() => {
    if (zipSearchRadius && zipCodeInputValue.length >= 5)
      setIsProvidersActive(true);
  }, [zipSearchRadius, zipCodeInputValue]);

  const filterCategories = () => {
    return categories.filter((category) => {
      if (searchCategory === "") return category;
      return category.name.toLowerCase().includes(searchCategory.toLowerCase());
    });
  };

  useEffect(() => {
    setIsLoading(true);

    if (vendor) {
      categoriesActions
        .getCategoriesListParent(vendorTokenLocal)
        .then((response) => {
          if (response.ok) {
            setCategories(response.data);
            setIsLoading(false);
          }
        });

      return;
    }

    categoriesActions
      .getCategoriesListParent(customerTokenLocal)
      .then((response) => {
        if (response.ok) {
          setCategories(response.data);
          setIsLoading(false);
        }
      });
  }, [customerTokenLocal, vendorTokenLocal, vendor]);

  useEffect(() => {
    getBrowserLocation()
      .then((currentLocation) => {
        setUserLocation(currentLocation);
      })
      .catch((defaultLocation) => {
        setUserLocation(defaultLocation);
      });
  }, []);

  const isSearchByLocationActive = () => {
    if (searchByLocationActive)
      return (
        <>
          <div className={"search-option inactive"}>Search by my location</div>
          <div
            className={"search-option"}
            onClick={() => {
              setRadiusActive(true);
            }}
          >
            {miles ? miles : "Choose radius"}
            <img src={searchArrowIcon} alt="arrow" />
          </div>
        </>
      );
  };

  const isSearchByZipCodeActive = () => {
    if (searchByZipCodeActive)
      return (
        <>
          <input
            className={"search-option"}
            type="text"
            placeholder={"Search by ZIP code"}
            value={zipCodeInputValue}
            onChange={(e) => setZipcodeInputValue(e.target.value)}
          />
          <div
            className={"search-option"}
            onClick={() => {
              setRadiusActive(true);
            }}
          >
            {zipSearchRadius ? zipSearchRadius : "Choose radius"}
            <img src={searchArrowIcon} alt="arrow" />
          </div>
        </>
      );
  };

  const defaultSearchInputs = () => {
    if (!searchByLocationActive && !searchByZipCodeActive)
      return (
        <>
          <div
            className={"search-option"}
            onClick={() => {
              setSearchByLocationActive(true);
            }}
          >
            Search by my location
            <img src={searchArrowIcon} alt="arrow" />
          </div>

          <div
            className={"search-option"}
            onClick={() => {
              setSearchByZipCodeActive(true);
              setZipSearch(true);
            }}
          >
            Search by ZIP code
            <img src={searchArrowIcon} alt="arrow" />
          </div>
        </>
      );
  };

  const isFiltersActive = () => {
    if (filtersActive)
      return (
        <>
          <div className={0 ? "search-providers visible" : "search-providers"}>
            <input
              type="text"
              placeholder={"Search Provider by Name"}
              value={searchProvider}
              onChange={(e) => setSearchProvider(e.target.value)}
              onClick={() => {
                setIsApply(true);
                setIsProvidersActive(true);
              }}
            />
            <div
              onClick={() => {
                setIsProvidersActive(false);
                setIsApply(false);
                setSearchProvider("");
              }}
            >
              Cancel
            </div>
            <img src={searchIcon} alt="search" />
          </div>

          {defaultSearchInputs()}
          {isSearchByLocationActive()}
          {isSearchByZipCodeActive()}

          <button
            onClick={() => {
              setIsApply(true);
            }}
            className={"search_providers_apply"}
          >
            Apply
          </button>
        </>
      );
  };

  return (
    <div className={"directory__wrapper"}>
      <HeaderMobile />

      <div className={"directory__inner"}>
        <div className="inner-title">

      <img
          className="directory__title"
          src={goBackArrow}
          alt="go back arrow"
          onClick={() => {
            history.goBack();
          }}
          />
        <div className={"directory__title"}>Directory</div>
        <div></div>
          </div>

        <div className={"directory__search"}>
          <input
            type="text"
            placeholder={"Search categories"}
            onChange={(e) => setSearchCategory(e.target.value)}
          />

          <img className={"img-1"} src={searchIcon} alt="search" />
          <img
            className={"img-2"}
            src={filterIcon}
            alt="categories filter"
            onClick={(e) => {
              e.stopPropagation();
              setFiltersActive(!filtersActive);
              setSearchByLocationActive(false);
              setSearchByZipCodeActive(false);
            }}
          />
        </div>

        {isFiltersActive()}

        <div
          className={radiusActive ? "account__modal active" : "account__modal"}
          onClick={() => setRadiusActive(false)}
        >
          <div
            className={"account__modal-inner left"}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={"choose-radius"}>Choose radius</div>

            <ul>
              {radiusEnums.map((radius) => {
                return (
                  <li
                    className={"radius-li"}
                    key={radius}
                    onClick={() => {
                      setRadioValue(radius);
                    }}
                  >
                    <input
                      className={"radius-input"}
                      type="radio"
                      id={`radius-${radius}`}
                      name={"radio"}
                    />
                    <label
                      className={"radius-label"}
                      htmlFor={`radius-${radius}`}
                    >
                      {radius}
                    </label>
                  </li>
                );
              })}
            </ul>

            <div className={"choose-radius-save"}>
              <span>{radiusUnselectedError}</span>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div onClick={() => setRadiusActive(false)}>CANCEL</div>

                <div
                  onClick={() => {
                    if (radioValue === 0) {
                      setRadiusUnselectedError("Please select radius");
                      return;
                    }

                    if (zipSearch) {
                      setZipSearchRadius(radioValue);
                      setRadiusUnselectedError("");
                      setRadiusActive(false);

                      return;
                    }

                    setMiles(radioValue);
                    setRadiusUnselectedError("");
                    setRadiusActive(false);
                    setClearProviders(true);
                    setIsProvidersActive(true);
                  }}
                >
                  SAVE
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"directory__favorites"}>
          <div
            onClick={() => {
              history.push("/favorite-providers");
            }}
          >
            Show favorites
          </div>
          <img
            src={arrowIcon}
            alt="go to favorites categories"
            onClick={() => {
              history.push("/favorite-providers");
            }}
          />
        </div>

        {isLoading && <Loader />}

        {!isProvidersActive
          ? filterCategories().map((category) => (
              <Category key={category.id} category={category} />
            ))
          : !isProvidersActive &&
            !isLoading && (
              <span className="nothing-small-text">Nothing has been found</span>
            )}

        {isProvidersActive && isApply && (
          <ProvidersBySearch
            searchProvider={searchProvider}
            userLocation={userLocation}
            searchRadius={miles}
            clearProviders={clearProviders}
            zipSearchRadius={zipSearchRadius}
            zipCodeInputValue={zipCodeInputValue}
          />
        )}
      </div>
    </div>
  );
};

export default Directory;
