import React, { useEffect, useState } from "react";
import "./RewardBalance.css";

import qrCodaIcon from "../../assets/rewards/qr-code.svg";
import rewardsIcon from "../../assets/rewards/rewards.svg";
import directoryIcon from "../../assets/rewards/directory.svg";
import inviteIcon from "../../assets/rewards/invite.svg";
import logo from "../../assets/logos/MOR-logo.svg";
import helpSvg from '../../assets/tour/help.svg'

import { useHistory } from "react-router-dom/cjs/react-router-dom";

import { customerActions, uuidActions } from "../../action";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import Loader from "../Loader/Loader";
import OneSignal from "react-onesignal";
import { useSelector } from "react-redux";
import Tour from '../Tour/tour'
import { FadeLoader } from "react-spinners";

const RewardsBalance = () => {
  const history = useHistory();

  // const customerTokenLocal = localStorage.getItem('customerToken');
  const customerTokenLocal = useSelector(
    ({ customerReducer: { customerToken } }) => customerToken
  );

  const [customerName, setCustomerName] = useState("");
  const [balance, setBalance] = useState("");
  const [isTourActive , setIsTourActive] = useState(false);
  const [tourCount , setTourCount] = useState(1);

  useEffect(() => {
    customerActions.getCustomer(customerTokenLocal).then(({ data }) => {
      console.log(data.data);

      setBalance(data.data.balance.mrp_balance);
      setCustomerName(data.data.user.first_name);
    });
  }, [customerTokenLocal]);

  useEffect(() => {
    async function checkId() {
      const id = await OneSignal.getUserId();

      if (id) {
        uuidActions.setUuid(customerTokenLocal, { uuid: id });
      }
    }

    checkId();
  }, [customerTokenLocal]);

  const userInfo = () => {
    if (!customerName) {
      return <Loader />;
    }

    return (
      <>
        <div className={"rewards__title"}>Hello, {customerName}!</div>

        <div className={"rewards__subtitle"}>Your reward balance:</div>

        <div className={"rewards__balance"}>${balance ? balance : "0.00"}</div>
      </>
    );
  };

  useEffect(()=>{
    if(tourCount == 6){
      localStorage.removeItem('verification')
       setIsTourActive(false);
       setTourCount(1);
    }
  },[tourCount])

  useEffect(()=>{
     let localStorageFirstTime = localStorage.getItem('verification');
     console.log('local',typeof localStorageFirstTime)
     if(localStorageFirstTime == 'true'){
       setIsTourActive(true);
       setTourCount(1);
     }
  },[])

  return (
    <div className={"rewards-wrapper"}>
      <HeaderMobile isTourActive={isTourActive}/>

      <div className={"rewards-inner"}>
        <div className={`${isTourActive && tourCount == 5 ? 'position_bubble' : ''}`} style={{width:"100%" , padding:"0px 10px"}}>
          {isTourActive && tourCount == 5 && <Tour tourCount={tourCount} setTourCount={setTourCount}/>}
            <img onClick={()=>{
               setTourCount((count) => {
                  if(isTourActive){

                    if(count == 6){
                      return 1;
                    }
                    else{
                      return count+1;
                    }
                  }else{
                     return 1;
                  }
               });
               setIsTourActive(true);
            }} src={helpSvg} />
        </div>
        <img
          className={"rewards-inner__logo"}
          src={logo}
          alt="morewards logo"
        />

        {userInfo()}
        {/*{isLoader && <Loader />}*/}
        {/*{!isLoader &&*/}
        {/*    <>*/}
        {/*        <div className={'rewards__title'}>Hello, {customerName}!</div>*/}

        {/*        <div className={'rewards__subtitle'}>*/}
        {/*            Your reward balance:*/}
        {/*        </div>*/}

        {/*        <div className={'rewards__balance'}>*/}
        {/*            ${balance ? balance : '0.00'}*/}
        {/*        </div>*/}
        {/*    </>*/}
        {/*}*/}

        {/*{userInfo()}*/}

        
        <div className={"rewards__buttons"}>
          <div
            className={`rewards__button ${isTourActive && tourCount == 1 ? 'position_bubble' : ''}`}
            onClick={() => {
              !isTourActive && history.push("/receiving");
            }}
          >
            {isTourActive && tourCount == 1 && <Tour tourCount={tourCount} setTourCount={setTourCount}/>}

            <img src={qrCodaIcon} alt="qr code" />
            <div>Pay with cash or credit</div>
          </div>

          <div
            className={`rewards__button ${isTourActive && tourCount == 2 ? 'position_bubble' : ''}`}
            onClick={() => {
              !isTourActive && history.push("/rewards");
            }}
          >
            {isTourActive && tourCount == 2 && <Tour tourCount={tourCount} setTourCount={setTourCount}/>}

            <img src={rewardsIcon} alt="rewards" />
            <div>Pay with rewards</div>
          </div>

          <div
            className={`rewards__button ${isTourActive && tourCount == 3 ? 'position_bubble' : ''}`}
            onClick={() => {
              !isTourActive && history.push("/directory");
            }}
          >
            {isTourActive && tourCount == 3 && <Tour tourCount={tourCount} setTourCount={setTourCount}/>}

            <img className={"directory"} src={directoryIcon} alt="return" />
            <div>Directory</div>
          </div>

          <div
            className={`rewards__button ${isTourActive && tourCount == 4 ? 'position_bubble' : ''}`}
            onClick={() => {
              !isTourActive && history.push("/invite");
            }}
          >
            {isTourActive && tourCount == 4 && <Tour tourCount={tourCount} setTourCount={setTourCount}/>}

            <img src={inviteIcon} alt="invite" />
            <div>Invite</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardsBalance;
