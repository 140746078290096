import React, {useState} from 'react';
import s from './WebReviews.module.css';
import WebReview from "./WebReview";
import StarRatings from "react-star-ratings";
import {useSelector} from "react-redux";
import {reviewActions} from "../../action";
import sadSmileIcon from '../../assets/web-review/review-sad-smile.svg';
import arrowIcon from '../../assets/web-review/review-arrow.svg';
import smileIcon from '../../assets/web-review/review-smile.svg';
import Popup from "../../components/Popup/Popup";

const WebReviews = ({reviews, id}) => {
	const customerTokenLocal = localStorage.getItem('customerToken');

	const {first_name, last_name} = useSelector(({customerReducer: {currentCustomer}}) => currentCustomer);

	const [leaveReviewActive, setLeaveReviewActive] = useState(false);
	const [value, setValue] = useState('');
	const [rating, setRating] = useState(0);

	const [modalActive, setModalActive] = useState(false);

	const changeRating = (userRating) => {
		setRating(userRating)
	}

	const sendReview = () => {
		const data = {
			job_id: id,
			review: value,
			rating: rating
		}


		reviewActions.leaveReview(data, customerTokenLocal)
			.then((data) => {
				setModalActive(true);

				setValue('');
				setRating(0);
			})
			.catch((e) => {

				setValue('');
				setRating(0);
			});
	}

	const isLeaveReviewActive = () => {
		if (leaveReviewActive) return <button
			className={s.discard}
			onClick={() => {
				setValue('');
				setRating(0);
				setLeaveReviewActive(false);
			}}
		>
			Discard
		</button>;

		return <></>
		// return <div className={s.reviews_leave}>
		// 	You’ve been here?
		//
		// 	<button
		// 		onClick={() => {
		// 			setLeaveReviewActive(true);
		// 		}}
		// 	>Write a review</button>
		// </div>
	}

	return (
		<>
			<div className="web-container">
				<div className={s.reviews_wrapper}>
					<div className={s.reviews_info}>
						<div className={s.reviews_count}>
							See reviews <span>({reviews.length})</span>
						</div>

						{isLeaveReviewActive()}
					</div>
				</div>
			</div>

			{leaveReviewActive &&
				<div className={s.leave_review__wrapper}>
					<div className="web-container">
						<div className={s.leave_review__leave}>
							<div className={s.leave_review__message}>
								{first_name + ' ' + last_name}
								<textarea
									value={value}
									onChange={e => setValue(e.target.value)}
									placeholder={'Write your review here...'}
								/>
							</div>

							<div className={s.leave_review__rate}>
								<span>Rate this place</span>
								<StarRatings
									rating={rating}
									starRatedColor='rgba(255, 188, 58, 1)'
									changeRating={changeRating}
									numberOfStars={5}
									starSpacing={'4px'}
									isSelectable={false}
									name='rating'
									svgIconViewBox={'0 0 14 14'}
									svgIconPath={'M6.54894 0.927048C6.8483 0.00573778 8.1517 0.0057404 8.45106 0.927051L9.40837 3.87336C9.54224 4.28538 9.9262 4.56434 10.3594 4.56434H13.4574C14.4261 4.56434 14.8289 5.80395 14.0451 6.37336L11.5389 8.19427C11.1884 8.44892 11.0417 8.90028 11.1756 9.31231L12.1329 12.2586C12.4323 13.1799 11.3778 13.946 10.5941 13.3766L8.08778 11.5557C7.7373 11.3011 7.2627 11.3011 6.91221 11.5557L4.40594 13.3766C3.62222 13.946 2.56774 13.1799 2.8671 12.2586L3.82441 9.3123C3.95828 8.90028 3.81162 8.44892 3.46114 8.19427L0.954859 6.37335C0.171145 5.80395 0.573923 4.56434 1.54265 4.56434H4.64057C5.0738 4.56434 5.45776 4.28538 5.59163 3.87336L6.54894 0.927048Z'}
								/>
								<button
									className={(value && rating) ? s.active : ''}
									onClick={() => {
										if (!(value && rating)) return;

										sendReview();
									}}
								>Send review</button>
							</div>
						</div>
					</div>
				</div>
			}

			<div className="web-container">
				{reviews.map(review => <WebReview review={review} />)}
			</div>

			{!reviews.length &&
				<div className={s.no_reviews}>
					<div className={s.review__smile}>
						<img src={sadSmileIcon} alt="sad smile"/>
						No reviews here
					</div>

					{/*<div className={s.leave_one}>Be the first to leave one!</div>*/}
					{/*<img className={s.arrow__icon} src={arrowIcon} alt="arrow"/>*/}
				</div>
			}

			<Popup popupActive={modalActive} setPopupActive={setModalActive}>
				<div className={s.review_popup__inner}>
					<img src={smileIcon} alt="smile"/>
					<div className={s.review_thx}>Thank you!</div>
					<div className={s.review_popup__text}>
						<div>Your review has been received and the</div>
						<div>rewards associated with this job will be</div>
						<div>issued shortly.</div>
					</div>
				</div>
			</Popup>
		</>
	);
}

export default WebReviews;
