import React, {useRef, useState} from 'react';
import './FindCustomer.css';

import goBackArrow from "../../../assets/arrows/blue-left-arrow.png";
import crossIcon from "../../../assets/close.png";

import QrReader from 'react-qr-reader';

import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";

import getQrData from '../../../helpers/getQrData';

import {setQRResult} from "../../../redux/action-creators";
import {phoneNormalizer} from "../../../helpers/phone-normalizer";

import HeaderMobile from "../../headers/HeaderMobile/HeaderMobile";
import CountriesSelector from "../../selectors/CountriesSelector";
import PayTypeSelector from "../../selectors/PayTypeSelector";
import Modal from "../../Modal/Modal";

const FindCustomer = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [country, setCountry] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [paymentType, setPaymentType] = useState('');

    const [modalActive, setModalActive] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [findByPhoneModalActive, setFindByPhoneModalActive] = useState(false);

    const qrRef = useRef(null);

    const onScanFile = () => {qrRef.current.openImageDialog()}

    const handleScanFile = (result) => {
        if (result) {
            const isCashOrRewards = result.includes('payedWithCashOrCredit') || result.includes('payedWithRewards');
            const isReturn = result.includes('return');

            if (isCashOrRewards) {
                const response = getQrData(result);

                if (!response.ok) {
                    setErrorMessage('QR Code is not valid. Please ask customer to generate new one.');
                    setModalActive(true);

                    return;
                }

                if (response.ok) {
                    dispatch(setQRResult(response.data));

                    localStorage.setItem('customerMobilePhone', '');
                    localStorage.setItem('paymentTypeByPhone', '');
                    history.push('/transaction');

                    return;
                }
            }

            if (isReturn) {
                setErrorMessage('In order to scan this QR code please select ' +
                    '\'Scan for return\' option on home page.');
                setModalActive(true);

                return;
            }

            if (!isReturn && !isCashOrRewards) {
                setErrorMessage('Wrong QR code. Please make sure you are scanning MoRewards QR Code.');
                setModalActive(true);
            }
        }
    }

    const handleErrorFile = (error) => {}

    const phoneForm = useForm({
        mode: 'onBlur'
    });

    const emailForm = useForm({
        mode: 'onBlur'
    });

    const searchByPhone = (data) => {
        if (!countryCode || !paymentType) {
            setFindByPhoneModalActive(true);
        }

        let paymentTypeByPhone = '';

        if (paymentType === 'Pay with cash or credit') {
            paymentTypeByPhone = 'payedWithCashOrCredit';
        }

        if (paymentType === 'Pay with rewards') {
            paymentTypeByPhone = 'payedWithRewards';
        }

        const customerMobilePhone = countryCode + ' ' + data.phoneNumber;

        localStorage.setItem('customerMobilePhone', customerMobilePhone);
        localStorage.setItem('paymentTypeByPhone', paymentTypeByPhone);

        history.push('/transaction');
    }

    const inviteByEmail = (data) => {

    }

    return (
        <div className={'find-customer__wrapper'}>
            <HeaderMobile vendor={true} />

            <div className={'find-customer__inner'}>
                <div className={'find-customer__title'}>
                    <img
                        src={goBackArrow}
                        alt="go back arrow"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    <div>Find customer</div>
                    <div/>
                </div>

                <label onClick={onScanFile} className={'find-customer__label'}>Scan Customer QR code</label>

                <div className={'find-customer__qr-code'}>
                    <QrReader
                        ref={qrRef}
                        delay={300}
                        style={{width: '100%'}}
                        onError={handleErrorFile}
                        onScan={handleScanFile}
                        legacyMode={true}
                    />
                </div>

                <div className={'find-customer__or'}>or</div>

                <form noValidate={true} onSubmit={phoneForm.handleSubmit(searchByPhone)}>
                    <div className={'find-customer__message'}>Search for the customer by phone number:</div>

                    <CountriesSelector
                        country={country}
                        setCountry={setCountry}
                        setCountryCode={setCountryCode}
                    />

                    <div className={'find-customer__phone'}>
                        <div
                            className={'signup-form__input country-code'}
                            style={{
                                color: countryCode ? '#4B4846' : '#8E8E93'
                            }}
                        >
                            {countryCode ? countryCode : '+1'}
                        </div>
                        <input
                            className={'signup-form__input phone-number'}
                            {...phoneForm.register('phoneNumber', {required: true})}
                            id={'phoneNumber'}
                            type={'tel'}
                            placeholder={'999-999-9999'}
                            onChange={(event) => {
                                const {value} = event.target;
                                event.target.value = phoneNormalizer(value);
                            }}
                        />
                    </div>

                    <PayTypeSelector paymentType={paymentType} setPaymentType={setPaymentType} />

                    <button className={'signup-form__btn'}>Search by  Phone Number</button>
                </form>

                <div className={'find-customer__message'}>
                    Invite customer to join Morewards if he doesn’t have the app yet
                </div>

                <div className={'find-customer__message'}>
                    <form noValidate={true} onSubmit={emailForm.handleSubmit(inviteByEmail)}>
                        <input
                            {...emailForm.register('email', {required: true})}
                            type="email"
                            placeholder={'Email'}
                        />
                        <button style={{fontWeight: '700'}} type={'submit'}>Invite</button>
                    </form>
                </div>

            </div>

            <Modal active={findByPhoneModalActive} setActive={setFindByPhoneModalActive}>
                <div className={'vendor-errors'}>
                    <img
                        src={crossIcon}
                        alt="cross"
                        onClick={() => {
                            setFindByPhoneModalActive(false);
                        }}
                    />

                    <div>
                        <span>Error</span>

                        <div>{countryCode ? '' : 'Please select country'}</div>
                        <div>{paymentType ? '' : 'Please select payment type'}</div>
                    </div>
                </div>
            </Modal>

            <Modal active={modalActive} setActive={setModalActive}>
                <div className={'vendor-errors'}>
                    <img
                        src={crossIcon}
                        alt="cross"
                        onClick={() => {
                            setModalActive(false);
                        }}
                    />

                    <div>
                        <span>Error</span>

                        <div>{errorMessage}</div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default FindCustomer;
