import React, { useState } from "react";
import "./SignUp.css";

import backArrow from "../../../assets/arrows/blue-left-arrow.png";
import crossIcon from "../../../assets/close.png";

import { yupResolver } from "@hookform/resolvers/yup";
import { CustomerSignUpSchema } from "../../../validators";

import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import VendorAlert from "../VendorAlert/VendorAlert";

import CountriesSelector from "../../selectors/CountriesSelector";
import StateSelector from "../../selectors/StateSelector";
import Checkbox from "../Checkbox/Checkbox";
import CustomerTerms from "../TermsMobile/CustomerTerms";
import Modal from "../../Modal/Modal";

import {
  IrelandZipCodeNormalizer,
  CanadaZipCodeNormalizer,
  USAZipCodeNormalizer,
} from "../../../helpers/zipCode-normalizer";

//import { phoneNormalizer } from "../../../helpers/phone-normalizer";
import { customerActions } from "../../../action";
import { isoValueOfCountry } from "../../../helpers/isoValueOfCountry";
import { regionCode } from "../../../helpers/regionCode";
import useWindowDimensions from "../../../hooks/windowDimensions.hook";
import Popup from "../../Popup/Popup";

const SignUp = ({ isMobile, setIsCreated }) => {
  const { width } = useWindowDimensions();

  const history = useHistory();

  const [terms, setTerms] = useState(false);

  const [checked, setChecked] = useState(false);

  const [modalActive, setModalActive] = useState(false);

  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [state, setState] = useState("");
  const [states, setStates] = useState([]);

  const [zipCodeInputName, setZipCodeInputName] = useState("");

  const [createCustomerError, setCreateCustomerError] = useState(null);
  const [errorModal, setErrorModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(CustomerSignUpSchema),
  });

  const zipCodeInputDependsOnCountry = () => {
    if (country === "Canada") {
      return (
        <>
          <input
            className={"signup-form__input zp"}
            {...register("CanadaZipCode")}
            id={"zipCode"}
            type={"text"}
            placeholder={"Zip code"}
            onChange={(event) => {
              const { value } = event.target;
              event.target.value = CanadaZipCodeNormalizer(value);
            }}
          />
        </>
      );
    }

    if (country === "Ireland") {
      return (
        <>
          <input
            className={"signup-form__input zp"}
            {...register("IrelandZipCode")}
            id={"zipCode"}
            type={"text"}
            placeholder={"Zip code"}
            onChange={(event) => {
              const { value } = event.target;
              event.target.value = IrelandZipCodeNormalizer(value);
            }}
          />
        </>
      );
    }

    if (country === "United States") {
      return (
        <>
          <input
            className={"signup-form__input zp"}
            {...register("UnitedStatesZipCode")}
            id={"zipCode"}
            type={"text"}
            placeholder={"Zip code"}
            onChange={(event) => {
              const { value } = event.target;
              event.target.value = USAZipCodeNormalizer(value);
            }}
          />
        </>
      );
    }

    return (
      <input
        className={"signup-form__input zp"}
        type={"text"}
        placeholder={
          zipCodeInputName
            ? "Zip code"
            : width > 600
            ? "Zip code"
            : "Please select the country firs"
        }
        disabled={!zipCodeInputName}
      />
    );
  };

  const inputsDependsOfScreen = () => {
    if (isMobile) {
      return (
        <>
          {/* <div className={'signup-form__inputs'}>
						<div
							className={'signup-form__input country-code'}
							style={{
								color: countryCode ? '#4B4846' : '#8E8E93'
							}}
						>
							{countryCode ? countryCode : '+1'}
						</div>
						<input
							className={'signup-form__input phone-number'}
							{...register('phoneNumber')}
							id={'phoneNumber'}
							type={'tel'}
							placeholder={'999-999-9999'}
							onChange={(event) => {
								const {value} = event.target;
								event.target.value = phoneNormalizer(value);
							}}
						/>
					</div> */}

          <div className={"width-wrapper"}>
            <StateSelector state={state} setState={setState} states={states} />
          </div>

          <input
            className={"signup-form__input"}
            {...register("city")}
            id={"city"}
            type={"text"}
            placeholder={"City"}
          />
          <input
            className={"signup-form__input"}
            {...register("address1")}
            id={"address1"}
            type={"text"}
            placeholder={"Address"}
          />

          {zipCodeInputDependsOnCountry()}
        </>
      );
    }

    return (
      <>
        <div className={"signup-form__inputs"}>
          {/*<div className={"signup-form__input country-code"}>{countryCode}</div>
           <input
            className={"signup-form__input phone-number"}
            {...register("phoneNumber")}
            id={"phoneNumber"}
            type={"phone"}
            placeholder={"999-999-9999"}
            onChange={(event) => {
              const { value } = event.target;
              event.target.value = phoneNormalizer(value);
            }}
          /> */}
          {/*<input*/}
          {/*    className={'signup-form__input zip-code'}*/}
          {/*    {...register('zipCode')}*/}
          {/*    id={'zipCode'}*/}
          {/*    type={'number'}*/}
          {/*    placeholder={'Zip code'}*/}
          {/*/>*/}
          <input
            className={"signup-form__input city"}
            {...register("city")}
            id={"city"}
            type={"text"}
            placeholder={"City"}
          />

          <StateSelector state={state} setState={setState} states={states} />
          {zipCodeInputDependsOnCountry()}
        </div>

        <input
          className={"signup-form__input"}
          {...register("address1")}
          id={"address1"}
          type={"text"}
          placeholder={"Address"}
        />
      </>
    );
  };

  const customerSignUp = async (data) => {
    try {
      setChecked(true);
      setModalActive(false);

      //const phone = countryCode + " " + data.phoneNumber;
      //localStorage.setItem("phone", phone);
      console.log("\ncustomerSignUp... ", data);
      //data.phone = phone;
      data.region = regionCode(country, state);
      data.country = isoValueOfCountry(country);
      const selectedAreaCode =
        data.CanadaZipCode || data.UnitedStatesZipCode || data.IrelandZipCode;

      //delete data.phoneNumber;
      delete data.confirmPassword;
      delete data.CanadaZipCode;
      delete data.UnitedStatesZipCode;
      delete data.IrelandZipCode;
      data.areacode = selectedAreaCode;
      const res = await customerActions.customerSignup(data);
      console.log("\n\n\n res...", res);
      if (res?.data && res?.data?.ok === true) {
        localStorage.setItem("email", data.email);
        if (isMobile) {
          history.push("/verify-email");
          return;
        }
        setIsCreated(true);
      }
    } catch (err) {
      let message = "Something went wrong during register.";
      if (err?.response?.data && err?.response?.data?.ok === false) {
        message =
          err?.response?.data?.message ||
          "Something went wrong during register.";
      } else if (err.message?.validate_errors) {
        console.log("\n validate_errors message...", err.message);
        message = "Please check all form fields";
      } else {
        if (err.message === "Request failed with status code 401") {
          message = "User already exist";
        } else {
          message = err.message || "Something went wrong during register.";
        }
      }
      setCreateCustomerError(message);
      setErrorModal(true);
      setTimeout(() => {
        setCreateCustomerError(null);
        setErrorModal(false);
      }, 2000);
    }
  };

  return (
    <>
      <form
        style={{ display: terms ? "none" : "flex" }}
        className={"signup-form"}
        noValidate={true}
        onSubmit={handleSubmit(customerSignUp)}
      >
        <VendorAlert title={"Sign up as a customer"} />

        <div className={"signup-form__title"}>
          <img
            src={backArrow}
            alt="back arrow"
            onClick={() => {
              history.goBack();
            }}
          />
          Register
          <div />
        </div>

        <div className={"signup-form__text-description"}>
          <div>New to MOREWARDS?</div>
          <div>Sign up below to refer jobs and earn</div>
          <div>real cash rewards.</div>
        </div>

        <div className={"signup-form__section-title"}>Personal information</div>

        <input
          className={"signup-form__input"}
          {...register("firstname")}
          id={"firstname"}
          type={"text"}
          placeholder={"First Name"}
        />
        <input
          className={"signup-form__input"}
          {...register("lastname")}
          id={"lastname"}
          type={"text"}
          placeholder={"Last Name"}
        />
        <div className={"width-wrapper"}>
          <CountriesSelector
            country={country}
            setCountry={setCountry}
            setCountryCode={setCountryCode}
            setStates={setStates}
            setState={setState}
            setZipCodeInputName={setZipCodeInputName}
          />
        </div>

        {inputsDependsOfScreen()}

        <input
          className={"signup-form__input"}
          {...register("email")}
          id={"email"}
          type={"email"}
          placeholder={"Email address"}
        />

        <div className={"signup-form__section-title"}>Password</div>
        <input
          className={"signup-form__input"}
          {...register("password")}
          id={"password"}
          type={"password"}
          placeholder={"Password"}
        />
        <input
          className={"signup-form__input"}
          {...register("confirmPassword")}
          id={"confirmPassword"}
          type={"password"}
          placeholder={"Confirm password"}
        />
        <input
          className={"signup-form__input"}
          {...register("referral_code")}
          id={"referral_code"}
          type={"text"}
          placeholder={"Referral code (if you have one)"}
        />

        <div className={"signup-form__terms"}>
          <Checkbox
            checked={checked}
            setChecked={setChecked}
            setTerms={setTerms}
          />
          <div className={"signup-form__terms-message"}>
            By registering you agree to our terms and conditions
          </div>
        </div>

        <button
          className={"signup-form__button active"}
          type={"submit"}
          onClick={() => {
            if (
              Object.keys(errors).length ||
              !country ||
              !countryCode ||
              !state
            ) {
              setModalActive(true);
            }
          }}
        >
          Sign up
        </button>
      </form>

      {modalActive && width < 600 ? (
        <Modal active={modalActive} setActive={setModalActive}>
          <div className={"vendor-errors"}>
            <img
              src={crossIcon}
              alt="cross"
              onClick={() => {
                setModalActive(false);
              }}
            />

            <div>
              <span>Error</span>

              {errors?.firstname && <div>{errors.firstname.message}</div>}
              {errors?.lastname && <div>{errors.lastname.message}</div>}
              {!country && <div>Please select the country</div>}
              {!countryCode && <div>Please select the mobile code</div>}
              {/* {errors?.phoneNumber && <div>{errors.phoneNumber.message}</div>} */}
              {!state && <div>Please select the state</div>}
              {errors?.city && <div>{errors.city.message}</div>}
              {errors?.address1 && <div>{errors.address1.message}</div>}
              {errors?.CanadaZipCode && (
                <div>{errors.CanadaZipCode.message}</div>
              )}
              {errors?.IrelandZipCode && (
                <div>{errors.IrelandZipCode.message}</div>
              )}
              {errors?.UnitedStatesZipCode && (
                <div>{errors.UnitedStatesZipCode.message}</div>
              )}
              {errors?.email && <div>{errors.email.message}</div>}
              {errors?.password && <div>{errors.password.message}</div>}
              {errors?.confirmPassword && (
                <div>{errors.confirmPassword.message}</div>
              )}
              {errors?.referral_code && (
                <div>{errors.referral_code.message}</div>
              )}
            </div>
          </div>
        </Modal>
      ) : (
        <Popup popupActive={modalActive} setPopupActive={setModalActive}>
          <div className={"vendor-errors"}>
            {/*<img*/}
            {/*    src={crossIcon}*/}
            {/*    alt="cross"*/}
            {/*    onClick={() => {*/}
            {/*        setModalActive(false);*/}
            {/*    }}*/}
            {/*/>*/}

            <div>
              <span>Error</span>

              {errors?.firstname && <div>{errors.firstname.message}</div>}
              {errors?.lastname && <div>{errors.lastname.message}</div>}
              {!country && <div>Please select the country</div>}
              {!countryCode && <div>Please select the mobile code</div>}
              {/* {errors?.phoneNumber && <div>{errors.phoneNumber.message}</div>} */}
              {!state && <div>Please select the state</div>}
              {errors?.city && <div>{errors.city.message}</div>}
              {errors?.address1 && <div>{errors.address1.message}</div>}
              {errors?.CanadaZipCode && (
                <div>{errors.CanadaZipCode.message}</div>
              )}
              {errors?.IrelandZipCode && (
                <div>{errors.IrelandZipCode.message}</div>
              )}
              {errors?.UnitedStatesZipCode && (
                <div>{errors.UnitedStatesZipCode.message}</div>
              )}
              {errors?.email && <div>{errors.email.message}</div>}
              {errors?.password && <div>{errors.password.message}</div>}
              {errors?.confirmPassword && (
                <div>{errors.confirmPassword.message}</div>
              )}
              {errors?.referral_code && (
                <div>{errors.referral_code.message}</div>
              )}
            </div>
          </div>
        </Popup>
      )}
      {errorModal && (
        <Modal active={errorModal} setActive={setErrorModal}>
          <div className={"vendor-errors"}>
            <img
              src={crossIcon}
              alt="cross"
              onClick={() => {
                setErrorModal(false);
              }}
            />

            <div>
              <span>Error</span>

              <div>{createCustomerError}</div>
            </div>
          </div>
        </Modal>
      )}
      {terms && <CustomerTerms setTerms={setTerms} />}
    </>
  );
};

export default SignUp;
