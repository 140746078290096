import axios from 'axios';
import {BASE_URL} from "../constants";

const getInviteCode = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(`${BASE_URL}/api/invite-code`, config);
    return response.data;
}

const sendInvite = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.post(`${BASE_URL}/api/invite`, data, config);
    return response.data;
}

export {
    getInviteCode,
    sendInvite
}
