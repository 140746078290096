import React, {useEffect, useRef, useState} from 'react';
import s from "./CategoryProviders.module.css";

import {providerActions} from "../../action";
import {getDistance} from "geolib";

import {useObserver} from "../../hooks/useObserver";

import CategoryProvider from "./CategoryProvider";
import Loader from "../../components/Loader/Loader";

const ProvidersByRadius = ({searchRadius , userLocation}) => {
	const customerTokenLocal = localStorage.getItem('customerToken');
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);

	const [isLoading, setIsLoading] = useState(false);
	const [providers, setProviders] = useState([]);

	const lastElement = useRef();

	useObserver(lastElement, page <= totalPages, isLoading,  () => {
		setPage(page + 1);
	});

	useEffect(() => {
		setIsLoading(true);

		if (!searchRadius) {
			setIsLoading(false);
			return
		}

		providerActions.getAllProviders(customerTokenLocal, page)
			.then(response => {
				if (page > response.data.last_page) {
					setIsLoading(false);
					return;
				}

				setTotalPages(response.data.last_page);
				setProviders([...providers, ...response.data.data]);
				setIsLoading(false);
			});
	}, [customerTokenLocal, page, searchRadius]);

	const filterProviders = () => {
		return providers.filter(provider => {
			let isInRadius = false;

			if (!searchRadius) return false;

			if (provider.locations.length !== 0) {
				provider.locations.forEach(location => {
					const distance = Math.floor(getDistance(userLocation, {
						lat: parseFloat(location.lat),
						lng: parseFloat(location.lon)
					}) * 0.000621371192);

					if (searchRadius > distance) isInRadius = true;
				});
			}

			return isInRadius;
		});
	}

	return (
		<div className={'rad-pr'}>
			<div className={s.category_providers}>
				{filterProviders().map(provider => <CategoryProvider key={provider.id} provider={provider} />)}
			</div>
			{(!filterProviders().length && searchRadius && !isLoading) && <div className={'providers-not-found'}>Providers not found 😔</div>}
			{isLoading && <Loader />}
			{!isLoading && <div ref={lastElement}/>}
		</div>
	);
}

export default ProvidersByRadius;
