import React, {useEffect, useRef, useState} from 'react';
import s from './WebCustomers.module.css';

import searchIcon from "../../assets/search.svg";

import { customerActions } from '../../action';
import {useObserver} from "../../hooks/useObserver";

import {useParams} from "react-router-dom";

import Loader from "../../components/Loader/Loader";
import WebCustomer from "./WebCustomer";
import infoIcon from "../../assets/info.png";
import Modal from "../../components/Modal/Modal";
import crossIcon from "../../assets/close.png";

const WebCustomers = () => {
	const {id} = useParams();

	const inputRef = useRef();
	const [value, setValue] = useState('');

	const customerTokenLocal = localStorage.getItem('customerToken');

	const [customers, setCustomers] = useState([]);

	const [providerSharedToUser, setProviderSharedToUser] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [modalActive, setModalActive] = useState(false);

	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const lastElement = useRef();

	useObserver(lastElement, page <= totalPages, isLoading,  () => {
		setPage(page + 1);
	});


	const getUniqueValues = (valuesList, criteria = 'email') => {
		const result = []
		const resultListValues = []

		for (let i = 0; i < valuesList.length; i++) {

			if (!resultListValues.includes(valuesList[i][criteria])) {
				result.push(valuesList[i])
				resultListValues.push(valuesList[i][criteria])
			}
		}

		return result
	}

	useEffect(() => {
		if (providerSharedToUser) {
			setTimeout(() => {
				setProviderSharedToUser(false);
			}, 2000);
		}
	}, [providerSharedToUser]);

	useEffect(() => {
		setIsLoading(true)

		customerActions.getAllCustomers(customerTokenLocal, page)
			.then(({data}) => {
				if (page > data.last_page) {
					setIsLoading(false);
					return;
				}

				setTotalPages(data.last_page);

				setCustomers(getUniqueValues([...customers , ...data.data]));
				setIsLoading(false);
			});
	}, [customerTokenLocal, page]);

	const customerSearch = () => {
		return customers.filter((customer) => {
			if (!value) return customer;

			if (customer.first_name) {
				return customer.first_name.toLowerCase().includes(value.toLowerCase())
					|| customer.last_name.toLowerCase().includes(value.toLowerCase());
			}
		});
	}

	return (
		<div className={'web-container'}>
			<div className={s.customers__title}>Select Customer</div>

			<div className={s.customers__search}>
				<input
					ref={inputRef}
					type="text"
					placeholder={'Search for customer'}
					value={value}
					onChange={e => setValue(e.target.value)}
				/>
				<img src={searchIcon} alt="search category" onClick={() => inputRef.current.focus()}/>
			</div>

			<div className={s.customers__list}>
				{customerSearch().map(customer => <WebCustomer
					key={customer.id}
					customer={customer}
					setProviderSharedToUser={setProviderSharedToUser}
					setErrorMessage={setErrorMessage}
					setModalActive={setModalActive}
					vendorId={id} />
				)}
			</div>

			{isLoading && <div className={s.loader}><Loader /></div>}
			{!isLoading && <div ref={lastElement} />}
			<Modal active={modalActive} setActive={setModalActive}>
				<div className={'vendor-errors'}>
					<img
						src={crossIcon}
						alt="cross"
						onClick={() => {
							setModalActive(false);
						}}
					/>

					<div>
						<span>Error</span>

						<div>{errorMessage}</div>
					</div>
				</div>
			</Modal>

			<div className={providerSharedToUser
				? 'sales-inner__message active share'
				: 'sales-inner__message'
			}>
				<img src={infoIcon} alt="info about transaction"/>

				<div className={'sales-inner__message-text'}>
					<div>Success!</div>
					<div>Vendor details were shared successfully</div>
				</div>
			</div>
		</div>
	);
}

export default WebCustomers;
