import React, {useState} from 'react';
import s from './ProviderNearby.module.css';
import ProviderNearby from "./ProviderNearby";

const ProvidersNearby = ({providers}) => {
	const [value, setValue] = useState('');

	const addressSearch = () => {
		return providers.filter((provider) => {
			if (!value) return provider;

			if (!provider.locations[0]?.add_1) return false;

			return provider.locations[0]?.add_1.toLowerCase().includes(value.toLowerCase());
		})
	}

	return (
		<div className={s.providers_nearby__wrapper}>
			<input
				type={'text'}
				placeholder={'Address'}
				value={value}
				onChange={(e) => setValue(e.target.value)}
			/>

			{addressSearch().map(provider => <ProviderNearby key={provider.id} provider={provider} />)}
		</div>
	);
};

export default ProvidersNearby;
