import React from 'react';
import './Popup.css';

import closeIcon from '../../assets/close-icon.svg';

const Popup = ({popupActive, setPopupActive, children}) => {
	return (
		<div
			className={popupActive ? 'popup__container popup__active' : 'popup__container'}
			onClick={() => setPopupActive(false)}
		>
			<div
				className={'popup__content'}
				onClick={(e) => e.stopPropagation()}
			>
				<div
					className={'popup__close'}
					title={'close'}
					onClick={() => setPopupActive(false)}
				>
					<img src={closeIcon} alt="close popup"/>
				</div>

				{children}
			</div>
		</div>
	);
};

export default Popup;
