import React from 'react';
import './AuthHeader.css';

import headerLogo from '../../../assets/logos/header-logo.png';

import {Link, useHistory} from "react-router-dom";

const AuthHeader = () => {
    const history = useHistory();

    return (
        <>
            <header className={'auth-header'}>
                <img
                    src={headerLogo}
                    alt={'logo'}
                    onClick={() => {
                        history.push('/');
                    }}
                />

                <div>
                    <div
                        className={'auth-header__terms'}
                        onClick={() => {
                            history.push('/terms-of-service');
                        }}
                    >Terms of service</div>
                    <Link to={'/auth'} className={'auth-header__button active'}>Get Started</Link>
                </div>
            </header>

            <div className={'auth-header__underline'} />
        </>
    );
};

export default AuthHeader;
