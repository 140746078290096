import * as yup from 'yup';

const emailInvite = yup.object().shape({
    email: yup
        .string()
        .required('Email is a required field')
        .email('Email should have correct format'),
});

const phoneInvite = yup.object().shape({
    phone: yup
        .string()
        .required('Phone number is a required field')
        .matches(/^((8|\+7)[ ]?)?(\(?\d{3}\)?[ ]?)?[\d\- ]{7,10}$/, 'Enter correct phone number'),
});

export {
    emailInvite,
    phoneInvite
}
