import React from 'react';
import s from './RadiusSearch.module.css';

const RadiusSearch = ({selectedRadius, setSelectedRadius, isZip, zipCode, setZipCode, setZipcodeModalActive}) => {
	const options = [10, 20, 30, 40, 50, 60, 4500];

	return (
		<div className={s.radius_search__wrapper}>
			<div className={s.radius_search__title}>Select search radius</div>

			{isZip &&
				<input
					value={zipCode}
					type={'text'}
					placeholder={'Zip code'}
					className={s.zip_input}
					onChange={e => setZipCode(e.target.value)}
				/>
			}

			{options.map(option => <div
				className={option === selectedRadius
					? `${s.radius_search__option} ${s.active}`
					: s.radius_search__option
				}
				onClick={() => setSelectedRadius(option)}
			>{option} miles</div>)}
			<button
				style={{width: '230px', margin: '20px 0 0 0'}}
				className={s.radius_search__option}
				onClick={() => setZipcodeModalActive(false) }
			>
				Submit
			</button>
		</div>
	);
};

export default RadiusSearch;
