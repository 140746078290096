import {
    SET_MESSAGE_ACTIVE,
    SET_PASSWORD_CHANGED,
    SET_ACCOUNT_EDITED,
    SET_PURCHASE_RETURNED,
    SET_PROVIDER_INVITE_SENT,
} from '../action-types';

const setMessageActive = response => ({type: SET_MESSAGE_ACTIVE, payload: response});
const setPasswordChanged = response => ({type: SET_PASSWORD_CHANGED, payload: response});
const setAccountEdited = response => ({type: SET_ACCOUNT_EDITED, payload: response});
const setPurchaseReturned = response => ({type: SET_PURCHASE_RETURNED, payload: response});
const setProviderInviteSent = response => ({type: SET_PROVIDER_INVITE_SENT, payload: response});

export {
    setMessageActive,
    setPasswordChanged,
    setAccountEdited,
    setPurchaseReturned,
    setProviderInviteSent,
}
