import axios from "axios";
import { BASE_URL } from "../constants";

const customerLogin = async (data) => {
  return await axios.post(`${BASE_URL}/api/auth/login/customer`, data);
};

const customerSignup = async (data) => {
  return await axios.post(`${BASE_URL}/api/auth/register/customer`, data);
};

const updateCustomer = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.put(`${BASE_URL}/api/user`, data, config);
};

const getCustomer = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.get(`${BASE_URL}/api/user`, config);
};

const getAllCustomers = async (token, page) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${BASE_URL}/api/customers/page/${page}`,
    config
  );
  return response.data;
};

const residualCustomer = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${BASE_URL}/api/residual-customer`,
    data,
    config
  );
  return response.data;
};

export {
  customerLogin,
  customerSignup,
  updateCustomer,
  getCustomer,
  getAllCustomers,
  residualCustomer,
};
