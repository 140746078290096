import React, {useRef} from 'react';
import {GoogleMap, Marker} from "@react-google-maps/api";

import locationMarker from "../../assets/location-marker-red.svg";

const defaultOptions = {
	panControl: false,
	zoomControl: false,
	mapTypeControl: false,
	scaleControl: false,
	streetViewControl: false,
	rotateControl: false,
	clickableIcons: false,
	keyboardShortcuts: false,
	scrollwheel: false,
	disableDoubleClickZoom: false,
	fullscreenControl: false,
	terms: false
}

const SmallMap = ({center, isActivity}) => {
	const containerStyle = {
		width: isActivity ? '350px' : '180px',
		height: '100%'
	};

	const mapRef = useRef(undefined);

	const onLoad = React.useCallback(function callback(map) {
		mapRef.current = map;
	}, [])

	const onUnmount = React.useCallback(function callback() {
		mapRef.current = undefined;
	}, []);

	return (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={center}
			zoom={10}
			onLoad={onLoad}
			onUnmount={onUnmount}
			options={defaultOptions}
		>
			<Marker position={center} icon={locationMarker}/>
		</GoogleMap>
	);
};

export default SmallMap;
