import React from 'react';
import './EmailVerify.css';

import emailVerifyIcon from '../../../assets/email-verify.svg';

import {useHistory} from "react-router-dom";
import {verificationActions} from "../../../action";

const EmailVerify = ({resetPassword, isWeb}) => {
    const history = useHistory();
    const email = localStorage.getItem('email');

    const resendVerifyEmail = () => {
        verificationActions.sendVerifyEmailCustomer(email);
    }

    const messageDependsOfEmailType = () => {
        if (resetPassword) {
            return (
                <>
                    {isWeb
                        ? <>
                            <div>We sent you a link to your email</div>
                            <div>address. To reset your password,</div>
                            <div>please follow the link.</div>
                        </>
                        : <>
                            <div>We sent you a new password</div>
                            <div>to your email address</div>
                        </>
                    }
                </>
            );
        }

        return (
            <>
                <div>We sent you a link to your email</div>
                <div>address. To verify your email,</div>
                <div>please follow the link.</div>
            </>
        );
    }

    const linkDependsOfEmailType = () => {
        if (resetPassword) {
            return (
                <>
                    <div>If you don’t receive the password,</div>
                    <div>check your spam folder or</div>
                    <div>ask to send a new password.</div>
                </>
            );
        }

        return (
            <>
                <div>If you don’t receive the link, check</div>
                <div>your spam folder or</div>
                <div
                    onClick={() => {
                        resendVerifyEmail();
                    }}
                >ask to send a new link.</div>
            </>
        );
    }

    return (
        <div className={'email-verify-wrapper'}>
            <div className={'email-verify-inner'}>
                <div className={'email-verify__title'}>{isWeb ? 'Forgot password' : 'Sign up'}</div>

                <img src={emailVerifyIcon} alt="email verify icon"/>

                <div className={'email-verify__text'}>
                    {messageDependsOfEmailType()}
                </div>

                <button
                    className={'email-verify__button bold'}
                    onClick={() => {
                        history.push('/');
                    }}
                >Back to login</button>

                <div className={'email-verify__help'}>
                    {linkDependsOfEmailType()}
                </div>
            </div>

            <div className={'new-email-web'}>
                <div>If you don’t receive the link, check your spam folder or</div>
                <div onClick={() => {
                    resendVerifyEmail();
                }}>ask to send a new link.</div>
            </div>
        </div>
    );
};

export default EmailVerify;
