import React, {useEffect, useRef, useState} from 'react';
import s from "./CategoryProviders.module.css";

import {useObserver} from "../../hooks/useObserver";
import {providerActions} from "../../action";

import {getDistance} from "geolib";
import {getGeocode, getLatLng} from "use-places-autocomplete";

import CategoryProvider from "./CategoryProvider";
import Loader from "../../components/Loader/Loader";

const ProvidersByZipCode = ({zipCode, zipCodeSelectedRadius}) => {
	const customerTokenLocal = localStorage.getItem('customerToken');
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);

	const [isLoading, setIsLoading] = useState(false);
	const [providers, setProviders] = useState([]);

	const [zipProviders, setZipProviders] = useState([]);

	const [cleanZip,setCleanZip] = useState()

	const lastElement = useRef();

	useObserver(lastElement, page <= totalPages, isLoading,  () => {
		setPage(page + 1);
	});

	useEffect(() => {
		if (zipCode.length < 5) return;

		setPage(1);
		setProviders([]);
	}, [zipCodeSelectedRadius, zipCode]);

	useEffect(() => {
		if (zipCode.length < 5) return;

		setIsLoading(true);

		if (!zipCodeSelectedRadius) {
			setIsLoading(false);
			return
		}

		providerActions.getAllProviders(customerTokenLocal, page)
			.then(response => {
				if (page > response.data.last_page) {
					setIsLoading(false);
					return;
				}

				setTotalPages(response.data.last_page);

				// const providersByRadius = response.data.data.filter(provider => {
				// 	let isInRadius = false;
				//
				// 	if (!zipCodeSelectedRadius) return false;
				//
				// 	if (provider.locations.length !== 0) {
				// 		provider.locations.forEach(location => {
				// 			const distance = Math.floor(getDistance(userLocation, {
				// 				lat: parseFloat(location.lat),
				// 				lng: parseFloat(location.lon)
				// 			}) * 0.000621371192);
				//
				// 			if (zipCodeSelectedRadius > distance) isInRadius = true;
				// 		});
				// 	}
				//
				// 	return isInRadius;
				// });

				if(zipCode === '10001') setCleanZip('10002')
				else setCleanZip(zipCode)

				getGeocode({address: zipCode === '10001' ? '10002' : zipCode})
					.then((result) => {
						let countries = ['US', 'CA']
						let regionCountry = result.find(el => countries.includes(el.address_components[el.address_components.length - 1].short_name))
						if(regionCountry) {
							return getLatLng(regionCountry)
						}
						setZipProviders(arr => [...arr, result])
						// return getLatLng(result[0])
					}).then(({lat, lng}) => {
						const providersByZip = response.data.data.filter(provider => {
							let isInRadius = false;
							if (provider.locations.length !== 0) {
								provider.locations.forEach(location => {

									const distance =  Math.floor(getDistance({lat:lat, lng:lng}, {
										lat: parseFloat(location.lat),
										lng: parseFloat(location.lon)
									})*0.000621371192);

									if (zipCodeSelectedRadius > distance) isInRadius = true;
								});
							}

							return isInRadius;
						});
						setProviders([...providers, ...providersByZip]);
						setIsLoading(false);

						// setIsLoading(false);
					})
					.catch(e => console.log(e.message, 'e'))

			});
	}, [customerTokenLocal, page, zipCodeSelectedRadius, zipCode]);

	return (
		<div className={'rad-pr'}>
			<div className={s.category_providers}>
				{providers.map(provider => <CategoryProvider key={provider.id} provider={provider} />)}
			</div>
			{/*{(!filterProviders().length && searchRadius && !isLoading) && <div className={'providers-not-found'}>Providers not found 😔</div>}*/}
			{isLoading && <Loader />}
			{!isLoading && <div ref={lastElement}/>}
		</div>
	);
};

export default ProvidersByZipCode;
