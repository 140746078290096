import React, {useState} from 'react';
import s from './WebCustomers.module.css';
import {customerActions} from "../../action";

const WebCustomer = ({customer, vendorId, setModalActive, setErrorMessage, setProviderSharedToUser}) => {
	const customerTokenLocal = localStorage.getItem('customerToken');

	const [isLoading, setIsLoading] = useState(false);

	const data = {
		vendor_id: parseInt(vendorId),
		customer_id: customer.id,
		link: 'https://pwa.locallyselected.com/provider/' + vendorId
	}

	const sendData = () => {
		if (isLoading) return;

		setIsLoading(true);

		customerActions.residualCustomer(data, customerTokenLocal)
			.then(response => {
				if (response.ok) {
					setProviderSharedToUser(true)
					return;
				}

				// if (!response.ok) {
				// 	setIsLoading(false);
				// 	setModalActive(true);
				// 	setErrorMessage(response.message);
				// }
			})
			.catch(e => {
				setIsLoading(false);
				setModalActive(true);
					if (e.response.status === 401) setErrorMessage('You are not authorized')
				else setErrorMessage(e.message)
			})
	}

	return (
		<div
			className={s.customer}
		>
			<div className={s.c}>
				<div className={s.customer__name}>{customer.first_name} {customer.last_name}</div>
				<div className={s.customer__email}>{customer.email}</div>
			</div>

			<div className={s.customer__share} onClick={sendData}>
				Share
			</div>
		</div>
	);
};

export default WebCustomer;
