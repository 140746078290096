import React from "react";
import s from "./Activity.module.css";

import ProviderWithMap from "../ProviderWithMap/ProviderWithMap";
import Job from "../Job/Job";

const Activity = ({ job, setAddFavorite, addFavorite }) => {
  // if (!job.user_provider) return <></>;

  return (
    <div className={s.web__activity}>
      <ProviderWithMap
        provider={job.user_provider}
        isActivity={true}
        jobID={job.id}
        jobStatus={job.status}
        date={job.created_at}
        setAddFavorite={setAddFavorite}
		addFavorite={addFavorite}
      />
      <Job job={job} />
    </div>
  );
};

export default Activity;
