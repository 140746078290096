import React, {useEffect, useState} from 'react';
import './HeaderMobile.css';

import accountIcon from '../../../assets/menu/account.svg';
import homeIcon from '../../../assets/menu/home.svg';
import activitiesIcon from '../../../assets/menu/activities.svg';
import directoryIcon from '../../../assets/menu/directory.svg';

import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {setCurrentVendor, setVendorToken} from "../../../redux/action-creators";
import {jobActions} from "../../../action";

const HeaderMobile = ({vendor , isTourActive}) => {
    const [menuActive, setMenuActive] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    // const customerTokenLocal = localStorage.getItem('customerToken');
    const customerTokenLocal = useSelector(({customerReducer: {customerToken}}) => customerToken);

    const logOutUser = () => {
        dispatch(setVendorToken(null));
        dispatch(setCurrentVendor({}));

        localStorage.removeItem('vendorToken');
        localStorage.removeItem('currentVendor');
    }

    const [newActivitiesCount, setNewActivitiesCount] = useState(0);

    const previousActivitiesCount = localStorage.getItem('previousActivitiesCount');

    useEffect(() => {
        jobActions.getJobs(1, customerTokenLocal)
            .then(({data}) => {

                localStorage.setItem('previousActivitiesCount', data.jobs.total);

                if (previousActivitiesCount < data.jobs.total) {
                    setNewActivitiesCount(data.jobs.total - previousActivitiesCount);
                    return;
                }

                setNewActivitiesCount(0);
            });
    }, [previousActivitiesCount, customerTokenLocal]);

    return (
        <>
            <div className={menuActive ? 'menu-wrapper active' : 'menu-wrapper'}>
                <div className={'menu-btn__wrapper'}>
                    <div>
                        <div className={menuActive ? 'menu active' : 'menu'}>Menu</div>
                    </div>
                    <div
                        className={menuActive ? 'menu-btn active' : 'menu-btn'}
                        onClick={() => {
                           !isTourActive && setMenuActive(!menuActive);
                        }}
                    >
                        <div className={'menu-btn__burger'} />
                    </div>
                </div>

                <div className={menuActive ? 'menu-navigation active' : 'menu-navigation'}>
                    <nav className={'menu-m-nav'}>
                        <div
                            className={'nav-link'}
                            onClick={() => {
                                history.push('/account');
                            }}
                        >
                            <img src={accountIcon} alt="account page"/>
                            <div>Account</div>
                        </div>
                        <div
                            className={'nav-link'}
                            onClick={() => {
                                history.push('/');
                            }}
                        >
                            <img src={homeIcon} alt="home page"/>
                            <div>Home</div>
                        </div>
                        <div
                            className={'nav-link activities'}
                            onClick={() => {
                                history.push('/activities');
                            }}
                        >
                            <img src={activitiesIcon} alt="activities page"/>
                            <div className={'new-activities__parent'}>
                                Activities
                                {!!newActivitiesCount && <span className={'new-activities'}>{newActivitiesCount}</span>}
                            </div>
                            {/*<span className={'new-activities'}>9</span>*/}

                        </div>
                        {!vendor &&
                            <div
                                className={'nav-link'}
                                onClick={() => {
                                    history.push('/directory');
                                }}
                            >
                                <img src={directoryIcon} alt="directory page"/>
                                <div>Directory</div>
                            </div>
                        }

                        {vendor &&
                            <button onClick={() => {
                                logOutUser();
                            }}>
                                log out
                            </button>
                        }
                    </nav>
                </div>

            </div>
        </>
    );
};

export default HeaderMobile;
