import { combineReducers } from 'redux';

import customerReducer from './customer-reducer';
import vendorReducer from './vendor-reducer';
import QRReducer from './qr-reducer';
import messageReducer from './message-reducer';
import reviewsReducer from './reviews-reducer';

export const reducer = combineReducers({
    customerReducer,
    vendorReducer,
    QRReducer,
    messageReducer,
    reviewsReducer
});
