import React, {useState} from 'react';
import './Login.css';

import crossIcon from "../../../assets/close.png";

import {useForm} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {CustomerSignInSchema} from '../../../validators';

import {Link, useHistory} from 'react-router-dom';
import {useDispatch} from "react-redux";

import {customerActions} from "../../../action";
import {setCurrentCustomer, setCustomerToken} from "../../../redux/action-creators";

import VendorAlert from '../VendorAlert/VendorAlert';
import Modal from "../../Modal/Modal";

const Login = ({setIsRestore}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [modalActive, setModalActive] = useState(false);

    const [loginError, setLoginError] = useState(null);
    const [errorModal, setErrorModal] = useState(false);

    const { register, handleSubmit, formState: {errors} } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(CustomerSignInSchema)
    });

    const customerSignIn = (data) => {
        customerActions.customerLogin(data)
            .then(({data}) => {
                if (data.ok) {
                    dispatch(setCustomerToken(data.data.token));
                    dispatch(setCurrentCustomer(data.data.user));

                    localStorage.setItem('customerToken', data.data.token);
                    localStorage.setItem('currentCustomer', JSON.stringify(data.data.user));

                    return;
                }

                if (!data.ok) {
                    setLoginError(data.message);
                    setErrorModal(true);
                }
            })
            .catch(e => {});
    }

    return (
        <>
            <form className={'login-form'} noValidate={true} onSubmit={handleSubmit(customerSignIn)}>
                <VendorAlert title={'Log in as a customer'} />

                <div>{errors?.email?.mesage}</div>
                <input
                    className={'login-form__input'}
                    {...register('username', {required: true})}
                    id={'username'}
                    type={'email'}
                    placeholder={'Email'}
                />
                <input
                    className={'login-form__input'}
                    {...register('password')}
                    id={'password'}
                    type={'password'}
                    placeholder={'Password'}
                />

                <div
                    className={'login-form__forgot-password'}
                    onClick={() => {
                        history.push('/reset-password');
                    }}
                >
                    <div>Forgot password?</div>
                </div>

                <button
                    className={'login-form__button active'}
                    type={'submit'}
                    onClick={() => {
                        if (Object.keys(errors).length) {
                            setModalActive(true);
                        }
                    }}
                >Log in</button>

                <div className={'login-form__register'}>
                    <div>Don’t have an account?</div>
                    <Link to={'/auth'}>Register</Link>
                </div>
            </form>

            <div
                className={'login-form__help-button'}
                onClick={() => {
                    setIsRestore(true);
                }}
            >
                <div>Forgot password?</div>
            </div>

            {modalActive &&
                <Modal active={modalActive} setActive={setModalActive} >
                    <div className={'vendor-errors'}>
                        <img
                            src={crossIcon}
                            alt="cross"
                            onClick={() => {
                                setModalActive(false);
                            }}
                        />

                        <div>
                            <span>Error</span>

                            {errors?.username &&
                                <div>{errors.username.message}</div>}
                            {errors?.password &&
                                <div>{errors.password.message}</div>}
                        </div>
                    </div>
                </Modal>
            }

            {errorModal &&
                <Modal active={errorModal} setActive={setErrorModal}>
                    <div className={'vendor-errors'}>
                        <img
                            src={crossIcon}
                            alt="cross"
                            onClick={() => {
                                setErrorModal(false);
                            }}
                        />

                        <div>
                            <span>Error</span>

                            <div>{loginError}</div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    );
};

export default Login;
