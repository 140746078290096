import React from 'react';
import './Checkbox.css';

const Checkbox = ({checked, setChecked, setTerms}) => {
    return (
        <div className={'checkbox'}>
            <div
                className={checked ? 'indicator checked' : 'indicator'}
                onClick={() => {
                    setChecked(!checked);
                }}
            >
                <div />
            </div>

            <div
                className={'label'}
                onClick={() => {
                    setTerms(true);
                }}
            >I agree to the Terms of Service</div>
        </div>
    );
};

export default Checkbox;
