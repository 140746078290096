import React from 'react';
import './Account.css';

import goBackArrow from "../../assets/arrows/blue-left-arrow.png";

import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {CustomerSignInSchema} from "../../validators";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";

const PaymentConfirmation = () => {
    const history = useHistory();

    const { register, handleSubmit } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(CustomerSignInSchema)
    });

    const confirmPayment = () => {

    }

    return (
        <div className={'account__wrapper'}>
            <HeaderMobile />

            <div className={'account__inner'}>
                <div className={'account__title sub'}>
                    <img
                        src={goBackArrow}
                        alt="go back arrow"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    Payment confirmation
                    <div />
                </div>

                <div className={'account__inner-subtitle'}>
                    Please enter your credit card details below to confirm payment
                </div>

                <form noValidate={true} onSubmit={handleSubmit(confirmPayment)}>
                    <input
                        className={'account__form-input'}
                        {...register('cardName', {required: true})}
                        type="text"
                        id={'cardName'}
                        placeholder={'Name on credit card'}
                    />

                    <input
                        className={'account__form-input'}
                        {...register('cardNumber', {required: true})}
                        type="text"
                        id={'cardNumber'}
                        placeholder={' Credit card number'}
                    />

                    <div className={'account__form-input-wrapper'}>
                        <input
                            className={'account__form-input'}
                            {...register('cardDate', {required: true})}
                            type="text"
                            id={'cardDate'}
                            placeholder={'MM / YYYY'}
                        />

                        <input
                            className={'account__form-input'}
                            {...register('cvv', {required: true})}
                            type="text"
                            id={'cvv'}
                            placeholder={'Cvv'}
                        />
                    </div>

                    <button type={"submit"} className={'account__button active'}>Save</button>
                </form>
            </div>
        </div>
    );
};

export default PaymentConfirmation;
