import React, {useEffect, useState} from 'react';
import s from './WebHomePage.module.css';
import styles from './WebActivitiesPage.module.css';

import searchIcon from '../assets/search.svg';

import CustomerStats from "../WEB-components/CustomerStats/CustomerStats";
import InterestsProviders from "../WEB-components/InterestsProviders/InterestsProviders";
import PlacesNearby from "../WEB-components/PlacesNearby/PlacesNearby";
import InviteNewUser from "../WEB-components/InviteNewUser/InviteNewUser";

const WebHomePage = () => {
	const [inviteActive, setInviteActive] = useState(false);
	const [mapActive, setMapActive] = useState(false);

	const [inputValue, setInputValue] = useState('');
	const [searchInputActive, setSearchInputActive] = useState(false);

	useEffect(() => {
		if (inviteActive) {
			setMapActive(false);
		}
	}, [inviteActive]);

	const isMapActive = () => {
		return <>
			<div className={`${s.page_title} ${s.second_title}`}>More places nearby</div>
			<PlacesNearby />
		</>
	}

	const isInvite = () => {
		if (!inviteActive) {
			return <>
				{mapActive && isMapActive()}

				<div className={`${s.page_title} ${s.second_title}`}>
					Find a Vendor
					<div
						className={searchInputActive
							? `${styles.activities__search_input} ${styles.active}`
							: styles.activities__search_input
						}
						onClick={() => setSearchInputActive(true)}
					>
						<input
							type="text"
							value={inputValue}
							onChange={(e) => setInputValue(e.target.value)}
						/>
						<img src={searchIcon} alt="search"/>
					</div>
				</div>

				<InterestsProviders
					searchValue={inputValue}
					mapActive={mapActive}
					setMapActive={setMapActive}
					inviteActive={inviteActive}
					showMap={false}
				/>
			</>
		}

		return <InviteNewUser setInviteActive={setInviteActive} />;
	}

	return (
		<div className={'web-container'}>
			{/*<div className={s.search_input}>*/}
			{/*	<input type="text" placeholder={'Search'}/>*/}
			{/*	<img src={searchIcon} alt="search"/>*/}
			{/*</div>*/}

			<div className={s.page_title}>My stats</div>

			<CustomerStats
				inviteActive={inviteActive}
				setInviteActive={setInviteActive}
				mapActive={mapActive}
				setMapActive={setMapActive}
			/>

			{isInvite()}
		</div>
	);
};

export default WebHomePage;
