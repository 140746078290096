import React, { useEffect, useState } from "react";
import { providerActions } from "../../action";
import CategoryProvider from "../WebCategories/CategoryProvider";

const WebFavorite = () => {
  const customerTokenLocal = localStorage.getItem("customerToken");

  const [providers, setProviders] = useState([]);

  useEffect(() => {
    providerActions
      .getFavoriteProviders(customerTokenLocal)
      .then(({ data }) => {
        setProviders(data);
      });
  }, [customerTokenLocal]);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "0 20px",
        margin: "42px 0 0",
      }}
    >
      {providers.map((provider) => (
        <CategoryProvider
          key={provider.id}
          provider={provider}
          setProviders={setProviders}
        />
      ))}
    </div>
  );
};

export default WebFavorite;
