import axios from 'axios';
import {BASE_URL} from "../constants";

const getCategoriesListParent = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(`${BASE_URL}/api/categories/parent`, config);
    return response.data;
}

const getCategoriesListSub = async (id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(`${BASE_URL}/api/categories/sub/${id}`, config);


    return response.data;
}

const getCategoryVendor = async (id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(`${BASE_URL}/api/categories/${id}/vendors`, config);
    return response.data;
}

export {
    getCategoriesListParent,
    getCategoriesListSub,
    getCategoryVendor
}
