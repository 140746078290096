import React, {useState} from 'react';

import crossIcon from "../../assets/close.png";
import goBackArrow from "../../assets/arrows/blue-left-arrow.png";
import arrowIcon from "../../assets/arrows/right-arrow-small.svg";

import {useHistory} from "react-router-dom";

import HeaderMobile from "../headers/HeaderMobile/HeaderMobile";
import Modal from "../Modal/Modal";

const AddFunds = () => {
    const history = useHistory();
    const [modalActive, setModalActive] = useState(false);
    const [amountActive, setAmountActive] = useState(false);
    const [price, setPrice] = useState(null);

    const amountPrice = [50, 100, 150, 200, 250];

    return (
        <div className={'account__wrapper'}>
            <HeaderMobile />

            <div className={'account__inner'}>
                <div className={'account__title sub'}>
                    <img
                        src={goBackArrow}
                        alt="go back arrow"
                        onClick={() => {
                            history.goBack();
                        }}
                    />
                    Add funds
                    <div />
                </div>

                <div
                    className={'account__link'}
                    onClick={() => setAmountActive(true)}
                >
                    Amount: {price ? price : ''}
                    <img src={arrowIcon} alt="arrow"/>
                </div>

                <button
                    className={'account__button active'}
                    onClick={() => {
                        if (!price) {
                            setModalActive(true);
                            return;
                        }
                        history.push('/payment-confirmation')
                    }}
                >Add funds</button>

                <div className={amountActive ? 'account__modal active' : 'account__modal'}>
                    <div className={'account__modal-inner'}>
                        <div className={'account__modal-title'}>Amount</div>
                        <div className={'account__modal-subtitle'}>
                            Select the amount of funds you want to add below
                        </div>

                        {amountPrice.map(price => {
                            return (
                                <div
                                    className={'account__modal-price'}
                                    onClick={() => {
                                        setPrice(price);
                                        setAmountActive(false);
                                    }}
                                    key={price}
                                >
                                    $ {price}
                                </div>
                            );
                        })}

                        <div
                            className={'account__modal-price__cancel'}
                            onClick={() => {
                                setAmountActive(false);
                            }}
                        >Cancel</div>
                    </div>
                </div>
            </div>

            <Modal active={modalActive} setActive={setModalActive} >
                <div className={'vendor-errors'}>
                    <img
                        src={crossIcon}
                        alt="cross"
                        onClick={() => {
                            setModalActive(false);
                        }}
                    />

                    <div>
                        <span>Error</span>

                        <div>Please select the amount</div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AddFunds;
